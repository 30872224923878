import { useState } from "react";

import { SERVER_URL } from "config";
import { Button, Input, Loader, SomethingWrong } from "common";
import { useAppDispatch, useAppSelector, addMenuJSONFile } from "store";

export function MenuUpdate() {
  const dispatch = useAppDispatch();
  const { status: { isLoading, error } } = useAppSelector(state => state.menu);
  const [file, setFile] = useState<File>();

  const fileUpdateHandler = (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) setFile(e.currentTarget.files[0]);
    else setFile(undefined);
  }

  const addFileHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (file) {
      let reader = new FileReader();
    
      reader.readAsText(file);
    
      reader.onload = function() {
        const json = JSON.parse((reader.result as string));
        dispatch(addMenuJSONFile(json))
      };
    
      reader.onerror = function() {
        alert(reader.error);
      };

      return;
    } else {
      alert("Need to add file");
    }
  }

  if (isLoading) {
    return (
      <div>
        <Loader className="loader" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="users noticed">
        <SomethingWrong />
      </div>
    );
  }

  return (
    <div>
      <div style={{ marginBottom: "15px" }}>
        <a target="blank" href={`${SERVER_URL}/admin/static/menu.json`}>Current menu JSON</a>
      </div>
      <div>
        <form onSubmit={addFileHandler}>
          <Input type="file" onChange={fileUpdateHandler} />
          <Button type="submit" disabled={file ? false : true}>Upload</Button>
        </form>
      </div>
    </div>
  );
}
