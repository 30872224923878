import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios  from "axios";

import { Orders, FetchData } from "models";
import { SERVER_URL } from "config";

type OrdersState = {
  orders: Orders | undefined;
  status: FetchData;
};

const initialState: OrdersState = {
  orders: undefined,
  status: {
    isError: false,
    isLoading: false,
    error: undefined,
  },
};

export const fetchOrders = createAsyncThunk("orders/fetchOrders", (_, thunkAPI) => {
  const config = {
    url: SERVER_URL + "/orders" + window.location.search,
    method: "get",
    withCredentials: true,
  };

  return axios(config)
    .then(result => {
      return result.data;
    })
    .catch((error: any) => {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        statusText: error.response.statusText,
        message: error.response.data.message,
      } as FetchData["error"]);
    });
});

export const updateOrdersStatuses = createAsyncThunk("order/updateOrdersStatuses", (statuses: Map<number, number>, thunkAPI) => {

  const statusesConfig: {
    url: string;
    method: "put",
    withCredentials: true,
    data: {
      status: number
    },
  }[] = [];

  statuses.forEach((value, key) => {
    statusesConfig.push({
      url: SERVER_URL + "/orders/" + key + "/status",
      method: "put",
      withCredentials: true,
      data: {
        status: value
      },
    })
  });

  const updateStatuses = statusesConfig.map(item => axios(item));

  return Promise.all([...updateStatuses])
    .then(() => {
      return;
    })
    .catch((error: any) => {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        statusText: error.response.statusText,
        message: error.response.data.message,
      } as FetchData["error"]);
    });
});

export const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchOrders.fulfilled.type]: (state: OrdersState, action: PayloadAction<Orders>) => {
      state.orders = action.payload;
      state.status.isLoading = false;
      state.status.isError = false;
      state.status.error = undefined;
    },
    [fetchOrders.pending.type]: (state: OrdersState) => {
      state.orders = undefined;
      state.status.isLoading = true;
      state.status.isError = false;
      state.status.error = undefined;
    },
    [fetchOrders.rejected.type]: (state: OrdersState, action: PayloadAction<FetchData["error"]>) => {
      state.status.isLoading = false;
      state.status.isError = true;
      state.status.error = action.payload;
    },
    [updateOrdersStatuses.fulfilled.type]: (state: OrdersState) => {
      state.status.isLoading = false;
      state.status.isError = false;
      state.status.error = undefined;
    },
    [updateOrdersStatuses.pending.type]: (state: OrdersState) => {
      state.status.isLoading = true;
      state.status.isError = false;
      state.status.error = undefined;
    },
    [updateOrdersStatuses.rejected.type]: (state: OrdersState, action: PayloadAction<FetchData["error"]>) => {
      state.status.isLoading = false;
      state.status.isError = true;
      state.status.error = action.payload;
    },
  }
});