import { useState } from "react";

import { InputWithLabel, SelectorWithLabel } from "common";

type Props = {
  comment: string | null,
  isPaid: boolean,
  isChecked: boolean,
  trackNumber: string | null,
  paymentType: string | null,
  promoCode: string | null,
  status: string,
  statuses: string[],
  isNotice: boolean;
  isDisabled: boolean,
  isTrackNumberUpdate: boolean;
  updateAdminCommentHandler: (newValue: string) => void,
  updateTrackNumberHandler: (newValue: string) => void,
  updatePromoCodeHandler: (newValue: string) => void,
  updateStatusHandler: (newValue: string) => void,
  updateIsNoticeHandler: (newValue: boolean) => void,
};

export function AdditionalInformation(props: Props) {
  const [isStatusChange, setIsStatusChange]   = useState(false);

  const _status = (() => {
    const status = props.status;
    if (status === "opened") return "Открыт (opened)"
    if (status === "denied") return "Отклонен (denied)"
    if (status === "completed") return "Выполнен (completed)"
    if (status === "fail") return "Неудача (fail)"
    if (status === "returned") return "Возвращено (returned)"
    if (status === "withheld") return "Отложен (withheld)"
    if (status === "pickup") return "Самозабор из шоурума (pickup)"
    if (status === "handled") return "Обработан (handled)"
    if (status === "canceled") return "Аннулирован (canceled)"
    if (status === "call_waiting") return "Ожидает звонка (call_waiting)"
    if (status === "postponed") return "Перенесенный (postponed)"
    if (status === "unfinished") return "Незавершенный (unfinished)"
    if (status === "on_delivery") return "В процессе доставки (on_delivery)"
    return status
  })();

  const _statuses = props.statuses.map(item => {
    if (item === "opened") return "Открыт (opened)"
    if (item === "denied") return "Отклонен (denied)"
    if (item === "completed") return "Выполнен (completed)"
    if (item === "fail") return "Неудача (fail)"
    if (item === "returned") return "Возвращено (returned)"
    if (item === "withheld") return "Отложен (withheld)"
    if (item === "pickup") return "Самозабор из шоурума (pickup)"
    if (item === "handled") return "Обработан (handled)"
    if (item === "canceled") return "Аннулирован (canceled)"
    if (item === "call_waiting") return "Ожидает звонка (call_waiting)"
    if (item === "postponed") return "Перенесенный (postponed)"
    if (item === "unfinished") return "Незавершенный (unfinished)"
    if (item === "on_delivery") return "В процессе доставки (on_delivery)"
    return item
  });

  const updateStatusesHandler = (e: React.FormEvent<HTMLSelectElement>) => {
    setIsStatusChange(true);

    let status = "";
    const value = e.currentTarget.value;

    if (value === "Открыт (opened)") status = "opened"
    else if (value === "Отклонен (denied)") status = "denied"
    else if (value === "Выполнен (completed)") status = "completed"
    else if (value === "Неудача (fail)") status = "fail"
    else if (value === "Возвращено (returned)") status = "returned"
    else if (value === "Отложен (withheld)") status = "withheld"
    else if (value === "Самозабор из шоурума (pickup)") status = "pickup"
    else if (value === "Обработан (handled)") status = "handled"
    else if (value === "Аннулирован (canceled)") status = "canceled"
    else if (value === "Ожидает звонка (call_waiting)") status = "call_waiting"
    else if (value === "Перенесенный (postponed)") status = "postponed"
    else if (value === "Незавершенный (unfinished)") status = "unfinished"
    else if (value === "В процессе доставки (on_delivery)") status = "on_delivery"
    else status = value;

    props.updateStatusHandler(status);
  }

  const updateIsNotice = (e: React.FormEvent<HTMLSelectElement>) => {
    const value = e.currentTarget.value;
    props.updateIsNoticeHandler(value === "Enable" ? true : false);
  }

  return (
    <>
      <InputWithLabel
        label="Is paid (only admin)"
        disabled={true}
        readOnly={true}
        value={props.isPaid ? "True" : "False"}
      />
      <InputWithLabel
        label="Is checked (only admin)"
        disabled={true}
        readOnly={true}
        value={props.isChecked ? "True" : "False"}
      />
      <InputWithLabel
        label="Payment type"
        disabled={true}
        readOnly={true}
        value={props.paymentType ? props.paymentType : ""}
      />
      <InputWithLabel
        label="Comment (only admin)"
        disabled={props.isDisabled}
        value={props.comment || ""}
        onChange={e => props.updateAdminCommentHandler(e.currentTarget.value)}
      />
      <InputWithLabel
        label="Track number (only admin)"
        disabled={props.isDisabled}
        value={props.trackNumber ? props.trackNumber : ""}
        onChange={e => props.updateTrackNumberHandler(e.currentTarget.value)}
      />
      <InputWithLabel
        label="Promo code"
        disabled={props.isDisabled || true}
        value={props.promoCode ? props.promoCode : ""}
        onChange={e => props.updatePromoCodeHandler(e.currentTarget.value)}
      />
      <SelectorWithLabel
        label="Status"
        currentValue={_status}
        values={_statuses}
        disabled={props.isDisabled || (props.isTrackNumberUpdate && props.trackNumber !== "")}
        onChange={updateStatusesHandler}
      />
      {isStatusChange && <div style={{backgroundColor: "yellow"}}>
        <SelectorWithLabel
          label="Notify user of status change"
          currentValue={props.isNotice ? "Enable" : "Disable"}
          values={["Enable", "Disable"]}
          disabled={props.isDisabled}
          onChange={updateIsNotice}
          style={{backgroundColor: "yellow"}}
        />
      </div>}
    </>
  );
}