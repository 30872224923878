import {  useState } from "react";
import axios  from "axios";

import { SERVER_URL } from "config";
import { fetchUploads, useAppDispatch, useAppSelector } from "store";

type MediaBody = {
  title: string;
  alias: string;
  bannerUrl: string;
  html: string;
  scripts: null | string;
  publishedAt: Date;
  lang: "en-EN" | "ru-RU";
  isPublished: boolean;
};

export function useGetMediaList() {
  const [isLoading, setIsLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [error, setError] = useState<undefined | string>(undefined);
  const status = { isLoading, isCompleted, error };

  const [mediaList, setMediaList] = useState<MediaBody[] | undefined>(undefined);

  const fetchMedia = () => {
    const config = {
      url: SERVER_URL + "/admin/articles",
      method: "get",
      headers: { "Accept-Language": "ru-RU" },
      withCredentials: true,
    };

    const configEN = {
      ...config,
      headers: { "Accept-Language": "en-EN" },
    };
    

    setIsLoading(true);

    Promise.all([axios(config), axios(configEN)])
      .then(results => {
        setMediaList([
          ...results[0].data.items,
          ...results[1].data.items,
        ]);
        setIsCompleted(true);
        setError(undefined);
      })
      .catch((error: any) => {
        setError(error.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { mediaList, status, fetchMedia };
}

export function useCreateMedia() {
  const [isLoading, setIsLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [error, setError] = useState<undefined | string>(undefined);
  const status = { isLoading, isCompleted, error };

  const create = (props: MediaBody) => {
    const config = {
      url: SERVER_URL + "/admin/articles",
      method: "post",
      data: props,
      headers: { "Accept-Language": props.lang },
      withCredentials: true,
    };

    setIsLoading(true);
  
    axios(config)
      .then(() => {
        setIsCompleted(true);
        setError(undefined);
      })
      .catch((error: any) => {
        setError(error.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { create, status };
}

export function useGetMediaByAliasAndLang() {
  const [isLoading, setIsLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [error, setError] = useState<undefined | string>(undefined);
  const status = { isLoading, isCompleted, error };

  const [data, setData] = useState<MediaBody | undefined>(undefined);

  const get = (alias: string, lang: "en-EN" | "ru-RU") => {
    const config = {
      url: SERVER_URL + "/admin/articles/" + alias,
      method: "get",
      headers: { "Accept-Language": lang },
      withCredentials: true,
    };

    setIsLoading(true);
  
    axios(config)
      .then(({ data }) => {
        setData({ ...data })
        setIsCompleted(true);
        setError(undefined);
      })
      .catch((error: any) => {
        setError(error.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { get, data, status };
}

export function useUpdateMediaByAlias() {
  const [isLoading, setIsLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [error, setError] = useState<undefined | string>(undefined);
  const status = { isLoading, isCompleted, error };

  const update = (props: MediaBody) => {
    setIsCompleted(false);

    const config = {
      url: SERVER_URL + "/admin/articles/" + props.alias,
      method: "put",
      data: props,
      headers: { "Accept-Language": props.lang },
      withCredentials: true,
    };

    setIsLoading(true);
  
    axios(config)
      .then(() => {
        setIsCompleted(true);
        setError(undefined);
      })
      .catch((error: any) => {
        setError(error.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { update, status };
}

export function useDropMediaByAliasAndLang() {
  const [isLoading, setIsLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [error, setError] = useState<undefined | string>(undefined);
  const status = { isLoading, isCompleted, error };

  const drop = (alias: string, lang: "en-EN" | "ru-RU") => {
    setIsCompleted(false);
  
    const config = {
      url: SERVER_URL + "/admin/articles/" + alias,
      method: "delete",
      headers: { "Accept-Language": lang },
      withCredentials: true,
    };

    setIsLoading(true);
  
    axios(config)
      .then(() => {
        setIsCompleted(true);
        setError(undefined);
      })
      .catch((error: any) => {
        setError(error.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { drop, status };
}

export function useFetchBannerList() {
  const dispatch = useAppDispatch();
  
  const { list, status: { isLoading, error } } = useAppSelector(state => state.uploads);
  
  const status = { isLoading, error: error?.message };

  const get = () => {
    dispatch(fetchUploads());
  };

  return { get, list: ["", ...list], status };
}
