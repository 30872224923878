
import { useEffect } from "react";
import { NavLink } from "react-router-dom";

import { Loader, SomethingWrong } from "common";
import { fetchGroups, useAppDispatch, useAppSelector } from "store";

import styles from "./list.module.scss";

export function GroupsListPage() {
  const dispatch = useAppDispatch();
  
  const { groups, status } = useAppSelector(state => state.groups);

  useEffect(() => {
    dispatch(fetchGroups());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (status.isLoading) {
    return <Loader />;
  }

  if (!groups || status.error) {
    return <SomethingWrong />;
  }

  return (
    <div className={styles.wrapper}>
      <div><b><NavLink to={"/groups/create"}>Create</NavLink></b></div>
      <br />

      <table>
        <thead>
          <tr>
            <th>Alias</th>
            <th>Name</th>
            <th>Description</th>
            <th>Published</th>
            <th>Language code</th>
          </tr>
        </thead>
        {groups.map(item => (
          <tbody key={item.alias}>
            <tr>
              <td><NavLink to={"/groups/edit/" + item.alias}>{item.alias}</NavLink></td>
              <td><NavLink to={"/groups/edit/" + item.alias}>{item.name}</NavLink></td>
              <td><NavLink to={"/groups/edit/" + item.alias}>{item.description}</NavLink></td>
              <td><NavLink to={"/groups/edit/" + item.alias}>{item.isPublished ? "Published" : "Draft"}</NavLink></td>
              <td><NavLink to={"/groups/edit/" + item.alias}>{item.languageCode}</NavLink></td>
            </tr>
          </tbody>
        ))}
      </table>


    </div>
  );
}