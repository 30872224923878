import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { FetchData } from "models";
import { SERVER_URL } from "config";

export type HtmlSection = {
  type: "html";
  desktopText: string;
  mobileText: string;
  nano: string;
};

export type BannerSection = {
  type: "banner"
  link: string;
  desktopImg: string;
  mobileImg: string;
  nano: string;
};

export type BannerSplitSection = {
  type: "banner-split"
  firstLink: string;
  secondLink: string;
  firstDesktopImg: string;
  firstMobileImg: string;
  secondDesktopImg: string;
  secondMobileImg: string;
  nano: string;
};

export type Collection = {
  id: number;
  alias: string;
};

export type CollectionGoodsSection = {
  type: "collection"
  alias: string;
  title: string;
  description: string;
  nano: string;
};

export type GroupSection = {
  type: "group"
  alias: string;
  title: string;
  description: string;
  nano: string;
}

export type Group = {
  name: number;
  alias: string;
};

export type SectionsForPage = (HtmlSection | BannerSection | BannerSplitSection | CollectionGoodsSection | GroupSection)[];

type HomepageState = {
  pages: {
    status: FetchData;
    pages: {
      id: number;
      name: string;
      description: string;
      json: {
        ru: SectionsForPage;
        en: SectionsForPage;
      };
      isDefault: boolean;
    }[];
  };
  collections: Collection[];
  groups: Group[],
  status: FetchData;
};

const initialState: HomepageState = {
  pages: {
    pages: [],
    status: { isError: false, isLoading: false, error: undefined }
  },
  collections: [],
  groups: [],
  status: { isError: false, isLoading: false, error: undefined }
};

export const fetchHomepages = createAsyncThunk("homepage/fetchHomepages", (_, thunkAPI) => {
  const config = {
    url: SERVER_URL + "/admin/home-pages" + window.location.search,
    method: "get",
    withCredentials: true,
  };

  return axios(config)
    .then(result => {
      return result.data;
    })
    .catch(error => {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        statusText: error.response.statusText,
        message: error.response.data.message,
      } as FetchData["error"]);
    });
});

export const fetchCollections = createAsyncThunk("homepage/fetchCollections", (_, thunkAPI) => {
  const config = {
    url: SERVER_URL + "/catalog/collections" + window.location.search,
    method: "get",
    withCredentials: true,
  };

  return axios(config)
    .then(result => {
      return result.data;
    })
    .catch(error => {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        statusText: error.response.statusText,
        message: error.response.data.message,
      } as FetchData["error"]);
    });
});

export const fetchGroupsHP = createAsyncThunk("homepage/fetchGroups", (_, thunkAPI) => {
  const config = {
    url: SERVER_URL + "/admin/groups" + window.location.search,
    method: "get",
    withCredentials: true,
  };

  return axios(config)
    .then(result => {
      const _groups: Group[] = result.data.map((item: any) => ({
        name: item.name,
        alias: item.alias,
      }))
      return _groups;
    })
    .catch(error => {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        statusText: error.response.statusText,
        message: error.response.data.message,
      } as FetchData["error"]);
    });
});

export const addHomepages = createAsyncThunk("homepage/addHomepages", (homepage: { name: string, description: string, json: {
  ru: SectionsForPage;
  en: SectionsForPage;
}, is_default: boolean }, thunkAPI) => {
  const config = {
    url: SERVER_URL + "/admin/home-pages" + window.location.search,
    method: "post",
    data: homepage,
    withCredentials: true,
  };

  return axios(config)
    .then(result => {
      return result.data;
    })
    .catch(error => {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        statusText: error.response.statusText,
        message: error.response.data.message,
      } as FetchData["error"]);
    });
});

export const deleteHomepages = createAsyncThunk("homepage/deleteHomepages", (id: number, thunkAPI) => {
  const config = {
    url: SERVER_URL + "/admin/home-pages/" + id + window.location.search,
    method: "delete",
    withCredentials: true,
  };

  return axios(config)
    .then(result => {
      return result.data;
    })
    .catch(error => {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        statusText: error.response.statusText,
        message: error.response.data.message,
      } as FetchData["error"]);
    });
});

export const updateHomepages = createAsyncThunk("homepage/addHomepages", (homepage: { id: number, name: string, description: string, json: {
  ru: SectionsForPage;
  en: SectionsForPage;
}, is_default: boolean }, thunkAPI) => {
  const config = {
    url: SERVER_URL + "/admin/home-pages/" + homepage.id + window.location.search,
    method: "put",
    data: homepage,
    withCredentials: true,
  };

  return axios(config)
    .then(result => {
      return result.data;
    })
    .catch(error => {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        statusText: error.response.statusText,
        message: error.response.data.message,
      } as FetchData["error"]);
    });
});

export const homepageSlice = createSlice({
  name: "homepage",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchHomepages.fulfilled.type]: (state: HomepageState, action: PayloadAction<any>) => {
      state.pages.pages = action.payload;
      state.pages.status.isLoading  = false;
      state.pages.status.isError    = false;
      state.pages.status.error      = undefined;
    },
    [fetchHomepages.pending.type]: (state: HomepageState) => {
      state.pages.status.isLoading  = true;
      state.pages.status.isError    = false;
      state.pages.status.error      = undefined;
    },
    [fetchHomepages.rejected.type]: (state: HomepageState, action: PayloadAction<FetchData["error"]>) => {
      state.pages.status.isLoading  = false;
      state.pages.status.isError    = true;
      state.pages.status.error      = action.payload;
    },
    [fetchCollections.fulfilled.type]: (state: HomepageState, action: PayloadAction<any>) => {
      state.collections = action.payload;
      state.pages.status.isLoading  = false;
      state.pages.status.isError    = false;
      state.pages.status.error      = undefined;
    },
    [fetchCollections.pending.type]: (state: HomepageState) => {
      state.pages.status.isLoading  = true;
      state.pages.status.isError    = false;
      state.pages.status.error      = undefined;
    },
    [fetchCollections.rejected.type]: (state: HomepageState, action: PayloadAction<FetchData["error"]>) => {
      state.pages.status.isLoading  = false;
      state.pages.status.isError    = true;
      state.pages.status.error      = action.payload;
    },
    [addHomepages.fulfilled.type]: (state: HomepageState) => {
      state.status.isLoading  = false;
      state.status.isError    = false;
      state.status.error      = undefined;
    },
    [addHomepages.pending.type]: (state: HomepageState) => {
      state.status.isLoading  = true;
      state.status.isError    = false;
      state.status.error      = undefined;
    },
    [addHomepages.rejected.type]: (state: HomepageState, action: PayloadAction<FetchData["error"]>) => {
      state.status.isLoading  = false;
      state.status.isError    = true;
      state.status.error      = action.payload;
    },
    [deleteHomepages.fulfilled.type]: (state: HomepageState) => {
      state.status.isLoading  = false;
      state.status.isError    = false;
      state.status.error      = undefined;
    },
    [deleteHomepages.pending.type]: (state: HomepageState) => {
      state.status.isLoading  = true;
      state.status.isError    = false;
      state.status.error      = undefined;
    },
    [deleteHomepages.rejected.type]: (state: HomepageState, action: PayloadAction<FetchData["error"]>) => {
      state.status.isLoading  = false;
      state.status.isError    = true;
      state.status.error      = action.payload;
    },
    [updateHomepages.fulfilled.type]: (state: HomepageState) => {
      state.status.isLoading  = false;
      state.status.isError    = false;
      state.status.error      = undefined;
    },
    [updateHomepages.pending.type]: (state: HomepageState) => {
      state.status.isLoading  = true;
      state.status.isError    = false;
      state.status.error      = undefined;
    },
    [updateHomepages.rejected.type]: (state: HomepageState, action: PayloadAction<FetchData["error"]>) => {
      state.status.isLoading  = false;
      state.status.isError    = true;
      state.status.error      = action.payload;
    },
    [fetchGroupsHP.fulfilled.type]: (state: HomepageState, action: PayloadAction<Group[]>) => {
      state.groups = action.payload;
      state.pages.status.isLoading  = false;
      state.pages.status.isError    = false;
      state.pages.status.error      = undefined;
    },
    [fetchGroupsHP.pending.type]: (state: HomepageState) => {
      state.pages.status.isLoading  = true;
      state.pages.status.isError    = false;
      state.pages.status.error      = undefined;
    },
    [fetchGroupsHP.rejected.type]: (state: HomepageState, action: PayloadAction<FetchData["error"]>) => {
      state.pages.status.isLoading  = false;
      state.pages.status.isError    = true;
      state.pages.status.error      = action.payload;
    },
  }
});
