import { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";

import { Button, InputWithLabel, Loader, SelectorWithLabel } from "common";
import { fetchGroups, GroupsRequiredFields, useAppDispatch, useAppSelector, deleteGroups } from "store";
import { CLIENT_URL } from "config";

type Props = {
  compleatedCallback: (fields: GroupsRequiredFields) => void;
};

export function GroupsForm({ compleatedCallback }: Props) {
  const { id }    = useParams();
  const navigate = useNavigate();
  
  const dispatch = useAppDispatch();

  const [name, setName] = useState("");
  const [alias, setAlias] = useState("");
  const [defaultAlias, setDefaultAlias] = useState<string>();
  const [description, setDescription] = useState("");
  const [productId, setProductId] = useState("");
  const [isPublished, setIsPublished] = useState(false);
  const [languageCode, setLanguageCode] = useState<"ru-RU" | "en-EN">("ru-RU");

  const catalogLink = `${CLIENT_URL}/catalog?_groups=${alias}`;

  const [productIdList, setProductIdList] = useState<number[]>([]);

  const { groups, status } = useAppSelector(state => state.groups);

  useEffect(() => {
    if (!id || groups) return;
    dispatch(fetchGroups())
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!id || !groups) return;

    const item = groups!.find(item => item.alias === id);

    if (!item) return;

    setDefaultAlias(item.alias);
    setName(item.name);
    setAlias(item.alias);
    setDescription(item.description);
    setIsPublished(item.isPublished);
    setLanguageCode(item.languageCode);
    if (item.filters.length > 0 && item.filters[0].include_ids) {
      setProductIdList(item.filters[0].include_ids.split(",").map(item => +item))
    }
  }, [id, groups]); // eslint-disable-line react-hooks/exhaustive-deps

  const productIdHandler = (event: React.FormEvent<HTMLInputElement>) => {
    let value = event.currentTarget.value;
    value = value.replaceAll("-", "");
    if (isNaN(+value)) return;
    setProductId(value);
  }

  const addProductIdHandler = () => {
    let _productId = +productId;
    if (isNaN(_productId)) return;

    if (productIdList.indexOf(_productId) !== -1) {
      return alert("This ID already exists");
    }

    setProductId("");
    setProductIdList([...productIdList, _productId]);
  };

  const deleteProductIdById = (id: number) => {
    setProductIdList(productIdList.filter(item => item !== id));
  };

  const compleatedCallbackHandler = () => {
    if (name.length === 0 || description.length === 0 || alias.length === 0 || productIdList.length === 0) {
      return alert("Fill in required fields");
    }

    compleatedCallback({
      name,
      alias,
      defaultAlias: defaultAlias,
      description,
      isPublished,
      languageCode,
      filters: [{ "include_ids": productIdList.join(",") }],
    });
  };

  const deleteHandler = () => {
    if (!window.confirm("Are you sure?")) return;
    dispatch(deleteGroups(alias))
      .then(result => {
        if ((result as any).error) return;
        navigate("/groups");
      });
  }

  if (status.isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <div><b><NavLink to={"/groups"}>Back</NavLink></b></div>
      <br />
      <InputWithLabel type="string" label="Name *" onChange={e => setName(e.currentTarget.value)} value={name} />
      <InputWithLabel type="string" label="Alias *" onChange={e => setAlias(e.currentTarget.value)} value={alias} />
      <InputWithLabel type="string" label="Description *" onChange={e => setDescription(e.currentTarget.value)} value={description} />
      <SelectorWithLabel
        currentValue={languageCode}
        label="Currency"
        values={["ru-RU", "en-EN"]}
        onChange={e => setLanguageCode(languageCode === "ru-RU" ? "en-EN" : "ru-RU")}
      />
      <br />
      <InputWithLabel type="string" label="Product ID" onChange={productIdHandler} value={productId} />
      <Button onClick={addProductIdHandler}>Add</Button>
      <br />
      <ul>
        <li><b>Products ID: *</b></li>
        {productIdList.length === 0 ? <li>List is empty</li> : <>
          {productIdList.map(item =>
            <li key={item} style={{ display: "flex", justifyContent: "space-between" }}>
              <div>{item}</div>
              <div
                style={{ fontWeight: "bold", cursor: "pointer" }}
                onClick={() => deleteProductIdById(item)}
              >delete</div>
            </li>
          )}
        </>}
      </ul>
      <br />
      <InputWithLabel type="checkbox" label="Is published" checked={isPublished} onChange={() => setIsPublished(!isPublished)} />
      <Button onClick={compleatedCallbackHandler}>{id ? "Edit" : "Create"}</Button>
      {id && <Button onClick={deleteHandler}>Delete</Button>}
      <div style={{ color: "red" }}>{status.error?.message}</div>
      <br />
      <InputWithLabel
        type="string"
        label="Сlick on the link to copy"
        value={catalogLink}
        onChange={() => 0}
        onClick={() => navigator.clipboard.writeText(catalogLink)}
        style={{ cursor: "pointer" }}
      />
    </div>
  );
}