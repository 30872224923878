import { Input, InputProps } from "common";
import cn from "classnames";

import "./input-with-label.scss";

type Props = InputProps & {
  label: string;
  inputWithLabelClassName?: string;
}; 

export function InputWithLabel({ label, inputWithLabelClassName, type, ...rest }: Props) {
  return (
    <label className={cn("input-with-label", type === "checkbox" && "input-with-label-checkbox",inputWithLabelClassName)}>
      <div className={cn("label", rest.error && "error")}>{label}</div>
      <Input type={type} {...rest} />
    </label>
  );
}
