import { InputWithLabel } from "common";
import { Address } from "models";

type Props = Address & {
  isDisabled: boolean,
  updateCountryHandler: (newValue: string) => void,
  updateStateHandler: (newValue: string) => void,
  updateCityHandler: (newValue: string) => void,
  updateStreetHandler: (newValue: string) => void,
  updateBuildingHandler: (newValue: string) => void,
  updateZipHandler: (newValue: string) => void,
  updateFloorHandler: (newValue: string) => void,
  updateEntranceHandler: (newValue: string) => void,
  updateApartmentHandler: (newValue: string) => void,
  updateCommentHandler: (newValue: string) => void,
};

export function Delivery(props: Props) {
  return (
    <>
      <InputWithLabel
        label="Country *"
        disabled={props.isDisabled}
        value={props.country}
        onChange={e => props.updateCountryHandler(e.currentTarget.value)}
      />
      <InputWithLabel
        label="State *"
        disabled={props.isDisabled}
        value={props.region}
        onChange={e => props.updateStateHandler(e.currentTarget.value)}
      />
      <InputWithLabel
        label="City *"
        disabled={props.isDisabled}
        value={props.city}
        onChange={e => props.updateCityHandler(e.currentTarget.value)}
      />
      <InputWithLabel
        label="Street *"
        disabled={props.isDisabled}
        value={props.street}
        onChange={e => props.updateStreetHandler(e.currentTarget.value)}
      />
      <InputWithLabel
        label="Building *"
        disabled={props.isDisabled}
        value={props.building}
        onChange={e => props.updateBuildingHandler(e.currentTarget.value)}
      />
      <InputWithLabel
        label="Zip *"
        disabled={props.isDisabled}
        value={props.zip}
        onChange={e => props.updateZipHandler(e.currentTarget.value)}
      />
      <InputWithLabel
        label="Floor"
        disabled={props.isDisabled}
        value={props.floor}
        onChange={e => props.updateFloorHandler(e.currentTarget.value)}
      />
      <InputWithLabel
        label="Entrance"
        disabled={props.isDisabled}
        value={props.entrance}
        onChange={e => props.updateEntranceHandler(e.currentTarget.value)}
      />
      <InputWithLabel
        label="Apartment"
        disabled={props.isDisabled}
        value={props.apartment}
        onChange={e => props.updateApartmentHandler(e.currentTarget.value)}
      />
      <InputWithLabel
        label="Customer comment"
        disabled={props.isDisabled}
        value={props.comment}
        onChange={e => props.updateCommentHandler(e.currentTarget.value)}
      />
    </>
  );
}
