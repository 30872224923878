export function isEmpty(field: string, secondField?: string) {
  return field.length === 0;
}

export function isEqual(field: string, secondField?: string) {
  return secondField ? field === secondField : false;
}

export function isEmail(field: string) {
  return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.exec(field) !== null; // eslint-disable-line
}
