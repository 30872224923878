import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios  from "axios";

import { FetchData } from "models";
import { SERVER_URL } from "config";

type StatisticDate = {
  totalCount: number;
  devices: {
    count: number;
    device: string;
    percent: 0;
  }[];
};

type Statistic = {
  target: string;
  month: StatisticDate;
  week: StatisticDate;
  previousWeek: StatisticDate;
};

type StatisticsState = {
  statistics: Statistic[] | undefined;
  status: FetchData;
};

const initialState: StatisticsState = {
  statistics: [],
  status: {
    isError: false,
    isLoading: false,
    error: undefined,
  },
};

export const fetchStatistics = createAsyncThunk("orders/fetchOrders", (_, thunkAPI) => {
  const config = {
    url: SERVER_URL + "/statistic/events" + window.location.search,
    method: "get",
    withCredentials: true,
  };

  return axios(config)
    .then(result => {
      console.log(result.data.items)
      return result.data.items;
    })
    .catch((error: any) => {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        statusText: error.response.statusText,
        message: error.response.data.message,
      } as FetchData["error"]);
    });
});


export const statisticsSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchStatistics.fulfilled.type]: (state: StatisticsState, action: PayloadAction<Statistic[]>) => {
      state.statistics = action.payload;
      state.status.isLoading = false;
      state.status.isError = false;
      state.status.error = undefined;
    },
    [fetchStatistics.pending.type]: (state: StatisticsState) => {
      state.statistics = undefined;
      state.status.isLoading = true;
      state.status.isError = false;
      state.status.error = undefined;
    },
    [fetchStatistics.rejected.type]: (state: StatisticsState, action: PayloadAction<FetchData["error"]>) => {
      state.status.isLoading = false;
      state.status.isError = true;
      state.status.error = action.payload;
    },
  }
});