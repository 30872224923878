import { HTMLProps } from "react";
import TextareaAutosize from "react-textarea-autosize";
import cn from "classnames";

import "./input.scss"

export type InputProps = HTMLProps<HTMLInputElement> & {
  error?: string;
  warning?: string;
  inputWrapperClassName?: string;
}; 

export function Input({ inputWrapperClassName, error, warning, type, ...rest }: InputProps) {
  return (
    <div className={cn("input", inputWrapperClassName)}>
      {type === "textarea" && <TextareaAutosize
        className={cn(error && "error", rest.className)} 
        minRows={3}
        value={rest.value}
        disabled={rest.disabled}
        onChange={(rest.onChange) as any}
      />}
      {type !== "textarea" && <input type={type} {...rest} className={cn(error && "error", rest.className)} />}
      {error && <div className={cn("error", "error-text")}>{error}</div>}
      {warning && <div className={cn("warning", "warning-text")}>{warning}</div>}
    </div>
  );
}
