import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios  from "axios";

import { FetchData } from "models";
import { SERVER_URL } from "config";

type Brand = {
  id: number;
  name: string;
};

type BrandsState = {
  brands: Brand[] | undefined;
  status: FetchData;
};

const initialState: BrandsState = {
  brands: undefined,
  status: {
    isError: false,
    isLoading: false,
    error: undefined,
  },
};

export const fetchBrands = createAsyncThunk("brands/fetchBrands", (_, thunkAPI) => {
  const config = {
    url: SERVER_URL + "/brands",
    method: "get",
    headers: { "Accept-Language": "ru-RU" },
    withCredentials: true,
  };

  return axios(config)
    .then(({ data }: { data: Brand[] }) => {
      console.log(data);
      return data;
    })
    .catch((error: any) => {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        statusText: error.response.statusText,
        message: error.response.data.message,
      } as FetchData["error"]);
    });
});

// export const updateOrdersStatuses = createAsyncThunk("order/updateOrdersStatuses", (statuses: Map<number, number>, thunkAPI) => {

//   const statusesConfig: {
//     url: string;
//     method: "put",
//     withCredentials: true,
//     data: {
//       status: number
//     },
//   }[] = [];

//   statuses.forEach((value, key) => {
//     statusesConfig.push({
//       url: SERVER_URL + "/orders/" + key + "/status",
//       method: "put",
//       withCredentials: true,
//       data: {
//         status: value
//       },
//     })
//   });

//   const updateStatuses = statusesConfig.map(item => axios(item));

//   return Promise.all([...updateStatuses])
//     .then(() => {
//       return;
//     })
//     .catch((error: any) => {
//       return thunkAPI.rejectWithValue({
//         status: error.response.status,
//         statusText: error.response.statusText,
//         message: error.response.data.message,
//       } as FetchData["error"]);
//     });
// });

export const brandsSlice = createSlice({
  name: "brandsSlice",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchBrands.fulfilled.type]: (state: BrandsState, action: PayloadAction<Brand[]>) => {
      state.brands = action.payload;
      state.status.isLoading = false;
      state.status.isError = false;
      state.status.error = undefined;
    },
    [fetchBrands.pending.type]: (state: BrandsState) => {
      state.brands = undefined;
      state.status.isLoading = true;
      state.status.isError = false;
      state.status.error = undefined;
    },
    [fetchBrands.rejected.type]: (state: BrandsState, action: PayloadAction<FetchData["error"]>) => {
      state.status.isLoading = false;
      state.status.isError = true;
      state.status.error = action.payload;
    },
    // [updateOrdersStatuses.fulfilled.type]: (state: OrdersState) => {
    //   state.status.isLoading = false;
    //   state.status.isError = false;
    //   state.status.error = undefined;
    // },
    // [updateOrdersStatuses.pending.type]: (state: OrdersState) => {
    //   state.status.isLoading = true;
    //   state.status.isError = false;
    //   state.status.error = undefined;
    // },
    // [updateOrdersStatuses.rejected.type]: (state: OrdersState, action: PayloadAction<FetchData["error"]>) => {
    //   state.status.isLoading = false;
    //   state.status.isError = true;
    //   state.status.error = action.payload;
    // },
  }
});