import { ReactNode } from "react";

import { Menu } from "common";

import "./default.scss"

interface Props {
  children: ReactNode,
}

export function DefaultLayout({ children }: Props) {
  return (
    <div className="default-layout">
      <div className="menu">
        <Menu />
      </div>
      <main className="main-block">
        {children}
      </main>
    </div>
  );
}
