import { createSlice, PayloadAction, createAsyncThunk, nanoid } from "@reduxjs/toolkit";
import axios  from "axios";

import { FetchData } from "models";
import { SERVER_URL } from "config";

interface Fields {
  id: number | undefined;
  alias: string;
  expiredAt: string | undefined;
  fullFilters: {
    id: string;
    type: "filters" | "anti-filters";
    filters: {
      name: string;
      items: {
        title: string;
        isChecked: boolean;
        forPrices: string;
      }[];
    }[];
  }[];
  name: string;
  description: string;
  forUsersIDs: number[];
  includeProductByID: number[];
  excludeProductByID: number[];
  collectionByID: number[];
  forAuth: boolean;
  isPercents: boolean;
  isPublished: boolean;
  totalFrom: string;
  totalTo: string;
  value: string;
  isSumWithDiscount: boolean;
  languageCode: "ru-RU" | "en-EN";
  maxUses: string;
  maxAuthUsers: string;
  maxUsesForUser: string;
};

type PromoCodeCreateOrEditState = {
  defaultFilters: {
    filters: {
      name: string;
      items: string[];
    }[];
    status: FetchData;
  };
  isRedirect: boolean,
  fields: Fields & {
    status: FetchData;
  };
  status: FetchData;
};

const defaultStatus: FetchData = {
  isError: false,
  isLoading: false,
  error: undefined,
};

const initialState: PromoCodeCreateOrEditState = {
  defaultFilters: {
    filters: [],
    status: defaultStatus,
  },
  isRedirect: false,
  fields: {
    id: undefined,
    alias: "",
    expiredAt: undefined,
    fullFilters: [],
    name: "",
    description: "",
    forUsersIDs: [],
    forAuth: false,
    isPercents: false,
    isPublished: false,
    totalFrom: "",
    totalTo: "",
    value: "",
    isSumWithDiscount: false,
    languageCode: "ru-RU",
    maxUses: "",
    maxAuthUsers: "",
    maxUsesForUser: "",
    status: defaultStatus,
    includeProductByID: [],
    excludeProductByID: [],
    collectionByID: [],
  },
  status: defaultStatus,
};

export const fetchDefaultFilters = createAsyncThunk("promo-code-create-or-edit/fetchDefaultFilters", (_, thunkAPI) => 
  axios({
    url: SERVER_URL + "/filters" + window.location.search,
    method: "get",
    withCredentials: true,
  })
    .then(result => {
      return result.data.filters;
    })
    .catch((error: any) => {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        statusText: error.response.statusText,
        message: error.response.data.message,
      } as FetchData["error"]);
    })
);

interface CreatePromo {
  name: string;
  alias: string;
  description: string;
  filters: {
    [key: string]: string;
  }[] | null;
  anti_filters: {
    [key: string]: string;
  }[] | null;
  language_code: "ru-RU" | "en-EN";
  value: string;
  is_percents: boolean;
  total_from: string; 
  total_to: string;
  expired_at: Date | undefined;
  for_auth: boolean;
  max_auth_users: string;
  max_uses_for_user: string;
  max_uses: string;
  is_published: boolean;
  is_sum_with_discount: boolean;
  for_users_ids: number[];
}
export const createPromoCode = createAsyncThunk("promo-code-create-or-edit/create", (promo: CreatePromo, thunkAPI) => {
  const config = {
    url: SERVER_URL + "/admin/promo" + window.location.search,
    method: "post",
    withCredentials: true,
    data: promo,
  };

  return axios(config)
    .then(result => {
      return result.data;
    })
    .catch((error: any) => {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        statusText: error.response.statusText,
        message: error.response.data.message,
      } as FetchData["error"]);
    });
});

export const updatePromoCode = createAsyncThunk("promo-code-create-or-edit/update", (promo: CreatePromo & { id: number }, thunkAPI) => {
  const config = {
    url: SERVER_URL + "/admin/promo/" + promo.id + window.location.search,
    method: "put",
    withCredentials: true,
    data: promo,
  };

  return axios(config)
    .then(result => {
      return result.data;
    })
    .catch((error: any) => {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        statusText: error.response.statusText,
        message: error.response.data.message,
      } as FetchData["error"]);
    });
});

export const deletePromoCode = createAsyncThunk("promo-code-create-or-edit/delete", (id: number, thunkAPI) => {
  const config = {
    url: SERVER_URL + "/admin/promo/" + id + window.location.search,
    method: "delete",
    withCredentials: true,
  };

  return axios(config)
    .then(result => {
      return result.data;
    })
    .catch((error: any) => {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        statusText: error.response.statusText,
        message: error.response.data.message,
      } as FetchData["error"]);
    });
});

export const promoCodeCreateOrEditSlice = createSlice({
  name: "promo-code-edit",
  initialState,
  reducers: {
    addFullFilter: (state) => {
      if (state.defaultFilters.filters.length === 0) throw new Error("defaultFilters cannot be empty");

      const parsedFilters = state.defaultFilters.filters.map((filter) => ({
        name: filter.name,
        items: filter.items.map(item => ({
          title: item,
          isChecked: false,
          forPrices: ""
        })),
      }));

      state.fields.fullFilters.push({
        id: nanoid(),
        filters: parsedFilters,
        type: "filters",
      });
    },
    changeFiltersType: (state, action: PayloadAction<{ id: string, type: "filters" | "anti-filters" }>) => {
      let searchPosition = -1;
      
      state.fields.fullFilters.map((filters, positon) => {
        if (filters.id === action.payload.id) { searchPosition = positon }
      });

      if (searchPosition !== -1) {
        state.fields.fullFilters[searchPosition] = {
          ...state.fields.fullFilters[searchPosition],
          type: action.payload.type,
        }
      }
    },
    deleteFiltersItem: (state, action: PayloadAction<string>) => {
      let searchPosition = -1;
      
      state.fields.fullFilters.map((filters, positon) => {
        if (filters.id === action.payload) { searchPosition = positon }
      });

      if (searchPosition !== -1) {
        state.fields.fullFilters.splice(searchPosition, 1);
      }
    },
    updateCheckboxFilters: (state, action: PayloadAction<{ id: string, name: string, title: string }>) => {
      let searchPosition = -1;
      
      state.fields.fullFilters.map((filters, positon) => {
        if (filters.id === action.payload.id) { searchPosition = positon }
      });

      if (searchPosition !== -1) {
        const tempFilters = state.fields.fullFilters[searchPosition].filters;

        let _filterPosition = -1;
        let _filterInnerPosition = -1;

        tempFilters.map((filter, filterPosition) => {
          if (filter.name === action.payload.name) {
            filter.items.map((filterInner, filterInnerPosition) => {
              if (filterInner.title === action.payload.title) {
                _filterPosition = filterPosition;
                _filterInnerPosition = filterInnerPosition;
              }
            });
          }
        })

        state.fields.fullFilters[searchPosition].filters[_filterPosition].items[_filterInnerPosition].isChecked = !state.fields.fullFilters[searchPosition].filters[_filterPosition].items[_filterInnerPosition].isChecked;
      }
    },
    updatePricesFilters: (state, action: PayloadAction<{ id: string, name: string, title: string, price: string }>) => {
      let searchPosition = -1;
      
      state.fields.fullFilters.map((filters, positon) => {
        if (filters.id === action.payload.id) { searchPosition = positon }
      });

      if (searchPosition !== -1) {
        const tempFilters = state.fields.fullFilters[searchPosition].filters;

        let _filterPosition = -1;
        let _filterInnerPosition = -1;

        tempFilters.map((filter, filterPosition) => {
          if (filter.name === action.payload.name) {
            filter.items.map((filterInner, filterInnerPosition) => {
              if (filterInner.title === action.payload.title) {
                _filterPosition = filterPosition;
                _filterInnerPosition = filterInnerPosition;
              }
            });
          }
        })

        state.fields.fullFilters[searchPosition].filters[_filterPosition].items[_filterInnerPosition].forPrices = action.payload.price;
      }
    },
    updateAlias: (state, action: PayloadAction<string>) => { state.fields.alias = action.payload },
    updateName: (state, action: PayloadAction<string>) => { state.fields.name = action.payload },
    updateDescription: (state, action: PayloadAction<string>) => { state.fields.description = action.payload },
    updateTotalFrom: (state, action: PayloadAction<string>) => { state.fields.totalFrom = action.payload },
    updateTotalTo: (state, action: PayloadAction<string>) => { state.fields.totalTo = action.payload },
    updateLanguageCode: (state, action: PayloadAction<"ru-RU" | "en-EN">) => { state.fields.languageCode = action.payload },
    updateValue: (state, action: PayloadAction<string>) => { state.fields.value = action.payload },
    updateExpiredAt: (state, action: PayloadAction<string>) => { state.fields.expiredAt = action.payload },
    updateMaxAuthUsers: (state, action: PayloadAction<string>) => { state.fields.maxAuthUsers = action.payload },
    updateMaxUsesForUser: (state, action: PayloadAction<string>) => { state.fields.maxUsesForUser = action.payload },
    updateMaxUses: (state, action: PayloadAction<string>) => { state.fields.maxUses = action.payload },
    updateIsPercents: (state) => { state.fields.isPercents = !state.fields.isPercents },
    updateIsSumWithDiscount: (state) => { state.fields.isSumWithDiscount = !state.fields.isSumWithDiscount },
    updateIsPublished: (state) => { state.fields.isPublished = !state.fields.isPublished },
    updateForAuth: (state) => { state.fields.forAuth = !state.fields.forAuth },

    addForUsersIDs: (state, action: PayloadAction<number>) => {
      let searchPosition = state.fields.forUsersIDs.indexOf(action.payload);
      if (searchPosition !== -1) { return alert("Already exist") } 
      state.fields.forUsersIDs.push(action.payload);
    },
    deleteForUsersIDs: (state, action: PayloadAction<number>) => {
      let searchPosition = state.fields.forUsersIDs.indexOf(action.payload);
      if (searchPosition === -1) { return alert("Does not exist") } 
      state.fields.forUsersIDs.splice(searchPosition, 1);
    },

    addIncludeProductByID: (state, action: PayloadAction<number>) => {
      let searchPosition = state.fields.includeProductByID.indexOf(action.payload);
      if (searchPosition !== -1) { return alert("Already exist") } 
      state.fields.includeProductByID.push(action.payload);
    },
    deleteIncludeProductByID: (state, action: PayloadAction<number>) => {
      let searchPosition = state.fields.includeProductByID.indexOf(action.payload);
      if (searchPosition === -1) { return alert("Does not exist") } 
      state.fields.includeProductByID.splice(searchPosition, 1);
    },

    addExcludeProductByID: (state, action: PayloadAction<number>) => {
      let searchPosition = state.fields.excludeProductByID.indexOf(action.payload);
      if (searchPosition !== -1) { return alert("Already exist") } 
      state.fields.excludeProductByID.push(action.payload);
    },
    deleteExcludeProductByID: (state, action: PayloadAction<number>) => {
      let searchPosition = state.fields.excludeProductByID.indexOf(action.payload);
      if (searchPosition === -1) { return alert("Does not exist") } 
      state.fields.excludeProductByID.splice(searchPosition, 1);
    },

    addCollectionByID: (state, action: PayloadAction<number>) => {
      let searchPosition = state.fields.collectionByID.indexOf(action.payload);
      if (searchPosition !== -1) { return alert("Already exist") } 
      state.fields.collectionByID.push(action.payload);
    },
    deleteCollectionID: (state, action: PayloadAction<number>) => {
      let searchPosition = state.fields.collectionByID.indexOf(action.payload);
      if (searchPosition === -1) { return alert("Does not exist") } 
      state.fields.collectionByID.splice(searchPosition, 1);
    },

    setFields: (state, action: PayloadAction<Fields>) => {
      state.fields.id = action.payload.id;
      state.fields.alias = action.payload.alias;
      state.fields.expiredAt = action.payload.expiredAt;
      state.fields.name = action.payload.name;
      state.fields.description = action.payload.description;
      state.fields.forUsersIDs = action.payload.forUsersIDs;
      state.fields.forAuth = action.payload.forAuth;
      state.fields.isPercents = action.payload.isPercents;
      state.fields.isPublished = action.payload.isPublished;
      state.fields.totalFrom = action.payload.totalFrom;
      state.fields.totalTo = action.payload.totalTo;
      state.fields.value = action.payload.value;
      state.fields.isSumWithDiscount = action.payload.isSumWithDiscount;
      state.fields.languageCode = action.payload.languageCode;
      state.fields.maxUses = action.payload.maxUses;
      state.fields.maxAuthUsers = action.payload.maxAuthUsers;
      state.fields.maxUsesForUser = action.payload.maxUsesForUser;
      state.fields.includeProductByID = action.payload.includeProductByID;
      state.fields.excludeProductByID = action.payload.excludeProductByID;
      state.fields.fullFilters = action.payload.fullFilters;
      state.fields.collectionByID = action.payload.collectionByID;
    },
    resetFields: (state) => {
      state.fields = initialState.fields;
      state.isRedirect = false;
    },
  },
  extraReducers: {
    [fetchDefaultFilters.fulfilled.type]: (state: PromoCodeCreateOrEditState, action: PayloadAction<{
      name: string;
      items: string[];
    }[]>) => {
      state.defaultFilters.filters = action.payload;
      state.defaultFilters.status.isLoading = false;
      state.defaultFilters.status.isError = false;
      state.defaultFilters.status.error = undefined;
    },
    [fetchDefaultFilters.pending.type]: (state: PromoCodeCreateOrEditState) => {
      state.defaultFilters.status.isLoading = true;
      state.defaultFilters.status.isError = false;
      state.defaultFilters.status.error = undefined;
    },
    [fetchDefaultFilters.rejected.type]: (state: PromoCodeCreateOrEditState, action: PayloadAction<FetchData["error"]>) => {
      state.defaultFilters.status.isLoading = false;
      state.defaultFilters.status.isError = true;
      state.defaultFilters.status.error = action.payload;
    },
    [createPromoCode.fulfilled.type]: (state: PromoCodeCreateOrEditState) => {
      state.isRedirect = true;
      state.fields.status.isLoading = false;
      state.fields.status.isError = false;
      state.fields.status.error = undefined;
    },
    [createPromoCode.pending.type]: (state: PromoCodeCreateOrEditState) => {
      state.fields.status.isLoading = true;
      state.fields.status.isError = false;
      state.fields.status.error = undefined;
    },
    [createPromoCode.rejected.type]: (state: PromoCodeCreateOrEditState, action: PayloadAction<FetchData["error"]>) => {
      state.fields.status.isLoading = false;
      state.fields.status.isError = true;
      state.fields.status.error = action.payload;
    },
    [updatePromoCode.fulfilled.type]: (state: PromoCodeCreateOrEditState) => {
      state.fields.status.isLoading = false;
      state.fields.status.isError = false;
      state.fields.status.error = undefined;
    },
    [updatePromoCode.pending.type]: (state: PromoCodeCreateOrEditState) => {
      state.fields.status.isLoading = true;
      state.fields.status.isError = false;
      state.fields.status.error = undefined;
    },
    [updatePromoCode.rejected.type]: (state: PromoCodeCreateOrEditState, action: PayloadAction<FetchData["error"]>) => {
      state.fields.status.isLoading = false;
      state.fields.status.isError = true;
      state.fields.status.error = action.payload;
    },
    [deletePromoCode.fulfilled.type]: (state: PromoCodeCreateOrEditState) => {
      state.isRedirect = true;
      state.fields.status.isLoading = false;
      state.fields.status.isError = false;
      state.fields.status.error = undefined;
    },
    [deletePromoCode.pending.type]: (state: PromoCodeCreateOrEditState) => {
      state.fields.status.isLoading = true;
      state.fields.status.isError = false;
      state.fields.status.error = undefined;
    },
    [deletePromoCode.rejected.type]: (state: PromoCodeCreateOrEditState, action: PayloadAction<FetchData["error"]>) => {
      state.fields.status.isLoading = false;
      state.fields.status.isError = true;
      state.fields.status.error = action.payload;
    },
  }
});
