import { InputWithLabel, SelectorWithLabel } from "common/components";
import { useAppDispatch, useAppSelector, userSlice } from "store";

export function PrimaryUserInfo() {
  const dispatch  = useAppDispatch();

  const user = useAppSelector(state => state.user).user!;

  const {
    updateFirstName,
    updateLastName,
    updateThirdName,
    updateEmail,
    updatePhoneNumber,
    updateCurrency,
  } = userSlice.actions;

  const updateFirstNameHandler    = (newValue: string) => { dispatch(updateFirstName(newValue)) }
  const updateLastNameHandler     = (newValue: string) => { dispatch(updateLastName(newValue)) }
  const updateThirdNameHandler    = (newValue: string) => { dispatch(updateThirdName(newValue)) }
  const updateEmailHandler        = (newValue: string) => { dispatch(updateEmail(newValue)) }
  const updatePhoneNumberHandler  = (newValue: string) => { dispatch(updatePhoneNumber(newValue)) }
  const updateCurrencyHandler  = (newValue: "USD" | "RUB") => { dispatch(updateCurrency(newValue)) }

  return (
    <div className="primary-user-info">
      <InputWithLabel
        label="First name"
        onChange={e => updateFirstNameHandler(e.currentTarget.value)}
        value={user.firstName}
      />
      <InputWithLabel
        label="Last name"
        onChange={e => updateLastNameHandler(e.currentTarget.value)}
        value={user.lastName}
      />
      <InputWithLabel
        label="Third name"
        onChange={e => updateThirdNameHandler(e.currentTarget.value)}
        value={user.thirdName}
      />
      <InputWithLabel
        label="Email"
        onChange={e => updateEmailHandler(e.currentTarget.value)}
        value={user.email}
        warning={user.verifications.isEmail === false ? "Email not confirmed" : ""}
      />
      <InputWithLabel
        label="Phone"
        onChange={e => updatePhoneNumberHandler(e.currentTarget.value)}
        value={user.phoneNumber}
        warning={user.verifications.isPhone === false ? "Phone not confirmed" : ""}
      />
      <SelectorWithLabel
        currentValue={user.currency === null ? "NONE" : user.currency}
        label="Currency"
        values={["RUB", "USD", "NONE"]}
        onChange={e => {
          const value: any = e.currentTarget.value;
          if (value === "NONE") return;
          updateCurrencyHandler(value)
        }}
      />
    </div>
  );
}