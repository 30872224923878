import { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";

import { Button, InputWithLabel, Selector } from "common/components";
import {
  fetchBrand,
  useAppDispatch,
  useAppSelector,
  brandUpdate,
} from "store";


export function Brand() {
  const dispatch = useAppDispatch();
  const id = useParams().id;
  const { brand, status: { isLoading, error } } = useAppSelector(state => state.brand);
  const [description, setDescription] = useState("");
  const [subDescription, setSubDescription] = useState("");
  const [imgURL, setImgURL] = useState("");
  const [selectLang, setSelectLang] = useState<"ru-RU" | "en-EN">("ru-RU");

  useEffect(() => {
    if (!id) return;
    dispatch(fetchBrand({
      name: id,
      lang: selectLang,
    }));
  }, [id, selectLang]);

  useEffect(() => {
    if (brand === undefined) {
      setDescription("");
      setSubDescription("");
      setImgURL("");
      return;
    };
    setDescription(brand.description ? brand.description : "");
    setSubDescription(brand.textDescription ? brand.textDescription : "");
    setImgURL(brand.image ? brand.image : "");
  }, [brand]);
  
  const handleSave = () => {
    dispatch(brandUpdate({
      name: id!,
      language_code: selectLang,
      description,
      text_description: subDescription,
      image: imgURL,
    }));
  };

  return (
    <div>
      <div>
        <NavLink to={"/brands"} className="link">back to brands</NavLink>
      </div>
      <h2>{id}</h2>
      <Selector
        currentValue={selectLang}
        values={["ru-RU", "en-EN"]}
        onChange={e => { setSelectLang(e.currentTarget.value as any) }}
      />
      <br />
      <div>
        <InputWithLabel
          type="string"
          label="Description"
          value={description}
          onChange={e => setDescription(e.currentTarget.value)}
        />
      </div>
      <br />
      <div>
        <InputWithLabel
          type="string"
          label="Meta description"
          value={subDescription}
          onChange={e => setSubDescription(e.currentTarget.value)}
        />
      </div>
      <br />
      <div>
        <InputWithLabel
          type="string"
          label="Image URL"
          value={imgURL}
          onChange={e => setImgURL(e.currentTarget.value)}
        />
      </div>
      <div>
      <br />
      <Button
        type="button"
        disabled={isLoading}
        onClick={handleSave}>Save</Button>
      </div>
    </div>
  );
}
