import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios  from "axios";

import { UserPrimary, FetchData } from "models";
import { SERVER_URL } from "config";

type UploadsState = {
  list: string[];
  status: FetchData;
};

const initialState: UploadsState = {
  list: [],
  status: {
    isError: false,
    isLoading: false,
    error: undefined,
  },
};

export const fetchUploads = createAsyncThunk("uploads/fetchUploads", (_, thunkAPI) => {
  const config = {
    url: SERVER_URL + "/admin/banners/list",
    method: "get",
    withCredentials: true,
  };

  return axios(config)
    .then(result => {
      return result.data.items;
    })
    .catch((error: any) => {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        statusText: error.response.statusText,
        message: error.response.data.message,
      } as FetchData["error"]);
    });
});

export const addFiles = createAsyncThunk("uploads/addFiles", (file: File, thunkAPI) => {
  const formData = new FormData();
  formData.append("file",file);

  const config = {
    withCredentials: true,
    headers: { "content-type": "multipart/form-data" },
    method: "post",
    url: SERVER_URL + "/admin/banners/upload",
    data: formData,
  };

  return axios(config)
    .then(result => {
      console.log(result)
      return result.data;
    })
    .catch((error: any) => {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        statusText: error.response.statusText,
        message: error.response.data.message,
      } as FetchData["error"]);
    });
});

export const deleteFile = createAsyncThunk("uploads/deleteFile", (name: string, thunkAPI) => {
  const config = {
    url: SERVER_URL + "/admin/banners/" + name + window.location.search,
    method: "delete",
    withCredentials: true,
  };

  return axios(config)
    .then(result => {
      return result.data;
    })
    .catch(error => {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        statusText: error.response.statusText,
        message: error.response.data.message,
      } as FetchData["error"]);
    });
});

export const uploadsSlice = createSlice({
  name: "uploads",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchUploads.fulfilled.type]: (state: UploadsState, action: PayloadAction<UploadsState["list"]>) => {
      state.list = action.payload;
      state.status.isLoading = false;
      state.status.isError = false;
      state.status.error = undefined;
    },
    [fetchUploads.pending.type]: (state: UploadsState) => {
      state.status.isLoading = true;
      state.status.isError = false;
      state.status.error = undefined;
    },
    [fetchUploads.rejected.type]: (state: UploadsState, action: PayloadAction<FetchData["error"]>) => {
      state.status.isLoading = false;
      state.status.isError = true;
      state.status.error = action.payload;
    },
    [addFiles.fulfilled.type]: (state: UploadsState, action: PayloadAction<UserPrimary>) => {
      state.status.isLoading = false;
      state.status.isError = false;
      state.status.error = undefined;
    },
    [addFiles.pending.type]: (state: UploadsState) => {
      state.status.isLoading = true;
      state.status.isError = false;
      state.status.error = undefined;
    },
    [addFiles.rejected.type]: (state: UploadsState, action: PayloadAction<FetchData["error"]>) => {
      state.status.isLoading = false;
      state.status.isError = true;
      state.status.error = action.payload;
    },
    [deleteFile.fulfilled.type]: (state: UploadsState, action: PayloadAction<UserPrimary>) => {
      state.status.isLoading = false;
      state.status.isError = false;
      state.status.error = undefined;
    },
    [deleteFile.pending.type]: (state: UploadsState) => {
      state.status.isLoading = true;
      state.status.isError = false;
      state.status.error = undefined;
    },
    [deleteFile.rejected.type]: (state: UploadsState, action: PayloadAction<FetchData["error"]>) => {
      state.status.isLoading = false;
      state.status.isError = true;
      state.status.error = action.payload;
    },
  }
});
