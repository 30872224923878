import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { Delivery, Loader, SomethingWrong, User, Button, SelectorWithLabel } from "common";
import { fetchOrder, useAppDispatch, useAppSelector, orderSlice, updateOrder } from "store";
import { AdditionalInformation, Products } from "./components";

import "./order.scss";

export function OrderPage() {
  const { id }    = useParams();
  const dispatch  = useAppDispatch();
  const { order, status: { isLoading, error, }, isChanged, isDeliver, statuses } = useAppSelector(state => state.order);

  const [isTrackNumberUpdate, setIsTrackNumberUpdate] = useState(false);

  const {
    updateFirstName,
    updateLastName,
    updateThirdName,
    updatePhoneNumber,
    updateEmail,
    updateAdminComment,
    updateTrackNumber,
    updatePromoCode,
    updateStatus,
    switchIsDeliver,
    updateCountry,
    updateState,
    updateCity,
    updateStreet,
    updateBuilding,
    updateZip,
    updateFloor,
    updateEntrance,
    updateApartment,
    updateCustomerComment,
  } = orderSlice.actions;

  const isDisabled = false; // fetch
  
  useEffect(() => {
    if (typeof id !== "string") { return; }
    dispatch(fetchOrder(id));
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const [isNotice, setIsNotice]   = useState(true);
  const updateIsNoticeHandler     = (newValue: boolean) => { setIsNotice(newValue); }

  if (isLoading) {
    return (
      <div className="orders">
        <Loader className="loader" />
      </div>
    );
  }

  if (error || !order) {
    return (
      <div className="orders noticed">
        <SomethingWrong />
      </div>
    );
  }

  const updateFirstNameHandler    = (newValue: string) => { dispatch(updateFirstName(newValue)); }
  const updateLastNameHandler     = (newValue: string) => { dispatch(updateLastName(newValue)); }
  const updateThirdNameHandler    = (newValue: string) => { dispatch(updateThirdName(newValue)); }
  const updatePhoneNumberHandler  = (newValue: string) => { dispatch(updatePhoneNumber(newValue)); }
  const updateEmailHandler        = (newValue: string) => { dispatch(updateEmail(newValue)); }

  const contactInfo = {
    updateFirstNameHandler,
    updateLastNameHandler,
    updateThirdNameHandler,
    updatePhoneNumberHandler,
    updateEmailHandler,
  };

  const updateAdminCommentHandler = (newValue: string) => { dispatch(updateAdminComment(newValue)); }
  const updateTrackNumberHandler  = (newValue: string) => {
    setIsTrackNumberUpdate(true);
    dispatch(updateTrackNumber(newValue));
  }
  const updatePromoCodeHandler    = (newValue: string) => { dispatch(updatePromoCode(newValue)); }
  const updateStatusHandler       = (newValue: string) => { dispatch(updateStatus(newValue)); }

  const additionalInformation = {
    updateAdminCommentHandler,
    updateTrackNumberHandler,
    updatePromoCodeHandler,
    updateStatusHandler,
    updateIsNoticeHandler,
  };

  const switchIsDeliverHandler        = () => { dispatch(switchIsDeliver()); }
  const updateCountryHandler          = (newValue: string) => { dispatch(updateCountry(newValue)); }
  const updateStateHandler            = (newValue: string) => { dispatch(updateState(newValue)); }
  const updateCityHandler             = (newValue: string) => { dispatch(updateCity(newValue)); }
  const updateStreetHandler           = (newValue: string) => { dispatch(updateStreet(newValue)); }
  const updateBuildingHandler         = (newValue: string) => { dispatch(updateBuilding(newValue)); }
  const updateZipHandler              = (newValue: string) => { dispatch(updateZip(newValue)); }
  const updateFloorHandler            = (newValue: string) => { dispatch(updateFloor(newValue)); }
  const updateEntranceHandler         = (newValue: string) => { dispatch(updateEntrance(newValue)); }
  const updateApartmentHandler        = (newValue: string) => { dispatch(updateApartment(newValue)); }
  const updateCustomerCommentHandler  = (newValue: string) => { dispatch(updateCustomerComment(newValue)); }

  const delivery = {
    updateCountryHandler,
    updateStateHandler,
    updateCityHandler,
    updateStreetHandler,
    updateBuildingHandler,
    updateZipHandler,
    updateFloorHandler,
    updateEntranceHandler,
    updateApartmentHandler,
    updateCommentHandler: updateCustomerCommentHandler,
  };

  const additionalInfo = {
    comment:      order.commentAdmin,
    isPaid:       order.is_paid,
    isChecked:    order.is_checked,
    trackNumber:  order.trackNumber,
    paymentType:  order.paymentType,
    promoCode:    order.promocode,
    status:       order.status,
    isNotice:     isNotice,
    statuses:     statuses!.map(status => status.name),
    isTrackNumberUpdate,
  };

  const saveHandler = () => {
    const statusValue = () => {
      let value = -1;
      statuses!.forEach(status => {
        if (status.name === order.status) {
          value = status.value;
          return;
        }
      });
      return value;
    };

    dispatch(updateOrder({
      order,
      isDeliver,
      status: statusValue(),
      isNotice
    }));

    updateIsNoticeHandler(true);
  };
  
  return (
    <div className="order">
      <div className="description">
        <Button disabled={!isChanged} onClick={saveHandler}>Save</Button>
        <div className="description-title">{order.system_id}</div>
      </div>
      <div className="grid">
        <div className="block">
          <div className="title">Contact info</div>
          <User {...order.user} isDisabled={isDisabled} {...contactInfo} />
        </div>
        <div className="block">
          <div className="title">Specify Shopping Method</div>
          <SelectorWithLabel
            currentValue={isDeliver ? "Delivery" : "Pickup from showroom"}
            label="Specify Shopping Method"
            readOnly={true}
            values={["Pickup from showroom", "Delivery"]}
            onChange={switchIsDeliverHandler}
          />
          {isDeliver && <Delivery {...order.delivery!} isDisabled={isDisabled} {...delivery} />}
        </div>
        <div className="block">
          <div className="title">Additional Information</div>
          <AdditionalInformation {...additionalInfo} isDisabled={isDisabled} {...additionalInformation} />
        </div>
      </div>
      <div className="products">
        <Products />
      </div>
    </div>
  )
}
