
import { useNavigate } from "react-router-dom";
import { GroupsRequiredFields, updateGroups, useAppDispatch } from "store";
import { GroupsForm } from "./form";

export function GroupsEditPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const updateHandler = (fields: GroupsRequiredFields) => {
    dispatch(updateGroups(fields))
      .then(result => {
        if ((result as any).error) return;
        navigate("/groups/edit/" + fields.alias);
      });
  };

  return (
    <GroupsForm compleatedCallback={updateHandler} />
  );
}
