import { useEffect, useMemo, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import { Button, InputWithLabel, Loader, SelectorWithLabel, Selector } from "common";
import { useAppDispatch, useAppSelector, fetchHomepages, addHomepages, updateHomepages, deleteHomepages, SectionsForPage, fetchUploads, fetchCollections, fetchGroupsHP } from "store";
import { nanoid } from "@reduxjs/toolkit";

import "./homepage.scss";

export function HomepagePage() {
  const dispatch = useAppDispatch();
  const { pages, collections, groups, status } = useAppSelector(state => state.homepage);
  const { list, status: { isLoading, error } } = useAppSelector(state => state.uploads);
  const listWrap = useMemo(() => {
    return [...list, ""];
  }, [list]);

  const NEW_PAGE = "— new page —";
  
  const collectionsWrap = useMemo(() => {
    return [...collections.map(item => item.alias), ""];
  }, [collections]);

  const groupsWrap = useMemo(() => {
    return [...groups.map(item => item.alias), ""];
  }, [groups]);

  const names = pages.pages.map(item => item.name);
  names.push(NEW_PAGE);

  const [selectedNamePage, setSelectedNamePage] = useState(NEW_PAGE);

  const [idPage, setIdPage] = useState<undefined | number>(undefined);
  const [namePage, setNamePage] = useState("");
  const [descriptionPage, setDescriptionPage] = useState("");
  const [isDefaultPage, setIsDefaultPage] = useState(false);
  const [pageSections, setPageSections] = useState<{
    ru: SectionsForPage,
    en: SectionsForPage,
  }>({
    ru: [],
    en: [],
  });

  useEffect(() => {
    const selectedElem = pages.pages.find(item => item.name === selectedNamePage);

    if (selectedElem === undefined || selectedNamePage === NEW_PAGE) {
      setIdPage(undefined);
      setNamePage("");
      setDescriptionPage("");
      setPageSections({
        ru: [],
        en: [],
      });
      setIsDefaultPage(false);
    } else {
      setIdPage(selectedElem.id);
      setNamePage(selectedElem.name);
      setDescriptionPage(selectedElem.description);
      setIsDefaultPage(selectedElem.isDefault);
      setPageSections(selectedElem.json);
    }
  }, [selectedNamePage, pages]);

  useEffect(() => {
    dispatch(fetchHomepages());
    dispatch(fetchUploads());
    dispatch(fetchCollections());
    dispatch(fetchGroupsHP());
  }, []);

  const addHomepagesHandler = () => {
    if (namePage.length === 0) {
      return alert("Fill in required fields");
    }

    let isVadid = true;

    pageSections.ru.map(section => {
      if (section.type === "html" && (section.desktopText.length === 0 || section.mobileText.length === 0)) {
        isVadid = false;
      }
      if (section.type === "banner" && (section.mobileImg.length === 0 || section.desktopImg.length === 0)) {
        isVadid = false;
      }
      if (section.type === "banner-split" && (section.firstDesktopImg.length === 0 || section.firstMobileImg.length === 0 || section.secondDesktopImg.length === 0 || section.secondMobileImg.length === 0)) {
        isVadid = false;
      }
      if (section.type === "collection" && (section.title.length === 0 || section.alias.length === 0)) {
        isVadid = false;
      }
      if (section.type === "group" && (section.title.length === 0 || section.alias.length === 0)) {
        isVadid = false;
      }
    });

    pageSections.en.map(section => {
      if (section.type === "html" && (section.desktopText.length === 0 || section.mobileText.length === 0)) {
        isVadid = false;
      }
      if (section.type === "banner" && (section.mobileImg.length === 0 || section.desktopImg.length === 0)) {
        isVadid = false;
      }
      if (section.type === "banner-split" && (section.firstDesktopImg.length === 0 || section.firstMobileImg.length === 0 || section.secondDesktopImg.length === 0 || section.secondMobileImg.length === 0)) {
        isVadid = false;
      }
      if (section.type === "collection" && (section.title.length === 0 || section.alias.length === 0)) {
        isVadid = false;
      }
      if (section.type === "group" && (section.title.length === 0 || section.alias.length === 0)) {
        isVadid = false;
      }
    });

    if (namePage.length === 0 || !isVadid) {
      return alert("Fill in required fields");
    }

    if (idPage) {
      dispatch(updateHomepages({
        id: idPage,
        name: namePage,
        description: descriptionPage,
        json: pageSections,
        is_default: isDefaultPage,
      }))
        .then(result => {
          if ((result as any).error) return;
          dispatch(fetchHomepages());
        });
    } else {
      dispatch(addHomepages({
        name: namePage,
        description: descriptionPage,
        json: pageSections,
        is_default: isDefaultPage,
      }))
        .then(result => {
          if ((result as any).error) return;
          dispatch(fetchHomepages());
        });
    }
  };

  const deleteHomepagesHandler = () => {
    if (idPage && window.confirm("Are you sure?")) {
      dispatch(deleteHomepages(idPage))
        .then(result => {
          if ((result as any).error) return;
          setSelectedNamePage(NEW_PAGE);
          dispatch(fetchHomepages());
        });
    }
  };

  const addSection = (sectionLang: "ru" | "en") => {
    const _pageSections = [...pageSections[sectionLang], {
      type: "html",
      desktopText: "",
      mobileText: "",
      nano: nanoid(),
    }];

    setPageSections({
      ...pageSections,
      [sectionLang]: _pageSections,
    });
  };

  const changeSectionType = (sectionLang: "ru" | "en", nano: string, newType: any) => {
    const _pageSections = pageSections[sectionLang].map(section => {
      if (section.nano === nano) {
        if (newType === "html") {
          return {
            type: "html",
            desktopText: "",
            mobileText: "",
            nano: section.nano,
          }
        } else if (newType === "banner") {
          return {
            link: "",
            type: "banner",
            desktopImg: "",
            mobileImg: "",
            nano: section.nano,
          }
        } else if (newType === "banner-split") {
          return {
            firstLink: "",
            secondLink: "",
            type: "banner-split",
            nano: section.nano,
            firstDesktopImg: "",
            firstMobileImg: "",
            secondDesktopImg: "",
            secondMobileImg: "",
          }
        } else if (newType === "collection") {
          return {
            type: "collection",
            alias: "",
            title: "",
            description: "",
            nano: section.nano,
          }
        }
        else if (newType === "group") {
          return {
            type: "group",
            alias: "",
            title: "",
            description: "",
            nano: section.nano,
          }
        }
      }
      return {...section};
    });
  
    setPageSections({
      ...pageSections,
      [sectionLang]: _pageSections,
    });
  };

  const changeHtmlSectionMobile = (sectionLang: "ru" | "en", nano: string, newValue: string) => {
    const _pageSections = pageSections[sectionLang].map(section => {
      if (section.nano === nano) { return { ...section, mobileText: newValue } }
      return {...section};
    });

    setPageSections({
      ...pageSections,
      [sectionLang]: _pageSections,
    });
  };

  const changeHtmlSectionDesktop = (sectionLang: "ru" | "en", nano: string, newValue: string) => {
    const _pageSections = pageSections[sectionLang].map(section => {
      if (section.nano === nano) { return { ...section, desktopText: newValue } }
      return {...section};
    });

    setPageSections({
      ...pageSections,
      [sectionLang]: _pageSections,
    });
  };

  const changeBannerSectionDesktop = (sectionLang: "ru" | "en", nano: string, newValue: string) => {
    const _pageSections = pageSections[sectionLang].map(section => {
      if (section.nano === nano) { return { ...section, desktopImg: newValue } }
      return {...section};
    });

    setPageSections({
      ...pageSections,
      [sectionLang]: _pageSections,
    });
  };

  const changeBannerSectionMobile = (sectionLang: "ru" | "en", nano: string, newValue: string) => {
    const _pageSections = pageSections[sectionLang].map(section => {
      if (section.nano === nano) { return { ...section, mobileImg: newValue } }
      return {...section};
    });

    setPageSections({
      ...pageSections,
      [sectionLang]: _pageSections,
    });
  };

  const changeFirstBannerSectionDesktop = (sectionLang: "ru" | "en", nano: string, newValue: string) => {
    const _pageSections = pageSections[sectionLang].map(section => {
      if (section.nano === nano) { return { ...section, firstDesktopImg: newValue } }
      return {...section};
    });

    setPageSections({
      ...pageSections,
      [sectionLang]: _pageSections,
    });
  };

  const changeFirstBannerSectionMobile = (sectionLang: "ru" | "en", nano: string, newValue: string) => {
    const _pageSections = pageSections[sectionLang].map(section => {
      if (section.nano === nano) { return { ...section, firstMobileImg: newValue } }
      return {...section};
    });

    setPageSections({
      ...pageSections,
      [sectionLang]: _pageSections,
    });
  };

  const changeSecondBannerSectionDesktop = (sectionLang: "ru" | "en", nano: string, newValue: string) => {
    const _pageSections = pageSections[sectionLang].map(section => {
      if (section.nano === nano) { return { ...section, secondDesktopImg: newValue } }
      return {...section};
    });

    setPageSections({
      ...pageSections,
      [sectionLang]: _pageSections,
    });
  };

  const changeSecondBannerSectionMobile = (sectionLang: "ru" | "en", nano: string, newValue: string) => {
    const _pageSections = pageSections[sectionLang].map(section => {
      if (section.nano === nano) { return { ...section, secondMobileImg: newValue } }
      return {...section};
    });

    setPageSections({
      ...pageSections,
      [sectionLang]: _pageSections,
    });
  };

  const changeLink = (sectionLang: "ru" | "en", nano: string, newValue: string) => {
    const _pageSections = pageSections[sectionLang].map(section => {
      if (section.nano === nano) { return { ...section, link: newValue } }
      return {...section};
    });

    setPageSections({
      ...pageSections,
      [sectionLang]: _pageSections,
    });
  };

  const changeFirstLink = (sectionLang: "ru" | "en", nano: string, newValue: string) => {
    const _pageSections = pageSections[sectionLang].map(section => {
      if (section.nano === nano) { return { ...section, firstLink: newValue } }
      return {...section};
    });

    setPageSections({
      ...pageSections,
      [sectionLang]: _pageSections,
    });
  };

  const changeSecondLink = (sectionLang: "ru" | "en", nano: string, newValue: string) => {
    const _pageSections = pageSections[sectionLang].map(section => {
      if (section.nano === nano) { return { ...section, secondLink: newValue } }
      return {...section};
    });

    setPageSections({
      ...pageSections,
      [sectionLang]: _pageSections,
    });
  };

  const changeCollectionTitle = (sectionLang: "ru" | "en", nano: string, newValue: string) => {
    const _pageSections = pageSections[sectionLang].map(section => {
      if (section.nano === nano) { return { ...section, title: newValue } }
      return {...section};
    });

    setPageSections({
      ...pageSections,
      [sectionLang]: _pageSections,
    });
  };

  const changeCollectionDescription = (sectionLang: "ru" | "en", nano: string, newValue: string) => {
    const _pageSections = pageSections[sectionLang].map(section => {
      if (section.nano === nano) { return { ...section, description: newValue } }
      return {...section};
    });

    setPageSections({
      ...pageSections,
      [sectionLang]: _pageSections,
    });
  };

  const changeCollectionAlias = (sectionLang: "ru" | "en", nano: string, newValue: string) => {
    const _pageSections = pageSections[sectionLang].map(section => {
      if (section.nano === nano) { return { ...section, alias: newValue } }
      return {...section};
    });

    setPageSections({
      ...pageSections,
      [sectionLang]: _pageSections,
    });
  };


  const deleteSection = (sectionLang: "ru" | "en", nano: string) => {
    if (window.confirm("Are you sure?")) {
      const _pageSections = pageSections[sectionLang].filter(page => {
        if (page.nano === nano) { return false };
        return true;
      });

      setPageSections({
        ...pageSections,
        [sectionLang]: _pageSections,
      });
    }
  }

  if (pages.status.isLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <SelectorWithLabel
        label="All homepages"
        currentValue={selectedNamePage}
        onChange={e => setSelectedNamePage(e.currentTarget.value)}
        values={names}
      />
      <div>
        <InputWithLabel
          label="Name *"
          value={namePage}
          onChange={e => setNamePage(e.currentTarget.value)}
        />
        <InputWithLabel
          label="Description"
          value={descriptionPage}
          onChange={e => setDescriptionPage(e.currentTarget.value)}
        />
        <InputWithLabel
          label="Is default"
          type="checkbox"
          checked={isDefaultPage}
          onChange={() => { setIsDefaultPage(!isDefaultPage) }}
        />
        <Tabs>
          <TabList>
            <Tab>RU</Tab>
            <Tab>EN</Tab>
          </TabList>
          <TabPanel>
            {pageSections.ru.map(section => {
              if (section.type === "html") {
                return <div key={section.nano} className="section-page">
                  <Selector
                    currentValue={section.type}
                    values={["html", "banner", "banner-split", "collection", "group"]}
                    onChange={e => { changeSectionType("ru", section.nano, e.currentTarget.value as any) }}
                  />
                  <div className="section-page__header">
                    <div>HTML</div>
                    <div onClick={() => deleteSection("ru", section.nano)} className="section-page__header-delete">delete</div>
                  </div>
                  <div>
                    <InputWithLabel
                      type="textarea"
                      label="HTML desktop editor *"
                      value={section.desktopText}
                      onChange={e => changeHtmlSectionDesktop("ru", section.nano, e.currentTarget.value) }
                    />
                  </div>
                  <div>
                    <InputWithLabel
                      type="textarea"
                      label="HTML mobile editor *"
                      value={section.mobileText}
                      onChange={e => changeHtmlSectionMobile("ru", section.nano, e.currentTarget.value) }
                    />
                  </div>
                </div>
              }
              if (section.type === "banner") {
                return <div key={section.nano} className="section-page">
                  <Selector
                    currentValue={section.type}
                    values={["html", "banner", "banner-split", "collection", "group"]}
                    onChange={e => { changeSectionType("ru", section.nano, e.currentTarget.value as any) }}
                  />
                  <div className="section-page__header">
                    <div>Banner</div>
                    <div onClick={() => deleteSection("ru", section.nano)} className="section-page__header-delete">delete</div>
                  </div>
                  <InputWithLabel
                    label="Link"
                    value={section.link}
                    onChange={e => { changeLink("ru", section.nano, e.currentTarget.value as any) }}
                  />
                  <SelectorWithLabel
                    label="For desktop *"
                    currentValue={section.desktopImg}
                    values={listWrap}
                    onChange={e => { changeBannerSectionDesktop("ru", section.nano, e.currentTarget.value as any) }}
                  />
                  <SelectorWithLabel
                    label="For mobile *"
                    currentValue={section.mobileImg}
                    values={listWrap}
                    onChange={e => { changeBannerSectionMobile("ru", section.nano, e.currentTarget.value as any) }}
                  />
                </div>
              }

              if (section.type === "banner-split") {
                return <div key={section.nano} className="section-page">
                  <Selector
                    currentValue={section.type}
                    values={["html", "banner", "banner-split", "collection", "group"]}
                    onChange={e => { changeSectionType("ru", section.nano, e.currentTarget.value as any) }}
                  />
                  <div className="section-page__header">
                    <div>Banner split</div>
                    <div onClick={() => deleteSection("ru", section.nano)} className="section-page__header-delete">delete</div>
                  </div>
                  <InputWithLabel
                    label="First link"
                    value={section.firstLink}
                    onChange={e => { changeFirstLink("ru", section.nano, e.currentTarget.value as any) }}
                  />
                  <InputWithLabel
                    label="Second link"
                    value={section.secondLink}
                    onChange={e => { changeSecondLink("ru", section.nano, e.currentTarget.value as any) }}
                  />
                  <SelectorWithLabel
                    label="First desktop img *"
                    currentValue={section.firstDesktopImg}
                    values={listWrap}
                    onChange={e => { changeFirstBannerSectionDesktop("ru", section.nano, e.currentTarget.value as any) }}
                  />
                  <SelectorWithLabel
                    label="Second desktop img *"
                    currentValue={section.secondDesktopImg}
                    values={listWrap}
                    onChange={e => { changeSecondBannerSectionDesktop("ru", section.nano, e.currentTarget.value as any) }}
                  />
                  <SelectorWithLabel
                    label="First mobile img *"
                    currentValue={section.firstMobileImg}
                    values={listWrap}
                    onChange={e => { changeFirstBannerSectionMobile("ru", section.nano, e.currentTarget.value as any) }}
                  />
                  <SelectorWithLabel
                    label="Second mobile img *"
                    currentValue={section.secondMobileImg}
                    values={listWrap}
                    onChange={e => { changeSecondBannerSectionMobile("ru", section.nano, e.currentTarget.value as any) }}
                  />
                </div>
              }
              if (section.type === "group") {
                return <div key={section.nano} className="section-page">
                  <div className="section-page__header">
                    <div>Group</div>
                    <div onClick={() => deleteSection("ru", section.nano)} className="section-page__header-delete">delete</div>
                  </div>
                  <Selector
                    currentValue={section.type}
                    values={["html", "banner", "banner-split", "collection", "group"]}
                    onChange={e => { changeSectionType("ru", section.nano, e.currentTarget.value as any) }}
                  />
                  <InputWithLabel
                    label="Title *"
                    value={section.title}
                    onChange={e => { changeCollectionTitle("ru", section.nano, e.currentTarget.value as any) }}
                  />
                  <InputWithLabel
                    label="Description"
                    value={section.description}
                    onChange={e => { changeCollectionDescription("ru", section.nano, e.currentTarget.value as any) }}
                  />
                  <SelectorWithLabel
                    label="Select group by alias *"
                    currentValue={section.alias}
                    values={groupsWrap}
                    onChange={e => { changeCollectionAlias("ru", section.nano, e.currentTarget.value as any) }}
                  />
                </div>
              }
              return <div key={section.nano} className="section-page">
                <div className="section-page__header">
                  <div>Collection</div>
                  <div onClick={() => deleteSection("ru", section.nano)} className="section-page__header-delete">delete</div>
                </div>
                <Selector
                  currentValue={section.type}
                  values={["html", "banner", "banner-split", "collection", "group"]}
                  onChange={e => { changeSectionType("ru", section.nano, e.currentTarget.value as any) }}
                />
                <InputWithLabel
                  label="Title *"
                  value={section.title}
                  onChange={e => { changeCollectionTitle("ru", section.nano, e.currentTarget.value as any) }}
                />
                <InputWithLabel
                  label="Description"
                  value={section.description}
                  onChange={e => { changeCollectionDescription("ru", section.nano, e.currentTarget.value as any) }}
                />
                <SelectorWithLabel
                  label="Select collection by alias *"
                  currentValue={section.alias}
                  values={collectionsWrap}
                  onChange={e => { changeCollectionAlias("ru", section.nano, e.currentTarget.value as any) }}
                />
              </div>
            })}
            <Button disabled={status.isLoading} onClick={() => addSection("ru")}>Add section</Button>
          </TabPanel>
          <TabPanel>
            {pageSections.en.map(section => {
              if (section.type === "html") {
                return <div key={section.nano} className="section-page">
                  <Selector
                    currentValue={section.type}
                    values={["html", "banner", "banner-split", "collection", "group"]}
                    onChange={e => { changeSectionType("en", section.nano, e.currentTarget.value as any) }}
                  />
                  <div className="section-page__header">
                    <div>HTML</div>
                    <div onClick={() => deleteSection("en", section.nano)} className="section-page__header-delete">delete</div>
                  </div>
                  <div>
                    <InputWithLabel
                      type="textarea"
                      label="HTML desktop editor *"
                      value={section.desktopText}
                      onChange={e => changeHtmlSectionDesktop("en", section.nano, e.currentTarget.value) }
                    />
                  </div>
                  <div>
                    <InputWithLabel
                      type="textarea"
                      label="HTML mobile editor *"
                      value={section.mobileText}
                      onChange={e => changeHtmlSectionMobile("en", section.nano, e.currentTarget.value) }
                    />
                  </div>
                </div>
              }
              if (section.type === "banner") {
                return <div key={section.nano} className="section-page">
                  <Selector
                    currentValue={section.type}
                    values={["html", "banner", "banner-split", "collection", "group"]}
                    onChange={e => { changeSectionType("en", section.nano, e.currentTarget.value as any) }}
                  />
                  <div className="section-page__header">
                    <div>Banner</div>
                    <div onClick={() => deleteSection("en", section.nano)} className="section-page__header-delete">delete</div>
                  </div>
                  <InputWithLabel
                    label="Link"
                    value={section.link}
                    onChange={e => { changeLink("en", section.nano, e.currentTarget.value as any) }}
                  />
                  <SelectorWithLabel
                    label="For desktop *"
                    currentValue={section.desktopImg}
                    values={listWrap}
                    onChange={e => { changeBannerSectionDesktop("en", section.nano, e.currentTarget.value as any) }}
                  />
                  <SelectorWithLabel
                    label="For mobile *"
                    currentValue={section.mobileImg}
                    values={listWrap}
                    onChange={e => { changeBannerSectionMobile("en", section.nano, e.currentTarget.value as any) }}
                  />
                </div>
              }

              if (section.type === "banner-split") {
                return <div key={section.nano} className="section-page">
                  <Selector
                    currentValue={section.type}
                    values={["html", "banner", "banner-split", "collection", "group"]}
                    onChange={e => { changeSectionType("en", section.nano, e.currentTarget.value as any) }}
                  />
                  <div className="section-page__header">
                    <div>Banner split</div>
                    <div onClick={() => deleteSection("en", section.nano)} className="section-page__header-delete">delete</div>
                  </div>
                  <InputWithLabel
                    label="First link"
                    value={section.firstLink}
                    onChange={e => { changeFirstLink("en", section.nano, e.currentTarget.value as any) }}
                  />
                  <InputWithLabel
                    label="Second link"
                    value={section.secondLink}
                    onChange={e => { changeSecondLink("en", section.nano, e.currentTarget.value as any) }}
                  />
                  <SelectorWithLabel
                    label="First desktop img *"
                    currentValue={section.firstDesktopImg}
                    values={listWrap}
                    onChange={e => { changeFirstBannerSectionDesktop("en", section.nano, e.currentTarget.value as any) }}
                  />
                  <SelectorWithLabel
                    label="Second desktop img *"
                    currentValue={section.secondDesktopImg}
                    values={listWrap}
                    onChange={e => { changeSecondBannerSectionDesktop("en", section.nano, e.currentTarget.value as any) }}
                  />
                  <SelectorWithLabel
                    label="First mobile img *"
                    currentValue={section.firstMobileImg}
                    values={listWrap}
                    onChange={e => { changeFirstBannerSectionMobile("en", section.nano, e.currentTarget.value as any) }}
                  />
                  <SelectorWithLabel
                    label="Second mobile img *"
                    currentValue={section.secondMobileImg}
                    values={listWrap}
                    onChange={e => { changeSecondBannerSectionMobile("en", section.nano, e.currentTarget.value as any) }}
                  />
                </div>
              }
              if (section.type === "group") {
                return <div key={section.nano} className="section-page">
                  <div className="section-page__header">
                    <div>Group</div>
                    <div onClick={() => deleteSection("en", section.nano)} className="section-page__header-delete">delete</div>
                  </div>
                  <Selector
                    currentValue={section.type}
                    values={["html", "banner", "banner-split", "collection", "group"]}
                    onChange={e => { changeSectionType("en", section.nano, e.currentTarget.value as any) }}
                  />
                  <InputWithLabel
                    label="Title *"
                    value={section.title}
                    onChange={e => { changeCollectionTitle("en", section.nano, e.currentTarget.value as any) }}
                  />
                  <InputWithLabel
                    label="Description"
                    value={section.description}
                    onChange={e => { changeCollectionDescription("en", section.nano, e.currentTarget.value as any) }}
                  />
                  <SelectorWithLabel
                    label="Select group by alias *"
                    currentValue={section.alias}
                    values={groupsWrap}
                    onChange={e => { changeCollectionAlias("en", section.nano, e.currentTarget.value as any) }}
                  />
                </div>
              }
              return <div key={section.nano} className="section-page">
                <div className="section-page__header">
                  <div>Collection</div>
                  <div onClick={() => deleteSection("en", section.nano)} className="section-page__header-delete">delete</div>
                </div>
                <Selector
                  currentValue={section.type}
                  values={["html", "banner", "banner-split", "collection", "group"]}
                  onChange={e => { changeSectionType("en", section.nano, e.currentTarget.value as any) }}
                />
                <InputWithLabel
                  label="Title *"
                  value={section.title}
                  onChange={e => { changeCollectionTitle("en", section.nano, e.currentTarget.value as any) }}
                />
                <InputWithLabel
                  label="Description"
                  value={section.description}
                  onChange={e => { changeCollectionDescription("en", section.nano, e.currentTarget.value as any) }}
                />
                <SelectorWithLabel
                  label="Select collection by alias *"
                  currentValue={section.alias}
                  values={collectionsWrap}
                  onChange={e => { changeCollectionAlias("en", section.nano, e.currentTarget.value as any) }}
                />
              </div>
            })}
            <Button disabled={status.isLoading} onClick={() => addSection("en")}>Add section</Button>
          </TabPanel>
        </Tabs>  
      </div>
      <Button onClick={addHomepagesHandler} disabled={status.isLoading}>{idPage ? "Update" : "Create"}</Button>
      {idPage && <Button onClick={deleteHomepagesHandler} disabled={status.isLoading}>Delete</Button>}
      <div>{status.error?.message}</div>
    </div>
  );
}