import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Button, Input } from "common";
import { fetchUsers, useAppDispatch } from "store";

export function Search() {
  const { search } = useLocation();
  const dispatch  = useAppDispatch();
  const navigate = useNavigate();
  
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(search);
    const searchFromURL = params.get("search");
    if (searchFromURL) setSearchValue(searchFromURL)
  }, []);

  const refreshUsers = () => {
    navigate("?search=" + searchValue);
    dispatch(fetchUsers());
  };

  return (
    <div className="users__search">
      <Input
        placeholder="Search"
        value={searchValue}
        onChange={e => { setSearchValue(e.currentTarget.value) }}
      />
      <Button onClick={refreshUsers}>Search</Button>
    </div>
  );
}