import { NavLink } from "react-router-dom";

import "./not-found.scss";

export function NotFoundPage() {
  return (
    <div className="not-found-mobile-page" role="dialog">
      <div className="contentinfo">
        <div className="text">Page not found</div>
        <NavLink to="/" className="link">Go to home</NavLink>
      </div>
    </div>
  );
}
