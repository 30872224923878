import { useEffect, useMemo, useState } from "react";
import { format } from "date-format-parse";
import queryString from "query-string";
import { Link, NavLink, useNavigate } from "react-router-dom";

import { Button, DateRange, Loader, Pagination, Selector, SomethingWrong } from "common";
import { fetchOrders, updateOrdersStatuses, useAppDispatch, useAppSelector } from "store";
import { getCurrency } from "helpers";

import "./orders.scss";
import { SERVER_URL } from "config";
import axios from "axios";

export function OrdersPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const page = queryString.parse(window.location.search).page;
  const byStatus = queryString.parse(window.location.search).by_status;

  const { orders, status: { isLoading, error } } = useAppSelector(state => state.orders);

  const [statuses, setStatuses] = useState<{value: number, name: string}[]>([]);

  const [newStatuses, setNewStatuses] = useState<Map<number, string>>(new Map());

  const [showFilters, setShowFilters] = useState(false);

  const [currentStatus, setCurrentStatus] = useState("");

  const [sortBy, setSortBy] = useState<"date_asc" | "date_desc" | "price_asc" | "price_desc">();
  const [dateRange, setDateRange] = useState<{
    startDate: Date,
    endDate: Date,
  }>({
    startDate: new Date(),
    endDate: new Date(),
  });

  const isChanged = useMemo(() => {
    if (newStatuses.size === 0) return false;
    return true;
  }, [newStatuses]);

  const getStatuses = async () => {
    const statusesConfig = {
      url: SERVER_URL + "/orders/statuses",
      method: "get",
      withCredentials: true,
    };

    try {
      const { data } = await axios(statusesConfig);
      setStatuses(data.items)
    } catch (err) {}
  }

  const _status = (status: string) => {
    if (status === "opened") return "Открыт (opened)"
    if (status === "denied") return "Отклонен (denied)"
    if (status === "completed") return "Выполнен (completed)"
    if (status === "fail") return "Неудача (fail)"
    if (status === "returned") return "Возвращено (returned)"
    if (status === "withheld") return "Отложен (withheld)"
    if (status === "pickup") return "Самозабор из шоурума (pickup)"
    if (status === "handled") return "Обработан (handled)"
    if (status === "canceled") return "Аннулирован (canceled)"
    if (status === "call_waiting") return "Ожидает звонка (call_waiting)"
    if (status === "postponed") return "Перенесенный (postponed)"
    if (status === "unfinished") return "Незавершенный (unfinished)"
    if (status === "on_delivery") return "В процессе доставки (on_delivery)"
    return status;
  };

  const _statuses = statuses.map(item => item.name).map(item => {
    if (item === "opened") return "Открыт (opened)"
    if (item === "denied") return "Отклонен (denied)"
    if (item === "completed") return "Выполнен (completed)"
    if (item === "fail") return "Неудача (fail)"
    if (item === "returned") return "Возвращено (returned)"
    if (item === "withheld") return "Отложен (withheld)"
    if (item === "pickup") return "Самозабор из шоурума (pickup)"
    if (item === "handled") return "Обработан (handled)"
    if (item === "canceled") return "Аннулирован (canceled)"
    if (item === "call_waiting") return "Ожидает звонка (call_waiting)"
    if (item === "postponed") return "Перенесенный (postponed)"
    if (item === "unfinished") return "Незавершенный (unfinished)"
    if (item === "on_delivery") return "В процессе доставки (on_delivery)"
    return item;
  });

  const updateStatusesHandler = (e: React.FormEvent<HTMLSelectElement>, id?: number) => {
    let status = "";
    const value = e.currentTarget.value;

    if (value === "Открыт (opened)") status = "opened"
    else if (value === "Отклонен (denied)") status = "denied"
    else if (value === "Выполнен (completed)") status = "completed"
    else if (value === "Неудача (fail)") status = "fail"
    else if (value === "Возвращено (returned)") status = "returned"
    else if (value === "Отложен (withheld)") status = "withheld"
    else if (value === "Самозабор из шоурума (pickup)") status = "pickup"
    else if (value === "Обработан (handled)") status = "handled"
    else if (value === "Аннулирован (canceled)") status = "canceled"
    else if (value === "Ожидает звонка (call_waiting)") status = "call_waiting"
    else if (value === "Перенесенный (postponed)") status = "postponed"
    else if (value === "Незавершенный (unfinished)") status = "unfinished"
    else if (value === "В процессе доставки (on_delivery)") status = "on_delivery"
    else status = value;

    if (id) {
      const _tempNewStatuses = new Map(newStatuses);
      _tempNewStatuses.set(id, status);
      setNewStatuses(_tempNewStatuses);
    } else {
      setCurrentStatus(value);
      const tempQuery = queryString.parse(window.location.search);

      if (value === "all") {
        delete tempQuery["by_status"]
      } else {
        tempQuery.by_status = "" + status;
      }

      const tempQueryAsString = queryString.stringify(tempQuery);
      navigate("?" + tempQueryAsString);
    }
  }

  useEffect(() => {
    if (sortBy === null) {
      return;
    }
    const tempQuery: any = queryString.parse(window.location.search);
    tempQuery.sorting = sortBy;
    const tempQueryAsString = queryString.stringify(tempQuery);
    navigate("?" + tempQueryAsString);
  }, [sortBy]);

  useEffect(() => {
    dispatch(fetchOrders());
    getStatuses();
  }, [page, byStatus, sortBy]); // eslint-disable-line react-hooks/exhaustive-deps

  const statusValue = (status: string) => {
    let value = -1;
    statuses!.forEach(item => {
      if (item.name === status) {
        value = item.value;
        return;
      }
    });
    return value;
  };

  const saveHandler = () => {
    const _tempStatuses = new Map<number, number>();

    newStatuses.forEach((value, key) => {
      _tempStatuses.set(key, statusValue(value));
    });

    dispatch(updateOrdersStatuses(_tempStatuses));
  };

  const sortByDate = (event: any) => {
    if (sortBy === null) {
      setSortBy("date_asc");
    } else if (sortBy === "date_asc") {
      setSortBy("date_desc");
    } else {
      setSortBy("date_asc");
    }
  };

  const sortByTotal = () => {
    if (sortBy === null) {
      setSortBy("price_asc");
    } else if (sortBy === "price_asc") {
      setSortBy("price_desc");
    } else {
      setSortBy("price_asc");
    }
  };

  const resetDateRange = () => {
    const tempQuery: any = queryString.parse(window.location.search);
    tempQuery.date_start = undefined;
    tempQuery.date_end = undefined;
    const tempQueryAsString = queryString.stringify(tempQuery);
    navigate("?" + tempQueryAsString);

    dispatch(fetchOrders());
    getStatuses();
  }

  const applyDateRange = () => {
    const tempQuery: any = queryString.parse(window.location.search);
    tempQuery.date_start = format(dateRange.startDate, "YYYY-MM-DD");
    tempQuery.date_end = format(dateRange.endDate, "YYYY-MM-DD");
    const tempQueryAsString = queryString.stringify(tempQuery);
    navigate("?" + tempQueryAsString);

    dispatch(fetchOrders());
    getStatuses();
  };

  const getDeliveryPrice = (price: number, deliveryPrice?: string) => {
    if (deliveryPrice === undefined) {
      return price;
    }
    return price + parseInt(deliveryPrice);
  };

  if (isLoading) {
    return (
      <div className="orders">
        <Loader className="loader" />
      </div>
    );
  }

  if (error || !orders) {
    return (
      <div className="orders noticed">
        <SomethingWrong />
      </div>
    );
  }

  return (
    <div className="orders">
      <div style={{ paddingBottom: "15px" }}>
        <h4 style={{ marginBottom: "5px" }}>Сохранить смену статуса заказа</h4>
        <Button style={{ height: "35px"}} disabled={!isChanged} onClick={saveHandler}>Save</Button>
      </div>
      <div><b onClick={() => setShowFilters(f => !f)} style={{ cursor: "pointer" }}>Фильтры</b></div>
      <br />
      <div className="description" style={{ display: showFilters ? "block" : "none" }}>
        <div style={{ paddingBottom: "15px" }}>
          <h4 style={{ marginBottom: "5px" }}>Выбор заказов по статусу</h4>
          <Selector
            label="Status"
            currentValue={_status(currentStatus)}
            values={["all", ..._statuses]}
            onChange={(e) => updateStatusesHandler(e)}
          />
        </div>
        <div>
          <h4 style={{ marginBottom: "5px" }}>Выбор заказов по диапазону дат</h4>
          <div><span style={{ cursor: "pointer", color: "blue" }} onClick={applyDateRange}>Применить</span></div>
          <div><span style={{ cursor: "pointer", color: "blue" }} onClick={resetDateRange}>Сбросить</span></div>
          <DateRange dateRange={dateRange} setDateRange={setDateRange} />
        </div>
        <div>
          <a style={{ color: "blue", cursor: "pointer" }} onClick={_ => {
            const config = {
              url: SERVER_URL + "/orders/export-to-csv" + window.location.search,
              method: "get",
              withCredentials: true,
            };
            axios(config)
              .then(res => {
                const data = res.data
                const blob = new Blob([data], {type: 'text/csv'});
                const elem = window.document.createElement('a');
                elem.href = window.URL.createObjectURL(blob);
                elem.download = "orders";        
                document.body.appendChild(elem);
                elem.click();        
                document.body.removeChild(elem);
              })
          }}>Выгрузить CSV</a>
        </div>
      </div>
      <table className="orders-list">
        <thead>
          <tr>
            <th>ID</th>
            <th
              style={{ cursor: "pointer", color: "blue"}}
              onClick={sortByDate}
            >Date {sortBy === "date_asc" && <>▲</>}{sortBy === "date_desc" && <>▼</>}</th>
            <th>Customer</th>
            <th>Status</th>
            <th>Items</th>
            <th
              style={{ cursor: "pointer", color: "blue"}}
              onClick={sortByTotal}
            >Total {sortBy === "price_asc" && <>▲</>}{sortBy === "price_desc" && <>▼</>}</th>
          </tr>
        </thead>
        {orders.orders.map((order: any) => (
          <tbody key={order.id}>
            <tr className="order-item">
              <td><NavLink to={"/order/" + order.id} className="link">{order.system_id}</NavLink></td>
              <td><NavLink to={"/order/" + order.id} className="link">{format(new Date(order.created_at), "DD.MM.YYYY, HH:mm")}</NavLink></td>
              <td><NavLink to={"/order/" + order.id} className="link">{order.user.firstName} {order.user.lastName}</NavLink></td>
              <td>
                <Selector
                  label="Status"
                  currentValue={_status(newStatuses.get(order.id) || order.status)}
                  values={_statuses}
                  onChange={(e) => updateStatusesHandler(e, order.id)}
                />
              </td>
              <td><NavLink to={"/order/" + order.id} className="link">{order.products.length}</NavLink></td>
              <td><NavLink to={"/order/" + order.id} className="link">{getCurrency(getDeliveryPrice(order.total, order?.delivery?.price), order.lang)}</NavLink></td>
            </tr>
          </tbody>
        ))}
      </table>
      <Pagination
        totalCount={orders.count}
        currentPage={page ? +page : 1}
        pageSize={36}
        onPageChange={(selected: number) => {
          const tempQuery = queryString.parse(window.location.search);
          tempQuery.page = "" + selected;
          const tempQueryAsString = queryString.stringify(tempQuery);
          console.log(window.location.origin)
          navigate("?" + tempQueryAsString);
        }}
        className="pagination"
      />
    </div>
  );
}
