import { format } from "date-format-parse";
import { NavLink } from "react-router-dom";

import { Loader, SomethingWrong } from "common";
import { fetchPromo, useAppDispatch, useAppSelector } from "store";
import { useEffect } from "react";

export function PromoCodePage() {
  const dispatch = useAppDispatch();

  const { promo, status } = useAppSelector(state => state.promoCode);

  useEffect(() => {
    dispatch(fetchPromo());
  }, []);

  if (status.isLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (status.isError || !promo) {
    return (
      <div>
        <SomethingWrong />
      </div>
    );
  }

  return (
    <div>
      <div className="create-or-edit__wrapper-link">
        <NavLink to={"/promo-code/create"}>Create</NavLink>
      </div>
      <div className="promo-code__list">
        <table>
          <thead>
            <tr>
              <th>Alias</th>
              <th>Name</th>
              <th>Description</th>
              <th>Expired at</th>
              <th>Published</th>
            </tr>
          </thead>
          {promo.map(item => (
            <tbody key={item.id}>
              <tr>
                <td><NavLink to={"/promo-code/edit/" + item.id}>{item.alias}</NavLink></td>
                <td><NavLink to={"/promo-code/edit/" + item.id}>{item.name}</NavLink></td>
                <td><NavLink to={"/promo-code/edit/" + item.id}>{item.description}</NavLink></td>
                <td><NavLink to={"/promo-code/edit/" + item.id}>{item.expiredAt ? format(new Date(item.expiredAt), "DD.MM.YYYY") : "–"}</NavLink></td>
                <td><NavLink to={"/promo-code/edit/" + item.id}>{item.isPublished ? "Yes" : "No"}</NavLink></td>
              </tr>
            </tbody>
          ))}
        </table>
      </div>
    </div>
  );
}
