import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios  from "axios";

import { FetchData } from "models";
import { SERVER_URL } from "config";

type BrandsFromServer = {
  name: string;
  languageCode: "ru-RU" | "en-EN";
  description: string;
  textDescription: string;
  image: null | string;
};

type BrandsForServer = {
  name: string;
  language_code: "ru-RU" | "en-EN";
  description: string;
  text_description: string;
  image: null | string;
};

type BrandState = {
  brand: BrandsFromServer | undefined;
  status: FetchData;
};

const initialState: BrandState = {
  brand: undefined,
  status: {
    isError: false,
    isLoading: false,
    error: undefined,
  },
};

export const fetchBrand = createAsyncThunk("brand/fetchBrand", ({ name, lang }: {
  name: string,
  lang: "ru-RU" | "en-EN",
}, thunkAPI) => {
  const config = {
    url: SERVER_URL + "/brands/" + name,
    method: "get",
    headers: { "Accept-Language": lang },
    withCredentials: true,
  };

  return axios(config)
    .then(({ data }: { data: BrandsFromServer }) => {
      return data;
    })
    .catch((error: any) => {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        statusText: error.response.statusText,
        message: error.response.data.message,
      } as FetchData["error"]);
    });
});

export const brandUpdate = createAsyncThunk("brand/updateBrand", (brand: BrandsForServer, thunkAPI) => {
  console.log(brand)
  const setUserConfig = {
    url: SERVER_URL + "/brands/" + brand.name,
    data: brand,
    method: "put",
    withCredentials: true,
  };

  return axios(setUserConfig)
  .then(result => {
    return result.data.password;
  })
  .catch((error: any) => {
    return thunkAPI.rejectWithValue({
      status: error.response.status,
      statusText: error.response.statusText,
      message: error.response.data.message,
    } as FetchData["error"]);
  });
});

export const brandSlice = createSlice({
  name: "brandSlice",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchBrand.fulfilled.type]: (state: BrandState, action: PayloadAction<BrandsFromServer>) => {
      state.brand = action.payload;
      state.status.isLoading = false;
      state.status.isError = false;
      state.status.error = undefined;
    },
    [fetchBrand.pending.type]: (state: BrandState) => {
      state.brand = undefined;
      state.status.isLoading = true;
      state.status.isError = false;
      state.status.error = undefined;
    },
    [fetchBrand.rejected.type]: (state: BrandState, action: PayloadAction<FetchData["error"]>) => {
      state.status.isLoading = false;
      state.status.isError = true;
      state.status.error = action.payload;
    },
    [brandUpdate.fulfilled.type]: (state: BrandState) => {
      state.status.isLoading = false;
      state.status.isError = false;
      state.status.error = undefined;
    },
    [brandUpdate.pending.type]: (state: BrandState) => {
      state.status.isLoading = true;
      state.status.isError = false;
      state.status.error = undefined;
    },
    [brandUpdate.rejected.type]: (state: BrandState, action: PayloadAction<FetchData["error"]>) => {
      state.status.isLoading = false;
      state.status.isError = true;
      state.status.error = action.payload;
    },
  }
});