import { FormEvent, useEffect, useState } from "react";

import { Input, Loader, SomethingWrong, Button } from "common";
import { addFiles, deleteFile, fetchUploads, useAppDispatch, useAppSelector } from "store";
import { SERVER_URL } from "config";


import "./uploads.scss";

export function UploadsPage() {
  const dispatch = useAppDispatch();
  const { list, status: { isLoading, error } } = useAppSelector(state => state.uploads);

  const [file, setFile] = useState<File>();

  useEffect(() => {
    dispatch(fetchUploads());
  }, []);
  
  const addFileHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (file) {
      dispatch(addFiles(file))
        .then(result => {
          if ((result as any).error) return;
          dispatch(fetchUploads());
        });
    } else {
      alert("Need to add file");
    }
  }
  
  const fileUpdateHandler = (e: FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) setFile(e.currentTarget.files[0]);
    else setFile(undefined);
  }

  const deleteFileHandler = (name: string) => {
    if (window.confirm("Are you sure?")) {
      dispatch(deleteFile(name))
        .then(result => {
          if ((result as any).error) return;
          dispatch(fetchUploads());
        });
    }
  }
  
  if (isLoading) {
    return (
      <div>
        <Loader className="loader" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="users noticed">
        <SomethingWrong />
      </div>
    );
  }

  return (
    <div>
      <form onSubmit={addFileHandler}>
        <Input type="file" onChange={fileUpdateHandler} />
        <Button type="submit">Upload</Button>
      </form>
      <div className="list">
        {list.map(item => <div key={item} className="list__item">
          <div>
            {item.match(/\.(jpg|jpeg|png|gif|webp)$/i) ? <img src={`${SERVER_URL}/images/banners/${item}`} alt={item} /> : <></>}
          </div>
          <div>{item}</div>
          <div className="list__item-delete" onClick={e => deleteFileHandler(item)}>delete</div>
        </div>)}
      </div>
    </div>
  );
}
