import axios from "axios";
import { Button, InputWithLabel, Selector } from "common/components";
import { SERVER_URL } from "config";
import { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { replaceString } from "./catalog-info.list";

export const CatalogInfoByAlias = () => {
  const [alias, setAlias] = useState("");
  const [selectLang, setSelectLang] = useState<"ru-RU" | "en-EN">("ru-RU");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [imgURL, setImgURL] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState<{
    message: string,
    fieldName: string,
  }[]>([]);

  const navigate = useNavigate();
  const { alias: byAlias } = useParams();

  useEffect(() => {
    if (byAlias === undefined) {
      return;
    }

    (async() => {
      setIsLoading(true);
      const statusesConfig = {
        url: SERVER_URL + "/admin/catalog-info/" + replaceString(byAlias),
        method: "get",
        withCredentials: true,
      };

      const { data } = await axios(statusesConfig);

      if (data) {
        data.alias && setAlias(data.alias);
        data.lang && setSelectLang(data.lang);
        data.title && setTitle(data.title);
        data.description && setDescription(data.description);
        data.image && setImgURL(data.image);
      }

      setIsLoading(false);
    })()
  }, [byAlias]);

  const handleUpdate = async () => {
    if (alias.length === 0
      || title.length === 0
      || description.length === 0
      || imgURL.length === 0
    ) {
      return 0;
    }

    const statusesConfig = {
      url: SERVER_URL + "/admin/catalog-info/" + JSON.stringify({ alias: replaceString(alias), lang: selectLang }),
      method: "put",
      withCredentials: true,
      data: {
        lang: selectLang,
        alias,
        title,
        description,
        image: imgURL,
      },
    };

    try {
      await setIsLoading(true);
      await setErrors([]);
      await axios(statusesConfig);
    } catch (err: any) {
      const error = JSON.parse(err?.request?.response ?? "{}")
      if (error['validation']) {
        const validationFields = error['validation'];
        validationFields.forEach((field: any) => {
          const fieldName: string = field.field_name;
          const message: string = field.message;
          setErrors(prevErrs => [...prevErrs, { message, fieldName }]);
        });
      }
    }
    setIsLoading(false);
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure?") === false) {
      return;
    }
    const statusesConfig = {
      url: SERVER_URL + "/admin/catalog-info/" + JSON.stringify({ alias: replaceString(alias), lang: selectLang }),
      method: "delete",
      withCredentials: true,
    };

    try {
      await setIsLoading(true);
      await setErrors([]);
      await axios(statusesConfig);
      navigate("/catalog-info");
    } catch (err: any) {
      const error = JSON.parse(err?.request?.response ?? "{}")
      if (error['validation']) {
        const validationFields = error['validation'];
        validationFields.forEach((field: any) => {
          const fieldName: string = field.field_name;
          const message: string = field.message;
          setErrors(prevErrs => [...prevErrs, { message, fieldName }]);
        });
      }
    }
    setIsLoading(false);
  };
  return (
    <div>
      <div><b><NavLink to={"/catalog-info"} className="link">back to catalog info list</NavLink></b></div>
      <br />
      <InputWithLabel
        type="string"
        label="Alias *"
        value={alias}
        onChange={e => setAlias(e.currentTarget.value)}
        disabled
      />
      <br />
      <Selector
        currentValue={selectLang}
        values={["ru-RU", "en-EN"]}
        onChange={e => { setSelectLang(e.currentTarget.value as any) }}
        disabled
      />
      <br />
      <div>
        <InputWithLabel
          type="string"
          label="Title *"
          value={title}
          onChange={e => setTitle(e.currentTarget.value)}
        />
      </div>
      <br />
      <div>
        <InputWithLabel
          type="string"
          label="Description *"
          value={description}
          onChange={e => setDescription(e.currentTarget.value)}
        />
      </div>
      <br />
      <div>
        <InputWithLabel
          type="string"
          label="Image URL *"
          value={imgURL}
          onChange={e => setImgURL(e.currentTarget.value)}
        />
      </div>
      <div>
      <br />
      <Button
        type="button"
        disabled={isLoading}
        onClick={handleUpdate}>Update</Button>
      </div>
      <Button
        type="button"
        disabled={isLoading}
        onClick={handleDelete}>Delete</Button>
      <ul style={{ color: "red" }}>
        {errors.map((error, pos)=> <li key={error.fieldName + pos}>{error.fieldName} — {error.message}</li>)}
      </ul>
    </div>
  );
}