import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios  from "axios";

import { Order, FetchData } from "models";
import { SERVER_URL } from "config";

type OrderState = {
  order: Order | undefined;
  isChanged: boolean;
  isDeliver: boolean;
  statuses: {
    value: 0,
    name: string
  }[] | undefined;
  status: FetchData;
};

const initialState: OrderState = {
  order: undefined,
  isChanged: false,
  isDeliver: false,
  statuses: undefined,
  status: {
    isError: false,
    isLoading: false,
    error: undefined,
  },
};

export const fetchOrder = createAsyncThunk("order/fetchOrders", (id: string, thunkAPI) => {
  const orderConfig = {
    url: SERVER_URL + "/orders/" + id,
    method: "get",
    withCredentials: true,
  };

  const statusesConfig = {
    url: SERVER_URL + "/orders/statuses",
    method: "get",
    withCredentials: true,
  };

  const order = axios(orderConfig);
  const statuses = axios(statusesConfig);

  return Promise.all([order, statuses])
    .then((result) => {
      return {
        order: result[0].data,
        statuses: result[1].data.items,
      };
    })
    .catch((error: any) => {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        statusText: error.response.statusText,
        message: error.response.data.message,
      } as FetchData["error"]);
    });
});

export const updateOrder = createAsyncThunk("order/updateOrders", async ({ order, isDeliver, status, isNotice }: { order: Order, isDeliver: boolean, status: number, isNotice: boolean }, thunkAPI) => {
  const orderConfig = {
    url: SERVER_URL + "/orders/" + order.id,
    method: "put",
    withCredentials: true,
    headers: {
      "Accept-Language": order.lang,
    },
    data: {
      credential: {
        firstName: order.user.firstName,
        lastName: order.user.lastName,
        thirdName: order.user.thirdName,
        email: order.user.email,
        phoneNumber: order.user.phoneNumber,
      },
      address: isDeliver ? order.delivery : undefined,
      products: order.products.map(product => product.id),
    },
  };

  const extraConfig = {
    url: SERVER_URL + "/orders/extra/" + order.id,
    method: "put",
    withCredentials: true,
    data: {
      id: order.id,
      comment_admin: order.commentAdmin,
      track_number: order.trackNumber,
      status: order.status,
    },
  };

  const statusConfig = {
    url: SERVER_URL + "/orders/" + order.id+ "/status",
    method: "put",
    withCredentials: true,
    data: {
      status: status,
      is_notice: isNotice,
    },
  }

  await axios(extraConfig);
  await axios(orderConfig);

  const newOrderConfig = {
    url: SERVER_URL + "/orders/" + order.id,
    method: "get",
    withCredentials: true,
  };
  const newOrder = await axios(newOrderConfig);

  if (newOrder.data.status !== order.status) {
    await axios(statusConfig);
  }

  const _newOrder = await axios(newOrderConfig);

  return new Promise((res) => res(_newOrder) )
    .then((result) => {
      return (result as any).data;
    })
    .catch((error: any) => {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        statusText: error.response.statusText,
        message: error.response.data.message,
      } as FetchData["error"]);
    });
});

export const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    updateFirstName: (state, action: PayloadAction<string>) => {
      if (!state.order) { return; }
      state.order.user.firstName = action.payload;
      state.isChanged = true;
    },
    updateLastName: (state, action: PayloadAction<string>) => {
      if (!state.order) { return; }
      state.order.user.lastName = action.payload;
      state.isChanged = true;
    },
    updateThirdName: (state, action: PayloadAction<string>) => {
      if (!state.order) { return; }
      state.order.user.thirdName = action.payload;
      state.isChanged = true;
    },
    updatePhoneNumber: (state, action: PayloadAction<string>) => {
      if (!state.order) { return; }
      state.order.user.phoneNumber = action.payload;
      state.isChanged = true;
    },
    updateEmail: (state, action: PayloadAction<string>) => {
      if (!state.order) { return; }
      state.order.user.email = action.payload;
      state.isChanged = true;
    },
    updateAdminComment: (state, action: PayloadAction<string>) => {
      if (!state.order) { return; }
      state.order.commentAdmin = action.payload;
      state.isChanged = true;
    },
    updateTrackNumber: (state, action: PayloadAction<string>) => {
      if (!state.order) { return; }
      state.order.trackNumber = action.payload;
      state.isChanged = true;
    },
    updatePromoCode: (state, action: PayloadAction<string>) => {
      if (!state.order) { return; }
      state.order.promocode = action.payload;
      state.isChanged = true;
    },
    updateStatus: (state, action: PayloadAction<string>) => {
      if (!state.order) { return; }
      state.order.status = action.payload;
      state.isChanged = true;
    },
    switchIsDeliver: (state) => {
      if (!state.order) { return; }
      state.isChanged = true;
      state.isDeliver = !state.isDeliver;
      if (state.order.delivery === null) {
        state.order.delivery = {
          country: "",
          region: "",
          city: "",
          street: "",
          building: "",
          zip: "",
          floor: "",
          entrance: "",
          apartment: "",
          comment: "",
          price: ""
        }
      }
    },
    updateCountry: (state, action: PayloadAction<string>) => {
      if (!state.order || !state.order.delivery) { return; }
      state.order.delivery.country = action.payload;
      state.isChanged = true;
    },
    updateState: (state, action: PayloadAction<string>) => {
      if (!state.order || !state.order.delivery) { return; }
      state.order.delivery.region = action.payload;
      state.isChanged = true;
    },
    updateCity: (state, action: PayloadAction<string>) => {
      if (!state.order || !state.order.delivery) { return; }
      state.order.delivery.city = action.payload;
      state.isChanged = true;
    },
    updateStreet: (state, action: PayloadAction<string>) => {
      if (!state.order || !state.order.delivery) { return; }
      state.order.delivery.street = action.payload;
      state.isChanged = true;
    },
    updateBuilding: (state, action: PayloadAction<string>) => {
      if (!state.order || !state.order.delivery) { return; }
      state.order.delivery.building = action.payload;
      state.isChanged = true;
    },
    updateZip: (state, action: PayloadAction<string>) => {
      if (!state.order || !state.order.delivery) { return; }
      state.order.delivery.zip = action.payload;
      state.isChanged = true;
    },
    updateFloor: (state, action: PayloadAction<string>) => {
      if (!state.order || !state.order.delivery) { return; }
      state.order.delivery.floor = action.payload;
      state.isChanged = true;
    },
    updateEntrance: (state, action: PayloadAction<string>) => {
      if (!state.order || !state.order.delivery) { return; }
      state.order.delivery.entrance = action.payload;
      state.isChanged = true;
    },
    updateApartment: (state, action: PayloadAction<string>) => {
      if (!state.order || !state.order.delivery) { return; }
      state.order.delivery.apartment = action.payload;
      state.isChanged = true;
    },
    updateCustomerComment: (state, action: PayloadAction<string>) => {
      if (!state.order || !state.order.delivery) { return; }
      state.order.delivery.comment = action.payload;
      state.isChanged = true;
    },
  },
  extraReducers: {
    [fetchOrder.fulfilled.type]: (state: OrderState, action: PayloadAction<{order: Order, statuses: any}>) => {
      state.status.isLoading = false;
      state.status.isError = false;
      state.status.error = undefined;
      state.isChanged = false;
      state.isDeliver = action.payload.order.delivery ? true : false;
      state.statuses = action.payload.statuses;
      state.order = action.payload.order;
    },
    [fetchOrder.pending.type]: (state: OrderState) => {
      state.order = undefined;
      state.status.isLoading = true;
      state.status.isError = false;
      state.status.error = undefined;
      state.isChanged = false;
      state.isDeliver = false;
      state.statuses = undefined;
    },
    [fetchOrder.rejected.type]: (state: OrderState, action: PayloadAction<FetchData["error"]>) => {
      state.status.isLoading = false;
      state.status.isError = true;
      state.status.error = action.payload;
      state.isChanged = false;
      state.isDeliver = false;
      state.statuses = undefined;
    },
    [updateOrder.fulfilled.type]: (state: OrderState, action: PayloadAction<Order>) => {
      state.order = action.payload;
      state.status.isLoading = false;
      state.status.isError = false;
      state.status.error = undefined;
      state.isChanged = false;
    },
    [updateOrder.pending.type]: (state: OrderState) => {
      state.status.isLoading = true;
      state.status.isError = false;
      state.status.error = undefined;
      state.isChanged = false;
    },
    [updateOrder.rejected.type]: (state: OrderState, action: PayloadAction<FetchData["error"]>) => {
      state.status.isLoading = false;
      state.status.isError = true;
      state.status.error = action.payload;
      state.isChanged = false;
    },
  },
});
