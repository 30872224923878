import cn from "classnames";

import { SelectorProps, Selector } from "common";

import "./selector-with-label.scss"

type Props = SelectorProps & {
  label: string;
}; 

export function SelectorWithLabel({ label, ...rest }: Props) {
  return (
    <label className={cn("selector-with-label")}>
      <div className={cn("label")}>{label}</div>
      <Selector {...rest} />
    </label>
  );
}
