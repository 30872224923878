import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

import { FetchData, UserPrimary } from "models";
import { SERVER_URL } from "config";

type UsersState = {
  users: {
    users:  UserPrimary[],
    page:   number,
    count:  number,
  } | undefined;
  status: FetchData;
};

const initialState: UsersState = {
  users: undefined,
  status: {
    isError:    false,
    isLoading:  false,
    error:      undefined,
  },
};

export const fetchUsers = createAsyncThunk("users/fetchUsers", (_, thunkAPI) => {
  const config = {
    url:              SERVER_URL + "/users" + window.location.search,
    method:           "get",
    withCredentials:  true,
  };

  console.log(config)
  

  return axios(config)
    .then(result => {
      return result.data;
    })
    .catch((error: any) => {
      return thunkAPI.rejectWithValue({
        status:     error.response.status,
        statusText: error.response.statusText,
        message:    error.response.data.message,
      } as FetchData["error"]);
    });
});

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchUsers.fulfilled.type]: (state: UsersState, action: PayloadAction<any>) => {
      state.users             = action.payload;
      state.status.isLoading  = false;
      state.status.isError    = false;
      state.status.error      = undefined;
    },
    [fetchUsers.pending.type]: (state: UsersState) => {
      state.users             = undefined;
      state.status.isLoading  = true;
      state.status.isError    = false;
      state.status.error      = undefined;
    },
    [fetchUsers.rejected.type]: (state: UsersState, action: PayloadAction<FetchData["error"]>) => {
      state.status.isLoading  = false;
      state.status.isError    = true;
      state.status.error      = action.payload;
    },
  },
});
