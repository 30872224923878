import { HTMLProps } from "react";
import cn from "classnames";

import "./button.scss";

type Props = HTMLProps<HTMLButtonElement> & {
  type?: "button" | "submit" | "reset";
  error?: string;
  buttonWrapperClassName?: string;
}; 

export function Button({ buttonWrapperClassName, error, type, children, ...rest }: Props) {
  return (
    <div className={cn("button", buttonWrapperClassName)}>
      <button type={type} {...rest} className={cn(error && "error", rest.className)} >
        {children}
      </button>
      {error && <div className={cn("error", "error-text")}>{error}</div>}    
    </div>
  );
}
