import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios  from "axios";

import { UserPrimary, UserPermissions, FetchData, UserVerifications } from "models";
import { SERVER_URL } from "config";

type UserState = {
  user: (
    UserPrimary & 
    { permissions: UserPermissions, verifications: UserVerifications }
  ) | undefined;
  isChanged: boolean;
  authHash: string | undefined;
  status: FetchData;
};

const initialState: UserState = {
  user: undefined,
  isChanged: false,
  status: {
    isError: false,
    isLoading: false,
    error: undefined,
  },
  authHash: undefined,
};

export const fetchUser = createAsyncThunk("user/fetchUser", (id: string, thunkAPI) => {
  const userConfig = {
    url: SERVER_URL + "/users/" + id,
    method: "get",
    withCredentials: true,
  };

  return axios(userConfig)
    .then(result => {
      return result.data;
    })
    .catch((error: any) => {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        statusText: error.response.statusText,
        message: error.response.data.message,
      } as FetchData["error"]);
    });
});

export const updateUser = createAsyncThunk("user/updateUser", (user: UserPrimary & { permissions: UserPermissions, verifications: UserVerifications }, thunkAPI) => {
  const updateUserConfig = {
    url: SERVER_URL + "/users/" + user.id,
    method: "put",
    withCredentials: true,
    data: user,
  };

  return axios(updateUserConfig)
  .then(result => {
    return result.data;
  })
  .catch((error: any) => {
    return thunkAPI.rejectWithValue({
      status: error.response.status,
      statusText: error.response.statusText,
      message: error.response.data.message,
    } as FetchData["error"]);
  });
});

export const reSendConfirmationLink = createAsyncThunk("user/reSendConfirmationLink", (userId: number, thunkAPI) => {
  const reSendConfig = {
    url: SERVER_URL + "/users/" + userId + "/send-registration",
    method: "post",
    withCredentials: true,
  };

  return axios(reSendConfig)
  .then(result => {
    return result.data;
  })
  .catch((error: any) => {
    return thunkAPI.rejectWithValue({
      status: error.response.status,
      statusText: error.response.statusText,
      message: error.response.data.message,
    } as FetchData["error"]);
  });
});

export const setUser = createAsyncThunk("user/setUser", (userId: number, thunkAPI) => {
  const setUserConfig = {
    url: SERVER_URL + "/admin/set-user",
    data: { user_id: userId },
    method: "post",
    withCredentials: true,
  };

  return axios(setUserConfig)
  .then(result => {
    return result.data.password;
  })
  .catch((error: any) => {
    return thunkAPI.rejectWithValue({
      status: error.response.status,
      statusText: error.response.statusText,
      message: error.response.data.message,
    } as FetchData["error"]);
  });
});

export const sendLogin = createAsyncThunk("user/sendLogin", (userId: number, thunkAPI) => {
  const setUserConfig = {
    url: SERVER_URL + "/admin/send-user-auth",
    data: { user_id: userId },
    method: "post",
    withCredentials: true,
  };

  return axios(setUserConfig)
  .then(result => {
    return result.data.password;
  })
  .catch((error: any) => {
    return thunkAPI.rejectWithValue({
      status: error.response.status,
      statusText: error.response.statusText,
      message: error.response.data.message,
    } as FetchData["error"]);
  });
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateFirstName: (state, action: PayloadAction<string>) => {
      if (!state.user) { return; }
      state.user.firstName = action.payload;
      state.isChanged = true;
    },
    updateLastName: (state, action: PayloadAction<string>) => {
      if (!state.user) { return; }
      state.user.lastName = action.payload;
      state.isChanged = true;
    },
    updateThirdName: (state, action: PayloadAction<string>) => {
      if (!state.user) { return; }
      state.user.thirdName = action.payload;
      state.isChanged = true;
    },
    updatePhoneNumber: (state, action: PayloadAction<string>) => {
      if (!state.user) { return; }
      state.user.phoneNumber = action.payload;
      state.isChanged = true;
    },
    updateEmail: (state, action: PayloadAction<string>) => {
      if (!state.user) { return; }
      state.user.email = action.payload;
      state.isChanged = true;
    },
    updatePermissions: (state, action: PayloadAction<UserPermissions>) => {
      if (!state.user) { return; }
      state.user.permissions = action.payload;
      state.isChanged = true;
    },
    updateVerifications: (state, action: PayloadAction<UserVerifications>) => {
      if (!state.user) { return; }
      state.user.verifications = action.payload;
      state.isChanged = true;
    },
    updateCurrency: (state, action: PayloadAction<"USD" | "RUB">) => {
      if (!state.user) { return; }
      state.user.currency = action.payload;
      state.isChanged = true;
    },
    resetSetUser: (state) => {
      state.authHash = undefined;
    },
  },
  extraReducers: {
    [fetchUser.fulfilled.type]: (state: UserState, action: PayloadAction<UserPrimary & { permissions: UserPermissions, verifications: UserVerifications }>) => {
      state.status.isLoading = false;
      state.status.isError = false;
      state.status.error = undefined;
      state.isChanged = false;
      state.user = action.payload;
    },
    [fetchUser.pending.type]: (state: UserState) => {
      state.user = undefined;
      state.authHash = undefined;
      state.status.isLoading = true;
      state.status.isError = false;
      state.status.error = undefined;
      state.isChanged = false;
    },
    [fetchUser.rejected.type]: (state: UserState, action: PayloadAction<FetchData["error"]>) => {
      state.status.isLoading = false;
      state.status.isError = true;
      state.status.error = action.payload;
      state.isChanged = false;
    },
    [updateUser.fulfilled.type]: (state: UserState) => {
      state.status.isLoading = false;
      state.status.isError = false;
      state.status.error = undefined;
      state.isChanged = false;
    },
    [updateUser.pending.type]: (state: UserState) => {
      state.status.isLoading = true;
      state.status.isError = false;
      state.status.error = undefined;
    },
    [updateUser.rejected.type]: (state: UserState, action: PayloadAction<FetchData["error"]>) => {
      state.status.isLoading = false;
      state.status.isError = true;
      state.status.error = action.payload;
    },
    [reSendConfirmationLink.fulfilled.type]: (state: UserState) => {
      state.status.isLoading = false;
      state.status.isError = false;
      state.status.error = undefined;
    },
    [reSendConfirmationLink.pending.type]: (state: UserState) => {
      state.status.isLoading = true;
      state.status.isError = false;
      state.status.error = undefined;
    },
    [reSendConfirmationLink.rejected.type]: (state: UserState, action: PayloadAction<FetchData["error"]>) => {
      state.status.isLoading = false;
      state.status.isError = true;
      state.status.error = action.payload;
    },
    [setUser.fulfilled.type]: (state: UserState, action: PayloadAction<string>) => {
      state.status.isLoading = false;
      state.status.isError = false;
      state.status.error = undefined;
      state.authHash = JSON.stringify({
        email: state.user!.email,
        password: action.payload,
      });
    },
    [setUser.pending.type]: (state: UserState) => {
      state.status.isLoading = true;
      state.status.isError = false;
      state.status.error = undefined;
      state.authHash = undefined;
    },
    [setUser.rejected.type]: (state: UserState, action: PayloadAction<FetchData["error"]>) => {
      state.status.isLoading = false;
      state.status.isError = true;
      state.status.error = action.payload;
    },
    [sendLogin.fulfilled.type]: (state: UserState, action: PayloadAction<string>) => {
      state.status.isLoading = false;
      state.status.isError = false;
      state.status.error = undefined;
    },
    [sendLogin.pending.type]: (state: UserState) => {
      state.status.isLoading = true;
      state.status.isError = false;
      state.status.error = undefined;
    },
    [sendLogin.rejected.type]: (state: UserState, action: PayloadAction<FetchData["error"]>) => {
      state.status.isLoading = false;
      state.status.isError = true;
      state.status.error = action.payload;
    },
  },
});
