import cn from "classnames";
import ReactPaginate from "react-paginate";

import "./pagination.scss";

type Props = {
  totalCount:   number;
  pageSize:     number;
  currentPage:  number;
  onPageChange: (selected: number) => void
  className?:   string;
}

export function Pagination({ totalCount, pageSize, currentPage, onPageChange, className }: Props) {
  return (
    <ReactPaginate
      className={cn("pagination-component", className)}
      breakLabel="..."
      nextLabel="next"
      previousLabel="prev"
      forcePage={currentPage-1}
      onPageChange={({ selected }: { selected: number }) => { onPageChange(selected + 1) }}
      pageCount={Math.ceil(totalCount / pageSize)}
    />
  );
}
