
import { useEffect } from "react";
import { NavLink } from "react-router-dom";

import { Loader } from "common";
import { useGetMediaList, useDropMediaByAliasAndLang } from "./use-media";

export function MediaListPage() {
  const getMediaList = useGetMediaList();
  const dropMediaByAlias = useDropMediaByAliasAndLang();

  useEffect(() => {
    getMediaList.fetchMedia();
  }, []);

  useEffect(() => {
    if (!dropMediaByAlias.status.isCompleted) return;
    getMediaList.fetchMedia();
  }, [dropMediaByAlias.status.isCompleted]);

  const deleteHandler = (alias: string, lang: "ru-RU" | "en-EN") => {
    if (!window.confirm("Are you sure?")) return;
    dropMediaByAlias.drop(alias, lang);
  };

  if (getMediaList.status.isLoading || dropMediaByAlias.status.isLoading) {
    return <Loader className="loader" />;
  }

  if (dropMediaByAlias.status.error) {
    return (
      <div>
        <h1>{dropMediaByAlias.status.error}</h1>
        <div onClick={() => window.location.reload()} style={{ cursor: "pointer"}}>Refresh</div>
      </div>
    );
  }

  if (getMediaList.status.error || getMediaList.mediaList === undefined) {
    return (
      <div>
        <h1>{getMediaList.status.error ? getMediaList.status.error : "Something wrong..."}</h1>
        <div onClick={() => window.location.reload()} style={{ cursor: "pointer"}}>Refresh</div>
      </div>
    );
  }

  return (
    <div>
      <h1>Media</h1>
      <NavLink to="/media/create" className="link">New article</NavLink>
      <br />
      <br />
      <table className="table-list">
        <thead>
          <tr>
            <th>Alias</th>
            <th>Title</th>
            <th>Lang</th>
            <th>Is published</th>
            <th></th>
          </tr>
        </thead>
        {getMediaList.mediaList.map(media => (
          <tbody key={media.alias}>
            <tr className="table-item">
              <td style={{width: "35%"}}><NavLink to={"/media/" + media.alias + "/" + media.lang} className="link">{media.alias}</NavLink></td>
              <td style={{width: "35%"}}><NavLink to={"/media/" + media.alias + "/" + media.lang} className="link">{media.title}</NavLink></td>
              <td><NavLink to={"/media/" + media.alias + "/" + media.lang} className="link">{media.lang}</NavLink></td>
              <td><NavLink to={"/media/" + media.alias + "/" + media.lang} className="link">{media.isPublished ? "Published" : "Draft"}</NavLink></td>
              <td><b onClick={() => deleteHandler(media.alias, media.lang)} style={{ cursor: "pointer" }}>Delete</b></td>
            </tr>
          </tbody>
        ))}
      </table>
    </div>
  );
}
