import axios from "axios";
import { Button, InputWithLabel, Selector } from "common/components";
import { SERVER_URL } from "config";
import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

export const CatalogInfoCreate = () => {
  const [alias, setAlias] = useState("");
  const [selectLang, setSelectLang] = useState<"ru-RU" | "en-EN">("ru-RU");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [imgURL, setImgURL] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState<{
    message: string,
    fieldName: string,
  }[]>([]);

  const handleCreate = async () => {
    if (alias.length === 0
      || title.length === 0
      || description.length === 0
      || imgURL.length === 0
    ) {
      return 0;
    }

    const statusesConfig = {
      url: SERVER_URL + "/admin/catalog-info",
      method: "post",
      withCredentials: true,
      data: {
        lang: selectLang,
        alias,
        title,
        description,
        image: imgURL,
      },
    };

    try {
      setIsLoading(true)
      await setErrors([]);
      await axios(statusesConfig);
      navigate("/catalog-info");
    } catch (err: any) {
      const error = JSON.parse(err?.request?.response ?? "{}")
      if (error['validation']) {
        const validationFields = error['validation'];
        validationFields.forEach((field: any) => {
          const fieldName: string = field.field_name;
          const message: string = field.message;
          setErrors(prevErrs => [...prevErrs, { message, fieldName }]);
        });
      }
    }
    setIsLoading(false);
  };

  return (
    <div>
      <div><b><NavLink to={"/catalog-info"} className="link">back to catalog info list</NavLink></b></div>
      <br />
      <InputWithLabel
        type="string"
        label="Alias *"
        value={alias}
        onChange={e => setAlias(e.currentTarget.value)}
      />
      <br />
      <Selector
        currentValue={selectLang}
        values={["ru-RU", "en-EN"]}
        onChange={e => { setSelectLang(e.currentTarget.value as any) }}
      />
      <br />
      <div>
        <InputWithLabel
          type="string"
          label="Title *"
          value={title}
          onChange={e => setTitle(e.currentTarget.value)}
        />
      </div>
      <br />
      <div>
        <InputWithLabel
          type="string"
          label="Description *"
          value={description}
          onChange={e => setDescription(e.currentTarget.value)}
        />
      </div>
      <br />
      <div>
        <InputWithLabel
          type="string"
          label="Image URL *"
          value={imgURL}
          onChange={e => setImgURL(e.currentTarget.value)}
        />
      </div>
      <div>
      <br />
      <Button
        type="button"
        disabled={isLoading}
        onClick={handleCreate}>Create</Button>
      </div>
      <ul style={{ color: "red" }}>
        {errors.map((error, pos)=> <li key={error.fieldName + pos}>{error.fieldName} — {error.message}</li>)}
      </ul>
    </div>
  );
}