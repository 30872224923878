import { useEffect } from "react";

import { Loader, SomethingWrong } from "common/components";
import { useAppDispatch, useAppSelector, fetchStatistics } from "store";

import styles from "./statistics.module.scss";

type MiniStatisticsProps = {
  totalCount: number;
  devices: {
    count: number;
    device: string;
    percent: 0;
  }[];
};
function MiniStatistics(props: MiniStatisticsProps) {
  return (
    <>
      <div className={styles.allCount}>All count: <b>{props.totalCount}</b></div>
      {props.devices.map((device, key) => {
        if (device.device !== "tablet" && device.device !== "mobile" && device.device !== "desktop") {
          return <></>
        }
        return (
          <div key={key} className={styles.device}>
            <div>Device:</div> <div><b>{device.device}</b></div>
            <div>Count:</div> <div><b>{device.count}</b></div>
            <div>Percent:</div> <div><b>{device.percent}</b></div>
          </div>
        );
      })}
    </>
  );
}

export function Statistics() {
  const dispatch = useAppDispatch();
  
  const { statistics, status: { isLoading, error } } = useAppSelector(state => state.statistics);

  useEffect(() => {
    dispatch(fetchStatistics());
  }, []);

  if (isLoading) {
    return (
      <div className="orders">
        <Loader className="loader" />
      </div>
    );
  }

  if (error || !statistics) {
    return (
      <div className="orders noticed">
        <SomethingWrong />
      </div>
    );
  }
  
  return (
    <div className="statistics">
      <table className="table-default">
        <thead>
          <tr>
            <th>Target</th>
            <th>Month</th>
            <th>Week</th>
            <th>Previous week</th>
          </tr>
        </thead>
        <tbody>
          {statistics.map((item, key) =>
            <tr className="order-item" key={key}>
              <td className={styles.tdImage}>{item.target.match(/\.(jpg|jpeg|png|gif|webp)$/i) ? <img className={styles.image} src={item.target} alt={item.target} /> : <></>}</td>
              <td className={styles.devices}>
                <MiniStatistics totalCount={item.month.totalCount} devices={item.month.devices} />
              </td>
              <td>
                <MiniStatistics totalCount={item.week.totalCount} devices={item.week.devices} />
              </td>
              <td>
                <MiniStatistics totalCount={item.previousWeek.totalCount} devices={item.previousWeek.devices} />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}