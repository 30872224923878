import { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import queryString from "query-string";

import { fetchUsers, useAppDispatch, useAppSelector } from "store";
import { Loader, SomethingWrong, Pagination } from "common";
import { Search } from "./search";

import "./users.scss";

export function UsersPage() {
  const dispatch = useAppDispatch();
  const { users, status: { isLoading, error } } = useAppSelector(state => state.users);

  const navigate = useNavigate();

  const page = queryString.parse(window.location.search).page;
  useEffect(() => {
    dispatch(fetchUsers());
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    return (
      <div className="users">
        <Loader className="loader" />
      </div>
    );
  }

  if (error || !users) {
    return (
      <div className="users noticed">
        <SomethingWrong />
      </div>
    );
  }

  return (
    <div className="users">
      <Search />
      <table className="users-list">
        <thead>
          <tr>
            <th>ID</th>
            <th>Full name</th>
            <th>Email</th>
            <th>Phone</th>
          </tr>
        </thead>
        <tbody>
          {users.users.map(user => (
            <tr className="users-item" key={user.id}>
              <td><NavLink to={"/user/" + user.id}>{user.id}</NavLink></td>
              <td><NavLink to={"/user/" + user.id}>{user.firstName} {user.lastName} {user.thirdName}</NavLink></td>
              <td><NavLink to={"/user/" + user.id}>{user.email}</NavLink></td>
              <td><NavLink to={"/user/" + user.id}>{user.phoneNumber}</NavLink></td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        totalCount={users.count}
        currentPage={page ? +page : 1}
        pageSize={36}
        onPageChange={(selected: number) => {
          const tempQuery = queryString.parse(window.location.search);
          tempQuery.page = "" + selected;
          const tempQueryAsString = queryString.stringify(tempQuery);
          console.log(window.location.origin)
          navigate("?" + tempQueryAsString);
        }}
        className="pagination"
      />
    </div>
  );
}
