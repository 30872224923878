import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios  from "axios";

import { FetchData } from "models";
import { SERVER_URL } from "config";

type PromoCodeState = {
  promo: {
    id: number;
    alias: string;
    expiredAt: string;
    filters: {
      [key: string]: string;
    }[];
    antiFilters: {
      [key: string]: string;
    }[];
    forUsersIds: number[];
    forAuth: boolean;
    isPercents: boolean;
    isPublished: boolean;
    isSumWithDiscount: boolean;
    languageCode: "ru-RU" | "en-EN";
    collectionsIds: number[];
    currentUsesCount: string;
    description: string;
    maxAuthUsers: string;
    maxUsesForUser: string;
    maxUses: string;
    name: string;
    totalFrom: string;
    totalTo: string;
    value: string;
  }[] | undefined;
  status: FetchData;
};

const initialState: PromoCodeState = {
  promo: undefined,
  status: {
    isError: false,
    isLoading: false,
    error: undefined,
  },
};

export const fetchPromo = createAsyncThunk("promo-code/fetchPromo", (_, thunkAPI) => {
  const config = {
    url: SERVER_URL + "/admin/promo" + window.location.search,
    method: "get",
    withCredentials: true,
  };

  return axios(config)
    .then(result => {
      return result.data;
    })
    .catch((error: any) => {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        statusText: error.response.statusText,
        message: error.response.data.message,
      } as FetchData["error"]);
    });
});

export const promoCodeSlice = createSlice({
  name: "promoCode",
  initialState,
  reducers: {
  },
  extraReducers: {
    [fetchPromo.fulfilled.type]: (state: PromoCodeState, action: PayloadAction<any>) => {
      state.promo = action.payload;
      state.status.isLoading = false;
      state.status.isError = false;
      state.status.error = undefined;
    },
    [fetchPromo.pending.type]: (state: PromoCodeState) => {
      state.promo = undefined;
      state.status.isLoading = true;
      state.status.isError = false;
      state.status.error = undefined;
    },
    [fetchPromo.rejected.type]: (state: PromoCodeState, action: PayloadAction<FetchData["error"]>) => {
      state.status.isLoading = false;
      state.status.isError = true;
      state.status.error = action.payload;
    },
  }
});
