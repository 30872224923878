
import { useNavigate } from "react-router-dom";
import { GroupsRequiredFields, createGroups, useAppDispatch } from "store";
import { GroupsForm } from "./form";

export function GroupsCreatePage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const createHandler = (fields: GroupsRequiredFields) => {
    dispatch(createGroups(fields))
      .then(result => {
        if ((result as any).error) return;
        navigate("/groups");
      });
  };

  return (
    <GroupsForm compleatedCallback={createHandler} />
  );
}
