import { combineReducers, configureStore } from "@reduxjs/toolkit";

import {
  authSlice,
  ordersSlice,
  orderSlice,
  usersSlice,
  userSlice,
  promoCodeSlice,
  promoCodeCreateOrEditSlice,
  homepageSlice,
  uploadsSlice,
  groupsSlice,
  dictionarySlice,
  menuSlice,
  brandsSlice,
  brandSlice,
  statisticsSlice,
} from "./slices";

const rootReducer = combineReducers({
  auth:   authSlice.reducer,
  orders: ordersSlice.reducer,
  order:  orderSlice.reducer,
  users:   usersSlice.reducer,
  user: userSlice.reducer,
  promoCode: promoCodeSlice.reducer,
  promoCodeCreateOrEdit: promoCodeCreateOrEditSlice.reducer,
  homepage: homepageSlice.reducer,
  uploads: uploadsSlice.reducer,
  groups: groupsSlice.reducer,
  dictionary: dictionarySlice.reducer,
  menu: menuSlice.reducer,
  brands: brandsSlice.reducer,
  brand: brandSlice.reducer,
  statistics: statisticsSlice.reducer,
});

export function setupStore() {
  return configureStore({ reducer: rootReducer });
}

export type RootState   = ReturnType<typeof rootReducer>;
export type AppStore    = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
