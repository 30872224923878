import { InputWithLabel } from "common";
import { UserPrimary } from "models";

type Props = UserPrimary & {
  isDisabled: boolean,
  updateFirstNameHandler: (newValue: string) => void,
  updateLastNameHandler: (newValue: string) => void,
  updateThirdNameHandler: (newValue: string) => void,
  updatePhoneNumberHandler: (newValue: string) => void,
  updateEmailHandler: (newValue: string) => void,
};

export function User(props: Props) {
  return (
    <>
      <InputWithLabel
        label="First Name *"
        disabled={props.isDisabled}
        value={props.firstName}
        onChange={e => props.updateFirstNameHandler(e.currentTarget.value)}
      />
      <InputWithLabel
        label="Last Name *"
        disabled={props.isDisabled}
        value={props.lastName}
        onChange={e => props.updateLastNameHandler(e.currentTarget.value)}
      />
      <InputWithLabel
        label="Third Name"
        disabled={props.isDisabled}
        value={props.thirdName}
        onChange={e => props.updateThirdNameHandler(e.currentTarget.value)}
      />
      <InputWithLabel
        label="Phone Number *"
        disabled={props.isDisabled}
        value={props.phoneNumber}
        onChange={e => props.updatePhoneNumberHandler(e.currentTarget.value)}
      />
      <InputWithLabel
        label="Email *"
        disabled={props.isDisabled}
        value={props.email}
        onChange={e => props.updateEmailHandler(e.currentTarget.value)}
      />
    </>
  );
}
