import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios  from "axios";

import { FetchData, FetchError } from "models";
import { fetchError } from "helpers";
import { SERVER_URL } from "config";

export type GroupsRequiredFields = {
  name: string;
  description: string;
  alias: string;
  defaultAlias: string | undefined;
  isPublished: boolean;
  filters: [{ "include_ids": string }]
  languageCode: "ru-RU" | "en-EN";
}

type Groups = GroupsRequiredFields[];

type GroupsState = {
  groups: Groups | undefined;
  status: FetchData;
};

const initialState: GroupsState = {
  groups: undefined,
  status: {
    isError: false,
    isLoading: false,
    error: undefined,
  },
};

export const fetchGroups = createAsyncThunk("groups/fetchGroups", (_, thunkAPI) => {
  const config = {
    url: SERVER_URL + "/admin/groups" + window.location.search,
    method: "get",
    withCredentials: true,
  };

  return axios(config)
    .then(result => { return result.data })
    .catch((error: FetchError) => thunkAPI.rejectWithValue(fetchError(error)))
});

export const createGroups = createAsyncThunk("groups/createGroups", (fields: GroupsRequiredFields, thunkAPI) => {
  const _fields = {
    language_code: fields.languageCode,
    is_published: fields.isPublished,
    name: fields.name,
    description: fields.description,
    alias: fields.alias,
    filters: fields.filters,
  };
  const config = {
    url: SERVER_URL + "/admin/groups",
    method: "post",
    data: _fields,
    withCredentials: true,
  };

  return axios(config)
    .then(result => { return result.data })
    .catch((error: FetchError) => thunkAPI.rejectWithValue(fetchError(error)));
});

export const updateGroups = createAsyncThunk("groups/updateGroups", (fields: GroupsRequiredFields, thunkAPI) => {
  const _fields = {
    language_code: fields.languageCode,
    is_published: fields.isPublished,
    name: fields.name,
    description: fields.description,
    alias: fields.alias,
    filters: fields.filters,
  };
  const config = {
    url: SERVER_URL + "/admin/groups/" + fields.defaultAlias,
    method: "put",
    data: _fields,
    withCredentials: true,
  };

  return axios(config)
    .then(result => { return result.data })
    .catch((error: FetchError) => thunkAPI.rejectWithValue(fetchError(error)));
});

export const deleteGroups = createAsyncThunk("groups/deleteGroups", (alias: string, thunkAPI) => {
  const config = {
    url: SERVER_URL + "/admin/groups/" + alias,
    method: "delete",
    withCredentials: true,
  };

  return axios(config)
    .then(result => { return result.data })
    .catch((error: FetchError) => thunkAPI.rejectWithValue(fetchError(error)));
});

const fulfilled = (state: GroupsState) => {
  state.status.isLoading = false;
  state.status.isError = true;
  state.status.error = undefined;
};

const pending = (state: GroupsState) => {
  state.status.isLoading = true;
  state.status.isError = false;
  state.status.error = undefined;
};

const rejected = (state: GroupsState, action: PayloadAction<FetchData["error"]>) => {
  state.status.isLoading = false;
  state.status.isError = true;
  state.status.error = action.payload;
};

export const groupsSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchGroups.fulfilled.type]: (state: GroupsState, action: PayloadAction<Groups>) => {
      state.groups = action.payload;
      state.status.isLoading = false;
      state.status.isError = false;
      state.status.error = undefined;
    },
    [fetchGroups.pending.type]: pending,
    [fetchGroups.rejected.type]: rejected,

    [createGroups.fulfilled.type]: fulfilled,
    [createGroups.pending.type]: pending,
    [createGroups.rejected.type]: rejected,

    [updateGroups.fulfilled.type]: fulfilled,
    [updateGroups.pending.type]: pending,
    [updateGroups.rejected.type]: rejected,

    [deleteGroups.fulfilled.type]: fulfilled,
    [deleteGroups.pending.type]: pending,
    [deleteGroups.rejected.type]: rejected,
  }
});