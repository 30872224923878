import { HTMLProps } from "react";
import "./selector.scss"

export type SelectorProps = HTMLProps<HTMLSelectElement> & {
  values: string[];
  currentValue: string;
};

export function Selector({ values, currentValue, ...rest }: SelectorProps) {
  return (
    <select className="selector" value={currentValue} data-value={currentValue} {...rest}>
      {values.map(item => <option value={item} key={item}>{item}</option>)}
    </select>
  );
}
