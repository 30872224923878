import { NavLink } from "react-router-dom";
import { useEffect } from "react";

import { Button, SelectorWithLabel } from "common";
import { useAppDispatch, useAppSelector, userSlice, reSendConfirmationLink, setUser, sendLogin } from "store";
import { CLIENT_URL } from "config";

export function AdditionalUserInfo() {
  const dispatch  = useAppDispatch();

  const user = useAppSelector(state => state.user).user!;
  const isChanged = useAppSelector(state => state.user).isChanged;
  const authHash = useAppSelector(state => state.user).authHash;

  const {
    updatePermissions,
    updateVerifications,
    resetSetUser,
  } = userSlice.actions;

  const updatePermissionsHandler    = (field: "isAccount" | "isAdmin" | "isSeller", newValue: boolean) => {
    const permissions = { ...user.permissions };
    permissions[field] = newValue;
    dispatch(updatePermissions(permissions));
  };

  const updateVerificationsHandler    = (field: "isEmail" | "isPhone" | "isReserve", newValue: boolean) => {
    const verifications = { ...user.verifications };
    verifications[field] = newValue;
    dispatch(updateVerifications(verifications));
  };

  const setUserHandler = () => {
    dispatch(setUser(user.id));
  };

  const sendLoginHandler = () => {
    dispatch(sendLogin(user.id));
  };

  useEffect(() => {
    if (!authHash) return undefined;
    window.open(`${CLIENT_URL}/another-login?code=${authHash}`);
    dispatch(resetSetUser());
  }, [authHash]);
  
  return (
    <div className="additional-user-info">
      <h2 className="additional-user-info__title">Permissions</h2>
      <SelectorWithLabel
        currentValue={user.permissions.isAccount === true ? "Yes" : "No"}
        label="Account access"
        values={["Yes", "No"]}
        onChange={e => { updatePermissionsHandler("isAccount", e.currentTarget.value === "Yes") }}
      />
      <SelectorWithLabel
        currentValue={user.permissions.isAdmin === true ? "Yes" : "No"}
        label="Admin"
        values={["Yes", "No"]}
        onChange={e => { updatePermissionsHandler("isAdmin", e.currentTarget.value === "Yes") }}
      />
      <SelectorWithLabel
        currentValue={user.permissions.isSeller === true ? "Yes" : "No"}
        label="Seller"
        values={["Yes", "No"]}
        onChange={e => { updatePermissionsHandler("isSeller", e.currentTarget.value === "Yes") }}
      />
      <h2 className="additional-user-info__title">Verifications</h2>
      <SelectorWithLabel
        currentValue={user.verifications.isEmail === true ? "Сonfirmed" : "Unconfirmed"}
        label="Email"
        values={["Сonfirmed", "Unconfirmed"]}
        onChange={e => { updateVerificationsHandler("isEmail", e.currentTarget.value === "Сonfirmed") }}
      />
      <SelectorWithLabel
        currentValue={user.verifications.isPhone === true ? "Сonfirmed" : "Unconfirmed"}
        label="Phone"
        values={["Сonfirmed", "Unconfirmed"]}
        onChange={e => { updateVerificationsHandler("isPhone", e.currentTarget.value === "Сonfirmed") }}
      />
      <h2 className="additional-user-info__title">Additional</h2>
      <Button onClick={() => { dispatch(reSendConfirmationLink(user.id))} }>Send confirmation email link</Button>
      <NavLink to={"/orders?user_id=" + user.id} className="link-btn" target="_blank">User orders</NavLink>
      <Button disabled={
        user.verifications.isEmail === false ||
        user.permissions.isAccount === false ||
        isChanged
      } onClick={setUserHandler}>Login with this user</Button>
      <Button disabled={
        user.verifications.isEmail === false ||
        user.permissions.isAccount === false ||
        isChanged
      } onClick={sendLoginHandler}>Send login link</Button>
    </div>
  );
}
