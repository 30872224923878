import { useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";
import ru from "date-fns/locale/ru";

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

export function DateRange(props: {
  dateRange: any
  setDateRange: any
}) {
  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  }

  const handleSelect = (ranges: any) => {
    props.setDateRange(ranges.selection)
  };

  return (
    <DateRangePicker
      className="date-range"
      ranges={[{
        startDate: props.dateRange.startDate,
        endDate: props.dateRange.endDate,
        key: 'selection',
      }]}
      onChange={handleSelect}
      locale={ru}
    />
  )
}