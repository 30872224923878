import { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { InputWithLabel, Button } from "common";
import { LoginPageErrors } from "models"
import { isEmail, isEmpty } from "helpers";
import { fetchAuth, useAppDispatch, useAppSelector } from "store";

import "./login.scss";

export function LoginPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  
  const [emailValue, setEmailValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [errors, setErrors] = useState<LoginPageErrors>({});

  const { account, status: { isLoading, error } } = useAppSelector(state => state.auth);

  useEffect(() => {
    if (!account) return;
    navigate("/");
  }, [account]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateUsernameValue = (event: FormEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setEmailValue(value);
  };

  const updatePasswordValue = (event: FormEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setPasswordValue(value);
  };

  const checkEmail = (): string | null => {
    if (isEmpty(emailValue)) {
      return "Field cannot be empty";
    }

    if (!isEmail(emailValue)) {
      return "Invalid email";
    }

    return null;
  };

  const checkPassword = (): string | null => {
    if (isEmpty(passwordValue)) {
      return "Field cannot be empty";
    }

    return null;
  };

  const checkFields = () => {
    const loginPageErrors: LoginPageErrors = {};
    const emailError = checkEmail();
    const passwordError = checkPassword();

    if (emailError) {
      loginPageErrors.email = emailError;
    }

    if (passwordError) {
      loginPageErrors.password = passwordError;
    }

    setErrors(loginPageErrors);

    return loginPageErrors;
  };

  const loginFormHandler = (event: FormEvent) => {
    event.preventDefault();

    if (Object.keys(checkFields()).length !== 0) {
      return;
    }

    dispatch(fetchAuth({
      email: emailValue,
      password: passwordValue,
    }));
  }

  return (
    <div className="login">
      <form className="form" onSubmit={loginFormHandler}>
        <h2 className="form-title">Login</h2>
        <InputWithLabel
          label="Email"
          inputWithLabelClassName="form-item"
          type="text"
          value={emailValue}
          onChange={updateUsernameValue}
          error={errors.email}
          disabled={isLoading}
        />
        <InputWithLabel
          label="Password"
          inputWithLabelClassName="form-item"
          type="password"
          value={passwordValue}
          onChange={updatePasswordValue}
          error={errors.password}
          disabled={isLoading}
        />
        <Button
          type="submit"
          buttonWrapperClassName="form-submit"
          disabled={isLoading}
          error={error?.message}
        >Login</Button>
      </form>
    </div>
  );
}
