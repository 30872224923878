import { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { format } from "date-format-parse";

import { Button, Input, InputWithLabel, Loader, Selector, SelectorWithLabel, SomethingWrong } from "common";
import { fetchDefaultFilters, useAppDispatch, useAppSelector, promoCodeCreateOrEditSlice, createPromoCode, fetchPromo, deletePromoCode, updatePromoCode } from "store";

import "../promo-code.scss";
import { nanoid } from "@reduxjs/toolkit";

export function PromoCodeCreateOrEditPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { promo, status: promoStatus } = useAppSelector(state => state.promoCode);
  const defaultFilters = useAppSelector(state => state.promoCodeCreateOrEdit).defaultFilters;
  const { fullFilters, status } = useAppSelector(state => state.promoCodeCreateOrEdit).fields;
  const isRedirect = useAppSelector(state => state.promoCodeCreateOrEdit).isRedirect;
  const {
    alias,
    name,
    description,
    totalFrom,
    totalTo,
    languageCode,
    value,
    isPercents,
    isSumWithDiscount,
    expiredAt,
    maxAuthUsers,
    maxUsesForUser,
    maxUses,
    forAuth,
    isPublished,
    forUsersIDs,
    includeProductByID,
    excludeProductByID,
    collectionByID,
  } = useAppSelector(state => state.promoCodeCreateOrEdit).fields;

  const {
    addFullFilter,
    changeFiltersType,
    deleteFiltersItem,
    updateCheckboxFilters,
    updatePricesFilters,
    updateAlias,
    updateDescription,
    updateExpiredAt,
    updateForAuth,
    updateIsPercents,
    updateIsPublished,
    updateIsSumWithDiscount,
    updateLanguageCode,
    updateMaxAuthUsers,
    updateMaxUses,
    updateMaxUsesForUser,
    updateName,
    updateTotalFrom,
    updateTotalTo,
    updateValue,
    addForUsersIDs,
    deleteForUsersIDs,
    addIncludeProductByID,
    deleteIncludeProductByID,
    addExcludeProductByID,
    deleteExcludeProductByID,
    addCollectionByID,
    deleteCollectionID,
    setFields,
    resetFields,
  } = promoCodeCreateOrEditSlice.actions;

  useEffect(() => {
    if (isRedirect) {
      navigate("/promo-code");
    }
  }, [isRedirect])

  useEffect(() => {
    dispatch(fetchDefaultFilters());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { id } = useParams();

  useEffect(() => {
    if (!id) return;
    dispatch(fetchPromo());
  }, [id]);

  useEffect(() => {
    if (!promo || !id || !defaultFilters.filters.length) return;

    const promoPosition = promo.findIndex(item => "" + item.id === id);
    const currentPromo = promo[promoPosition];

    const _includeProductByID: any = [];
    const _excludeProductByID: any = [];
    const _fullFilters: any = [];

    if (currentPromo.filters) {
      for (let i = 0; i < currentPromo.filters.length; i++) {
        const _filters = currentPromo.filters[i];
        if (Object.keys(_filters).length === 0) continue;
        if (_filters["include_ids"]) {
          const items = _filters["include_ids"].split(",");
          items.forEach(item => {
            _includeProductByID.push(item);
          })
          continue;
        }

        const filtersKeys = Object.keys(_filters);
  
        const parsedFilters = defaultFilters.filters.map((filter) => ({
          name: filter.name,
          items: filter.items.map((item, pos) => ({
            title: item,
            isChecked: (() => {
              const namePosition = filtersKeys.indexOf(filter.name);
              if (namePosition === -1) { return false }
              const selectedItems = _filters[filtersKeys[namePosition]].split(",");
              return selectedItems.indexOf(item) !== -1;
            })(),
            forPrices: (() => {
              if (filter.name !== "prices" || filtersKeys.indexOf("prices") === -1) return "";
              const selectedPrices = _filters["prices"].split(",");
              if (selectedPrices.length === 2) {
                return selectedPrices[pos] === "0" ? "" : selectedPrices[pos];
              } else {
                if (pos === 0) return selectedPrices[pos] === "0" ? "" : selectedPrices[pos];
                return "";
              }
            })()
          })),
        }));

        const pricesIndex = parsedFilters.findIndex(item => item.name === "prices");
        parsedFilters[pricesIndex].items[0] = {
          ...parsedFilters[pricesIndex].items[0],
          forPrices: _filters["min"] || "",
        }
        parsedFilters[pricesIndex].items[1] = {
          ...parsedFilters[pricesIndex].items[1],
          forPrices: _filters["max"] || "",
        }

        _fullFilters.push({
          id: nanoid(),
          filters: parsedFilters,
          type: "filters",
        });
      }
    }

    if (currentPromo.antiFilters) {
      for (let i = 0; i < currentPromo.antiFilters.length; i++) {
        const _antiFilters = currentPromo.antiFilters[i];
        if (Object.keys(_antiFilters).length === 0) continue;
        if (_antiFilters["include_ids"]) {
          const items = _antiFilters["include_ids"].split(",");
          items.forEach(item => {
            _excludeProductByID.push(item);
          })
          continue;
        }

        const filtersKeys = Object.keys(_antiFilters);
        
        const parsedFilters = defaultFilters.filters.map((filter) => ({
          name: filter.name,
          items: filter.items.map((item, pos) => ({
            title: item,
            isChecked: (() => {
              const namePosition = filtersKeys.indexOf(filter.name);
              if (namePosition === -1) { return false }
              const selectedItems = _antiFilters[filtersKeys[namePosition]].split(",");
              return selectedItems.indexOf(item) !== -1;
            })(),
            forPrices: ""
          })),
        }));
  
        _fullFilters.push({
          id: nanoid(),
          filters: parsedFilters,
          type: "anti-filters",
        });
      }
    }
 
    dispatch(setFields({
      id: currentPromo.id,
      alias: currentPromo.alias,
      expiredAt: currentPromo.expiredAt ? format(new Date(currentPromo.expiredAt), "YYYY-MM-DD") : "",
      fullFilters: _fullFilters,
      includeProductByID: _includeProductByID,
      excludeProductByID: _excludeProductByID,
      collectionByID: currentPromo.collectionsIds ? currentPromo.collectionsIds : [],
      name: currentPromo.name,
      description: currentPromo.description,
      forUsersIDs: currentPromo.forUsersIds ? currentPromo.forUsersIds : [],
      forAuth: currentPromo.forAuth,
      isPercents: currentPromo.isPercents,
      isPublished: currentPromo.isPublished,
      totalFrom: currentPromo.totalFrom ? "" + currentPromo.totalFrom : "",
      totalTo: currentPromo.totalTo ? "" + currentPromo.totalTo : "",
      value: currentPromo.value,
      isSumWithDiscount: currentPromo.isSumWithDiscount,
      languageCode: currentPromo.languageCode,
      maxUses: currentPromo.maxUses ? currentPromo.maxUses : "",
      maxAuthUsers: currentPromo.maxAuthUsers ? currentPromo.maxAuthUsers : "",
      maxUsesForUser: currentPromo.maxUsesForUser ? currentPromo.maxUsesForUser : "",
    }));
  }, [promo, defaultFilters]);

  useEffect(() => {
    return () => {
      dispatch(resetFields());
    }
  }, []);

  const [forUserIDsValue, setForUserIDsValue] = useState("");
  const [includeProductByIDValue, setIncludeProductByIDValue] = useState("");
  const [excludeProductByIDValue, setExcludeProductByIDValue] = useState("");
  const [collectionByIDValue, setCollectionByIDValue] = useState("");

  const addFullFilterHandler = () => {
    dispatch(addFullFilter());
  };

  const changeFiltersTypeHandler = ({ id, type }: { id: string, type: "filters" | "anti-filters" }) => {
    dispatch(changeFiltersType({ id, type }));
  };

  const deleteFiltersItemHandler = (id: string) => {
    dispatch(deleteFiltersItem(id));
  };

  const updateCheckboxFiltersHandler = ({ id, name, title }: { id: string, name: string, title: string }) => {
    dispatch(updateCheckboxFilters({ id, name, title }));
  };

  const updatePricesFiltersHandler = ({ id, name, title, price }: { id: string, name: string, title: string, price: string }) => {
    dispatch(updatePricesFilters({ id, name, title, price }));
  };

  const updateAliasHandler = (alias: string) => { dispatch(updateAlias(alias)) };
  const updateNameHandler = (name: string) => { dispatch(updateName(name)) };
  const updateDescriptionHandler = (description: string) => { dispatch(updateDescription(description)) };

  const updateTotalFromHandler = (totalFrom: string) => { if (!isNaN(+totalFrom)) dispatch(updateTotalFrom(totalFrom)) };
  const updateTotalToHandler = (totalTo: string) => { if (!isNaN(+totalTo)) dispatch(updateTotalTo(totalTo)) };
  const updateLanguageCodeHandler = (languageCode: "en-EN" | "ru-RU") => { dispatch(updateLanguageCode(languageCode)) };
  const updateValueHandler = (value: string) => { if (!isNaN(+value)) dispatch(updateValue(value)) };
  const updateIsPercentsHandler = () => { dispatch(updateIsPercents()) };
  const updateIsSumWithDiscountHandler = () => { dispatch(updateIsSumWithDiscount()) };

  const updateExpiredAtHandler = (expiredAt: string) => { dispatch(updateExpiredAt(expiredAt)) };
  const updateMaxAuthUsersHandler = (maxAuthUsers: string) => { if (!isNaN(+maxAuthUsers)) dispatch(updateMaxAuthUsers(maxAuthUsers)) };
  const updateMaxUsesForUserHandler = (maxUsesForUser: string) => { if (!isNaN(+maxUsesForUser)) dispatch(updateMaxUsesForUser(maxUsesForUser)) };
  const updateMaxUsesHandler = (maxUses: string) => { if (!isNaN(+maxUses)) dispatch(updateMaxUses(maxUses)) };

  const updateForAuthHandler = () => { dispatch(updateForAuth()) };
  const updateIsPublishedHandler = () => { dispatch(updateIsPublished()) };

  const createOrUpdatePromoHandler = () => {
    if (alias.length === 0 ||
        name.length === 0 ||
        totalFrom.length === 0 ||
        value.length === 0) {
      alert("Fill in required fields *");
      return;
    }

    let _filters: any[] = [];
    let _antiFilters: any[] = [];

    fullFilters.forEach(filter => {
      const _innerFilters: any = {};
      const _innerAntyFilters: any = {};

      for (let i = 0; i < filter.filters.length; i++) {
        let checkedItems = "";
        let prices: any = {};
  
        for (let j = 0; j < filter.filters[i].items.length; j++) {
          if (filter.filters[i].items[j].isChecked === true) {
            checkedItems += "," + filter.filters[i].items[j].title;
          }

          if (filter.filters[i].items[j].forPrices.length !== 0) {
            if (filter.filters[i].items[0] === filter.filters[i].items[j]) {
              prices["min"] = filter.filters[i].items[j].forPrices;
            } else {
              prices["max"] = filter.filters[i].items[j].forPrices;
            }
          }
        }
  
        checkedItems = checkedItems.replace(",", "");
  
        if (checkedItems.length !== 0) {
          if (filter.type === "filters") {
            _innerFilters[filter.filters[i].name] = checkedItems;
          } else if (filter.type === "anti-filters") {
            _innerAntyFilters[filter.filters[i].name] = checkedItems;
          }
        }

        if (prices["min"]) {
          if (filter.type === "filters") {
            _innerFilters["min"] = "" + prices["min"];
          } else if (filter.type === "anti-filters") {
            _innerAntyFilters["min"] = "" + prices["min"];
          }
        }

        if (prices["max"]) {
          if (filter.type === "filters") {
            _innerFilters["max"] = "" + prices["max"];
          } else if (filter.type === "anti-filters") {
            _innerAntyFilters["max"] = "" + prices["max"];
          }
        }
      }

      _filters.push(_innerFilters)
      _antiFilters.push(_innerAntyFilters)
    });

    if (includeProductByID.length !== 0) {
      _filters.push({
        ["include_ids"]: includeProductByID.join(","),
      })
    } 

    if (excludeProductByID.length !== 0) {
      _antiFilters.push({
        ["include_ids"]: excludeProductByID.join(","),
      })
    }

    _filters = _filters.filter(item => Object.keys(item).length !== 0)
    _antiFilters = _antiFilters.filter(item => Object.keys(item).length !== 0)

    const promoCode = {
      name: name,
      alias: alias,
      description: description,
      filters: _filters.length === 0 ? null : _filters,
      anti_filters: _antiFilters.length === 0 ? null : _antiFilters,
      language_code: languageCode,
      value: value,
      is_percents: isPercents,
      total_from: totalFrom,
      total_to: totalTo,
      expired_at: expiredAt ? new Date(expiredAt) : undefined,
      for_auth: forAuth,
      max_auth_users: maxAuthUsers,
      max_uses_for_user: maxUsesForUser,
      max_uses: maxUses,
      is_published: isPublished,
      is_sum_with_discount: isSumWithDiscount,
      for_users_ids: forUsersIDs,
      collections_ids: collectionByID,
    };

    if (id) {
      dispatch(updatePromoCode({ ...promoCode, id: +id }));
    } else {
      dispatch(createPromoCode(promoCode))
    }
  };

  const deletePromoCodeHandler = () => {
    if (window.confirm("Are you sure?")) {
      dispatch(deletePromoCode(+id!));
    }
  }

  if (defaultFilters.status.isLoading || promoStatus.isLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (defaultFilters.status.isError || promoStatus.isError) {
    return (
      <div>
        <SomethingWrong />
      </div>
    );
  }
  
  return (
    <div className="create-or-edit">
      <div className="create-or-edit__wrapper-link">
        <NavLink to={"/promo-code"}>Back to list</NavLink>
      </div>
      {fullFilters.map(filters => (
        <div className="create-or-edit__filters" key={filters.id}>
          <div className="create-or-edit__filters-header">
            <Selector
              currentValue={filters.type}
              values={["filters", "anti-filters"]}
              onChange={e => { changeFiltersTypeHandler({
                id: filters.id,
                type: e.currentTarget.value as any, 
              }) }}
            />
            <div className="create-or-edit__filters-header-drop" onClick={e => deleteFiltersItemHandler(filters.id)}>Drop</div>
          </div>
          <div className="create-or-edit__filters-items">
            {filters.filters.map((filter, pos) => (
              <div key={filter.name} className="create-or-edit__filters-items-inner">
                <div className="create-or-edit__filters-items-name">{filter.name}</div>
                <div className="create-or-edit__filters-items-items">
                  {filter.name === "prices" && <>
                    <Input
                      type="text"
                      placeholder={filters.filters[pos].items[0].title}
                      value={"" + filters.filters[pos].items[0].forPrices}
                      onChange={e => { if(!isNaN(+e.currentTarget.value)) updatePricesFiltersHandler({
                        price: e.currentTarget.value,
                        id: filters.id, name: filter.name, title: filters.filters[pos].items[0].title,
                      }) }}
                    />
                    <Input
                      type="text"
                      placeholder={filters.filters[pos].items[1].title}
                      value={"" + filters.filters[pos].items[1].forPrices}
                      onChange={e => { if(!isNaN(+e.currentTarget.value)) updatePricesFiltersHandler({
                        price: e.currentTarget.value,
                        id: filters.id, name: filter.name, title: filters.filters[pos].items[1].title,
                      }) }}
                    />
                  </>}              
                  {filter.name !== "prices" && filter.items.map(items => {
                    return <InputWithLabel
                      key={items.title}
                      type="checkbox"
                      label={items.title}
                      checked={items.isChecked}
                      onChange={() => { updateCheckboxFiltersHandler({ id: filters.id, name: filter.name, title: items.title }) }}
                    />
                  })}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
      <Button onClick={addFullFilterHandler}>+</Button>
      <div className="create-or-edit__flags">
        <div>
          <InputWithLabel type="string" label="Alias *" value={alias} onChange={e => updateAliasHandler(e.currentTarget.value)} />
          <InputWithLabel type="string" label="Name *" value={name} onChange={e => updateNameHandler(e.currentTarget.value)} />
          <InputWithLabel type="string" label="Description" value={description} onChange={e => updateDescriptionHandler(e.currentTarget.value)} />
        </div>
        <div>
          <InputWithLabel type="string" label="Total from *" value={totalFrom} onChange={e => updateTotalFromHandler(e.currentTarget.value)} />
          <InputWithLabel type="string" label="Total to" value={totalTo} onChange={e => updateTotalToHandler(e.currentTarget.value)} />
          <SelectorWithLabel
            currentValue={languageCode === "ru-RU" ? "RUB" : "DOL"}
            label="Currency"
            values={["RUB", "DOL"]}
            onChange={e => updateLanguageCodeHandler(languageCode === "ru-RU" ? "en-EN" : "ru-RU")}
          />
          <InputWithLabel type="string" label="Value *" value={value} onChange={e => updateValueHandler(e.currentTarget.value)} />
          <InputWithLabel type="checkbox" label="Is percents" checked={isPercents} onChange={e => updateIsPercentsHandler()} />
          <InputWithLabel type="checkbox" label="Is sum with discount" checked={isSumWithDiscount} onChange={e => updateIsSumWithDiscountHandler()} />
        </div>
        <div>
          <InputWithLabel type="date" label="Expire at" value={expiredAt || ""} onChange={e => updateExpiredAtHandler(e.currentTarget.value)} />
          <InputWithLabel type="string" label="Max auth users" value={maxAuthUsers} onChange={e => updateMaxAuthUsersHandler(e.currentTarget.value)} />
          <InputWithLabel type="string" label="Max uses for user" value={maxUsesForUser} onChange={e => updateMaxUsesForUserHandler(e.currentTarget.value)} />
          <InputWithLabel type="string" label="Max uses" value={maxUses} onChange={e => updateMaxUsesHandler(e.currentTarget.value)} />
          <InputWithLabel type="checkbox" label="For auth" checked={forAuth} onChange={e => updateForAuthHandler()} />
          <InputWithLabel type="checkbox" label="Is published" checked={isPublished} onChange={e => updateIsPublishedHandler()} />
        </div>
        <div>
          <InputWithLabel type="text" label="User IDs" value={forUserIDsValue} onChange={e => { if (!isNaN(+e.currentTarget.value)) setForUserIDsValue(e.currentTarget.value)}} />
          <Button onClick={() => { dispatch(addForUsersIDs(+forUserIDsValue)); setForUserIDsValue("") }}>Add</Button>
          <div className="create-or-edit__mini-list">
            {forUsersIDs.map(userID => <div key={userID}><span onClick={() => dispatch(deleteForUsersIDs(userID))}>X</span> {userID}</div>)}
          </div>
        </div>

        <div>
          <InputWithLabel type="text" label="Include product by ID" value={includeProductByIDValue} onChange={e => { if (!isNaN(+e.currentTarget.value)) setIncludeProductByIDValue(e.currentTarget.value)}} />
          <Button onClick={() => { dispatch(addIncludeProductByID(+includeProductByIDValue)); setIncludeProductByIDValue("") }}>Add</Button>
          <div className="create-or-edit__mini-list">
            {includeProductByID.map(product => <div key={product}><span onClick={() => dispatch(deleteIncludeProductByID(product))}>X</span> {product}</div>)}
          </div>
        </div>
        <div>
          <InputWithLabel type="text" label="Exclude product by ID" value={excludeProductByIDValue} onChange={e => { if (!isNaN(+e.currentTarget.value)) setExcludeProductByIDValue(e.currentTarget.value)}} />
          <Button onClick={() => { dispatch(addExcludeProductByID(+excludeProductByIDValue)); setExcludeProductByIDValue("") }}>Add</Button>
          <div className="create-or-edit__mini-list">
            {excludeProductByID.map(product => <div key={product}><span onClick={() => dispatch(deleteExcludeProductByID(product))}>X</span> {product}</div>)}
          </div>
        </div>
        <div>
          <InputWithLabel type="text" label="Add collection by ID" value={collectionByIDValue} onChange={e => { if (!isNaN(+e.currentTarget.value)) setCollectionByIDValue(e.currentTarget.value)}} />
          <Button onClick={() => { dispatch(addCollectionByID(+collectionByIDValue)); setCollectionByIDValue("") }}>Add</Button>
          <div className="create-or-edit__mini-list">
            {collectionByID.map(product => <div key={product}><span onClick={() => dispatch(deleteCollectionID(product))}>X</span> {product}</div>)}
          </div>
        </div>

      </div>
      <Button
        onClick={createOrUpdatePromoHandler}
        disabled={status.isLoading}
        error={status.error?.message}
      >{id ? "Update" : "Create"}</Button>
      {id && <Button
        onClick={deletePromoCodeHandler}
        disabled={status.isLoading}
        error={status.error?.message}
      >Delete</Button>}
    </div>
  );
}