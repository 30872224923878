import "./something-wrong.scss";

export function SomethingWrong() {
  return (
    <div className="something-wrong">
      <div>Something wrong</div>
      <div>Try to login again</div>
    </div>
  );
}
