import { useParams } from "react-router-dom";

import { PrimaryUserInfo, AdditionalUserInfo } from "./components";
import { Button, Loader, SomethingWrong } from "common";
import { fetchUser, updateUser, useAppDispatch, useAppSelector } from "store";

import "./user.scss";
import { useEffect } from "react";

export function UserPage() {
  const { id }    = useParams();
  const dispatch  = useAppDispatch();

  const { user, isChanged, status: { isLoading, error } } = useAppSelector(state => state.user);

  useEffect(() => {
    dispatch(fetchUser("" + id));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const updateUserHandler = () => { dispatch(updateUser(user!)) };

  if (isLoading) {
    return (
      <div className="user">
        <Loader className="loader" />
      </div>
    );
  }

  if (error || !user) {
    return (
      <div className="user noticed">
        <SomethingWrong />
      </div>
    );
  }

  return (
    <div className="user-page">
      <div className="user-page__header">
        <Button disabled={!isChanged} onClick={updateUserHandler}>Save</Button>
      </div>
      <div className="user-page__content">
        <div className="user-page__content-item"><PrimaryUserInfo /></div>
        <div className="user-page__content-item"><AdditionalUserInfo /></div>
      </div>
    </div>
  );
}