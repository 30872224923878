import { NavLink, useParams } from "react-router-dom";
import Editor from "@monaco-editor/react";
import dateFormat from "dateformat";

import { Button, InputWithLabel, Loader, SelectorWithLabel } from "common";
import { useFetchBannerList, useGetMediaByAliasAndLang, useUpdateMediaByAlias } from "./use-media";
import { useEffect, useState } from "react";
import { SERVER_URL } from "config";

export function MediaEditPage() {
  const id = useParams().id;
  const lang = useParams().lang;

  const getMediaByAlias = useGetMediaByAliasAndLang();
  const updateMediaByAlias = useUpdateMediaByAlias();
  const fetchBannerList = useFetchBannerList();

  const [aliasValue, setAliasValue] = useState("");
  const [titleValue, setTitleValue] = useState("");
  const [publishDateValue, setPublishDateValue] = useState(new Date());
  const [bannerUrlValue, setBannerUrlValue] = useState("");
  const [htmlValue, setHtmlValue] = useState("");
  const [scriptsValue, setScriptsValue] = useState("");
  const [langValue, setLangValue] = useState<"ru-RU" | "en-EN">("ru-RU");
  const [isPublishedValue, setIsPublishedValue] = useState(false);

  useEffect(() => {
    fetchBannerList.get();
  }, []);

  useEffect(() => {
    if (!id || !lang) return;
    if (lang !== "ru-RU" && lang !== "en-EN") return;
    getMediaByAlias.get(id, lang);
  }, [id]);

  useEffect(() => {
    if (!getMediaByAlias.data) return;

    setAliasValue(getMediaByAlias.data.alias);
    setTitleValue(getMediaByAlias.data.title);
    setHtmlValue(getMediaByAlias.data.html);
    if (getMediaByAlias.data.scripts) setScriptsValue(getMediaByAlias.data.scripts);
    setLangValue(!(lang !== "ru-RU" && lang !== "en-EN") ? lang : "ru-RU");
    setIsPublishedValue(getMediaByAlias.data.isPublished);
    setBannerUrlValue(getMediaByAlias.data.bannerUrl);
    setPublishDateValue(new Date(getMediaByAlias.data.publishedAt));
  }, [getMediaByAlias.data]);

  const updateHandler = () => {
    updateMediaByAlias.update({
      title: titleValue,
      alias: aliasValue,
      html: htmlValue,
      scripts: scriptsValue,
      isPublished: isPublishedValue,
      lang: langValue,
      bannerUrl: bannerUrlValue,
      publishedAt: publishDateValue,
    });
  };

  if (getMediaByAlias.status.isLoading || updateMediaByAlias.status.isLoading || fetchBannerList.status.isLoading) {
    return <Loader className="loader" />;
  }

  if (lang !== "ru-RU" && lang !== "en-EN") {
    return (
      <div>
        <h1>Lang error</h1>
        <NavLink to="/media" className="link">Back to list</NavLink>
      </div>
    );
  }

  if (getMediaByAlias.status.error) {
    return (
      <div>
        <h1>{getMediaByAlias.status.error}</h1>
        <NavLink to="/media" className="link">Back to list</NavLink>
      </div>
    );
  }

  if (fetchBannerList.status.error) {
    return (
      <div>
        <h1>{fetchBannerList.status.error}</h1>
        <NavLink to="/media" className="link">Back to list</NavLink>
      </div>
    );
  }

  return (
    <div>
      <h1>Edit article</h1>
        <NavLink to="/media" className="link">Back to list</NavLink>
      <br />
      <br />
      <InputWithLabel
        label="Alias *"
        value={aliasValue}
        disabled={updateMediaByAlias.status.isLoading || true}
        onChange={e => setAliasValue(e.currentTarget.value)}
      />
      <span style={{ display: "block", marginBottom: "7px" }} />
      <InputWithLabel
        label="Title *"
        value={titleValue}
        disabled={updateMediaByAlias.status.isLoading}
        onChange={e => setTitleValue(e.currentTarget.value)}
      />
      <span style={{ display: "block", marginBottom: "7px" }} />
      <InputWithLabel
        label="Publish date *"
        type="datetime-local"
        value={dateFormat(publishDateValue, "yyyy-mm-dd'T'HH:MM", false)}
        disabled={updateMediaByAlias.status.isLoading}
        onChange={e => setPublishDateValue(new Date(e.currentTarget.value))}
      />
      <span style={{ display: "block", marginBottom: "7px" }} />
      <div style={{ display: "grid", gridTemplateColumns: "100px 1fr", gap:" 10px" }}>
        <div style={{ width: "100px", height: "100px", border: "1px solid lightgray" }}>
          {bannerUrlValue.length !== 0 && <>
            <img
              src={SERVER_URL + "/images/banners/" + bannerUrlValue}
              alt="banner"
              style={{ width: "100%", height: "100%", objectFit: "cover", fontSize: 0 }}
            />
          </>}
        </div>
        <SelectorWithLabel
          label="Banner"
          currentValue={bannerUrlValue}
          values={Array.from(fetchBannerList.list)}
          disabled={updateMediaByAlias.status.isLoading}
          onChange={(e) => setBannerUrlValue(e.currentTarget.value)}
        />
      </div>
      <span style={{ display: "block", marginBottom: "7px" }} />
      <div style={{
        paddingBottom: "0.3rem",
        fontWeight: "500",
      }}>Scripts editor</div>
      <Editor
        height="100px"
        value={scriptsValue}
        onChange={newValue => {
          if (updateMediaByAlias.status.isLoading) return;
          setScriptsValue(newValue!)
        }}
        defaultLanguage="javascript"
        defaultValue="// enter JS code"
        theme="vs-dark"
      />
      <span style={{ display: "block", marginBottom: "7px" }} />
      <div style={{
        paddingBottom: "0.3rem",
        fontWeight: "500",
      }}>HTML editor</div>
      <Editor
        height="300px"
        defaultLanguage="html"
        value={htmlValue}
        onChange={newValue => {
          if (updateMediaByAlias.status.isLoading) return;
          setHtmlValue(newValue!)
        }}
        theme="vs-dark"
      />
      <span style={{ display: "block", marginBottom: "7px" }} />
      <SelectorWithLabel
        label="Language"
        currentValue={langValue}
        values={["ru-RU", "en-EN"]}
        disabled={updateMediaByAlias.status.isLoading || true}
        onChange={() => setLangValue("en-EN")}
      />
      <span style={{ display: "block", marginBottom: "10px" }} />
      <InputWithLabel
        type="checkbox"
        label="Is published"
        checked={isPublishedValue}
        disabled={updateMediaByAlias.status.isLoading}
        onChange={() => setIsPublishedValue(current => !current)}
      />
      <span style={{ display: "block", marginBottom: "20px" }} />
      <Button
        onClick={updateHandler}
        error={updateMediaByAlias.status.error}
        disabled={updateMediaByAlias.status.isLoading}
      >Update</Button>
    </div>
  );
}
