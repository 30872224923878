import { getCurrency } from "helpers";

import { useAppSelector } from "store";
import { CLIENT_URL } from "config";

import "./products.scss";
import { useMemo } from "react";

export function Products() {
  const { order } = useAppSelector(state => state.order);
  const { products, lang, delivery } = order!;

  const totalPrice = useMemo(() => {
    let sum = 0;
    products.map(product => {
      sum += product.price - product.discount;
    });

    if (delivery?.price) {
      return sum + parseInt(delivery?.price);
    }

    return sum;
  }, [products]);

  if (!products) {
    return (
      <div>
        Products is empty or something wrong
      </div>
    );
  }

  return (
    <div className="order-product">
      <div className="product-total">Total price: <b>{getCurrency(totalPrice, lang)}</b></div>
      <table className="product-list">
        <thead>
          <tr>
            <th>ID</th>
            <th>Brand</th>
            <th>Name</th>
            <th>Price</th>
          </tr>
        </thead>
        {products.map(product => (
          <tbody key={product.id}>
              <tr className="product-item" key={product.id}>
              <td><a href={`${CLIENT_URL}/${lang === "en-EN" ? "en" : "ru"}/product/${product.id}`} target="blank">{product.id}</a></td>
              <td><a href={`${CLIENT_URL}/${lang === "en-EN" ? "en" : "ru"}/product/${product.id}`} target="blank">{product.brand.name}</a></td>
              <td><a href={`${CLIENT_URL}/${lang === "en-EN" ? "en" : "ru"}/product/${product.id}`} target="blank">{product.name}</a></td>
              <td>
                <a href={`${CLIENT_URL}/${lang === "en-EN" ? "en" : "ru"}/product/${product.id}`} target="blank">
                  {product.discount !== 0 ? (
                    <>
                      <span className="old-price">{getCurrency(product.price, lang)}</span>
                      {getCurrency(product.price - product.discount, lang)}
                    </>
                  ) : <>{getCurrency(product.price, lang)}</>}
                </a>
              </td>
            </tr>
          </tbody>
        ))}
        {delivery?.price && <>
          <tr>
            <td></td>
            <td></td>
            <td>Доставка</td>
            <td>{parseInt(delivery?.price)}</td>
          </tr>
        </>}
      </table>
    </div>
  );
}
