import { useEffect, useMemo, useState } from "react";

import { InputWithLabel, Loader, Selector, SomethingWrong, Button } from "common";
import { fetchDictionary, fetchExtract, saveDictionary, useAppDispatch, useAppSelector, dictionarySlice, Dictionary } from "store";

export function DictionaryPage() {
  const dispatch = useAppDispatch();

  const [dictionaryFromFile, setDictionaryFromFile] = useState<Dictionary | undefined>();

  const { updateValue, setDictionary } = dictionarySlice.actions;

  const { dictionary, isChanged, status: { isLoading, error } } = useAppSelector(state => state.dictionary);

  const types = ["All", "Only EN", "Only EN without translation", "Only RU", "Only RU without translation"];
  const [selectedType, setSelectedType] = useState("All");

  const [filter, setFilter] = useState("");

  const downloadDictionary = () => {
    const a = document.createElement("a");
    const file = new Blob([JSON.stringify(dictionary, null, 2)], {type: 'application/json'});
    a.href = URL.createObjectURL(file);
    a.download = "dictionary.json";
    a.click();
  };

  const setDictionaryData = (e: any) => {
    const file = e.currentTarget.files[0];
    const fileType = file.type;

    if (fileType !== "application/json") {
      setDictionaryFromFile(undefined);
      return alert("Only *.json");
    }

    const reader = new FileReader();
    reader.readAsText(file, "UTF-8")
    reader.onerror = (evt) => {
      alert("Error reading file");
      setDictionaryFromFile(undefined);
    };
    reader.onload = (evt) => {
      try {
        const dictionary: Dictionary = JSON.parse(evt.target!.result as any);
        let isValid = true;

        if (dictionary.ru && dictionary.en) {
          dictionary.ru.forEach(item => {
            if (!(item.id !== undefined && item.originalWord !== undefined && item.translation !== undefined)) isValid = false;
          });
          dictionary.en.forEach(item => {
            if (!(item.id !== undefined && item.originalWord !== undefined && item.translation !== undefined)) isValid = false;
          });
        }

        if (!isValid) {
          setDictionaryFromFile(undefined);
          return alert("File is not valid");
        }
  
        setDictionaryFromFile(dictionary);
      } catch (err) {
        return alert("Error reading file");
      }
    };
  }

  useEffect(() => {
    dispatch(fetchDictionary());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const updateTranslationHandler = ({ lang, id, translation }: { lang: "en" | "ru", id: string, translation: string }) => {
    dispatch(updateValue({ lang, id, translation }));
  };

  const fetchExtractHandler = () => {
    dispatch(fetchExtract())
      .then((result: any) => {
        if (result.meta.requestStatus === "fulfilled") { dispatch(fetchDictionary()) }
      });
  }

  const saveDictionaryHandler = () => {
    dispatch(saveDictionary(dictionary))
      .then((result: any) => {
        if (result.meta.requestStatus === "fulfilled") { dispatch(fetchDictionary()) }
      });
  }

  const setDictionaryFromFileHandler = () => {
    if (!dictionaryFromFile) return alert("Something wrong");
    dispatch(setDictionary(dictionaryFromFile));
    setDictionaryFromFile(undefined);
  };

  if (isLoading) { return <div className="orders"><Loader className="loader" /></div> }
  if (error) { return <div className="orders noticed"><SomethingWrong /></div> }
  
  return (
    <div className="dictionary">
      <Selector
        currentValue={selectedType}
        values={types}
        onChange={e =>  setSelectedType(e.currentTarget.value as any)}
      />
      <div style={{ paddingTop: "20px", paddingBottom: "10px" }}>
        <Button type="button" disabled={isChanged} onClick={fetchExtractHandler}>Search for new words on the server</Button>
        <Button type="button" disabled={!isChanged} onClick={saveDictionaryHandler}>Save</Button>
      </div>

      <div style={{ paddingTop: "20px", paddingBottom: "10px" }}>
        <Button type="button" onClick={downloadDictionary}>Get dictionary</Button>
      </div>

      <div style={{ paddingTop: "20px", paddingBottom: "10px" }}>
        <input type="file" id="input" onChange={setDictionaryData} accept=".json" />
        <Button type="button" disabled={dictionaryFromFile ? false : true} onClick={setDictionaryFromFileHandler}>Set dictionary</Button>
      </div>
      <div style={{ paddingTop: "20px", paddingBottom: "10px" }}>
        <InputWithLabel type="string" label="Filter" value={filter} onChange={e => setFilter(e.currentTarget.value)} />
      </div>
      <div>
        {(selectedType === "Only EN" || selectedType === "Only EN without translation" || selectedType === "All") && <>
          <h3 style={{ padding: "10px 0" }}>EN</h3>
          {dictionary.en
            .filter(item => {
              if (item.originalWord.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) > -1) {
                return true
              } else return false
            })
            .filter(item => {
              if (selectedType === "Only EN without translation") return item.translation.length === 0 ? true : false;
              return true;
            })
            .map(item => <div key={item.id} style={{margin: "10px 0"}}>
              <InputWithLabel type="string" label={item.originalWord} value={item.translation} onChange={e => updateTranslationHandler({ lang: "en", id: item.id, translation: e.currentTarget.value })} />
            </div>)}
        </>}
        {(selectedType === "Only RU" || selectedType === "Only RU without translation" || selectedType === "All") && <>
          <h3 style={{ padding: "10px 0" }}>RU</h3>
          {dictionary.ru
            .filter(item => {
              if (item.originalWord.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) > -1) {
                return true
              } else return false
            })
            .filter(item => {
              if (selectedType === "Only RU without translation") return item.translation.length === 0 ? true : false;
              return true;
            })
            .map(item => <div key={item.id} style={{margin: "10px 0"}}>
              <InputWithLabel type="string" label={item.originalWord} value={item.translation} onChange={e => updateTranslationHandler({ lang: "ru", id: item.id, translation: e.currentTarget.value })} />
            </div>)}
        </>}
      </div>
    </div>
  );
}