import { useEffect, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";

import { Loader, SomethingWrong, InputWithLabel } from "common";
import { useAppDispatch, useAppSelector, fetchBrands } from "store";


export function Brands() {
  const dispatch = useAppDispatch();
  const { brands, status: { isLoading, error } } = useAppSelector(state => state.brands);
  const [brandsFilterValue, setBrandsValueFilter] = useState("");

  const brandsFilter = useMemo(() => {
    if (brands === undefined) return [];
    return brands.filter(item => {
      const labels = item.name.toLocaleLowerCase().split(" ");
      const filter = brandsFilterValue.toLocaleLowerCase();
      let flag = false;
      labels.forEach(item => {
        if (item.startsWith(filter)) {
          flag = true;
          return;
        }
      })
      return flag;
    });
  }, [brands, brandsFilterValue]);

  useEffect(() => {
    dispatch(fetchBrands());
  }, []);

  const handleBrandsFilter = (e: React.FormEvent<HTMLInputElement>) => {
    setBrandsValueFilter(e.currentTarget.value)
  };

  if (isLoading) {
    return (
      <div>
        <Loader className="loader" />
      </div>
    );
  }

  if (error || brands === undefined) {
    return (
      <div className="users noticed">
        <SomethingWrong />
      </div>
    );
  }
  
  return (
    <div>
      <InputWithLabel
        type="string"
        label="Brands filter"
        onChange={handleBrandsFilter}
        value={brandsFilterValue}
      />
      <br />
      <ul>
        {brandsFilter.map(brand => <li key={brand.id}>
          <NavLink to={"/brands/" + brand.name} className="link">{brand.name}</NavLink>
        </li>)}
      </ul>
    </div>
  )
}