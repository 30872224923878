import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Editor from "@monaco-editor/react";
import dateFormat from "dateformat";

import { SERVER_URL } from "config";
import { translit } from "helpers/translit";
import { Button, InputWithLabel, Loader, SelectorWithLabel } from "common";
import { useCreateMedia, useFetchBannerList } from "./use-media";

export function MediaCreatePage() {
  const navigate = useNavigate();
  
  const createMedia = useCreateMedia();
  const fetchBannerList = useFetchBannerList();

  const [aliasValue, setAliasValue] = useState("");
  const [titleValue, setTitleValue] = useState("");
  const [publishDateValue, setPublishDateValue] = useState(new Date());
  const [bannerUrlValue, setBannerUrlValue] = useState("");
  const [htmlValue, setHtmlValue] = useState("");
  const [scriptsValue, setScriptsValue] = useState("");
  const [langValue, setLangValue] = useState<"ru-RU" | "en-EN">("ru-RU");
  const [isPublishedValue, setIsPublishedValue] = useState(false);

  useEffect(() => {
    fetchBannerList.get();
  }, []);

  useEffect(() => {
    if (titleValue.length === 0) return;
    setAliasValue(translit(titleValue).toLocaleLowerCase());
  }, [titleValue]);

  const createHandler = () => {
    createMedia.create({
      alias: aliasValue,
      title: titleValue,
      html: htmlValue,
      scripts: scriptsValue,
      isPublished: isPublishedValue,
      lang: langValue,
      bannerUrl: bannerUrlValue,
      publishedAt: publishDateValue,
    });
  };

  if (createMedia.status.isCompleted) {
    navigate("/media");
  }

  if (fetchBannerList.status.isLoading) {
    return <Loader className="loader" />;
  }

  if (fetchBannerList.status.error) {
    return (
      <div>
        <h1>{fetchBannerList.status.error}</h1>
        <NavLink to="/media" className="link">Back to list</NavLink>
      </div>
    );
  }
  
  return (
    <div>
      <h1>New article</h1>
        <NavLink to="/media" className="link">Back to list</NavLink>
      <br />
      <br />
      <InputWithLabel
        label="Alias *"
        value={aliasValue}
        disabled={createMedia.status.isLoading}
        onChange={e => setAliasValue(e.currentTarget.value)}
      />
      <span style={{ display: "block", marginBottom: "7px" }} />
      <InputWithLabel
        label="Title *"
        value={titleValue}
        disabled={createMedia.status.isLoading}
        onChange={e => setTitleValue(e.currentTarget.value)}
      />
      <span style={{ display: "block", marginBottom: "7px" }} />
      <InputWithLabel
        label="Publish date *"
        type="datetime-local"
        value={dateFormat(publishDateValue, "yyyy-mm-dd'T'HH:MM")}
        disabled={createMedia.status.isLoading}
        onChange={e => setPublishDateValue(new Date(e.currentTarget.value))}
      />
      <span style={{ display: "block", marginBottom: "7px" }} />
      <div style={{ display: "grid", gridTemplateColumns: "100px 1fr", gap:" 10px" }}>
        <div style={{ width: "100px", height: "100px", border: "1px solid lightgray" }}>
          {bannerUrlValue.length !== 0 && <>
            <img
              src={SERVER_URL + "/images/banners/" + bannerUrlValue}
              alt="banner"
              style={{ width: "100%", height: "100%", objectFit: "cover", fontSize: 0 }}
            />
          </>}
        </div>
        <SelectorWithLabel
          label="Banner"
          currentValue={bannerUrlValue}
          values={Array.from(fetchBannerList.list)}
          disabled={createMedia.status.isLoading}
          onChange={(e) => setBannerUrlValue(e.currentTarget.value)}
        />
      </div>
      <span style={{ display: "block", marginBottom: "7px" }} />
      <div style={{
        paddingBottom: "0.3rem",
        fontWeight: "500",
      }}>Scripts editor</div>
      <Editor
        height="100px"
        value={scriptsValue}
        onChange={newValue => {
          if (createMedia.status.isLoading) return;
          setScriptsValue(newValue!)
        }}
        defaultLanguage="javascript"
        defaultValue="// enter JS code"
        theme="vs-dark"
      />
      <span style={{ display: "block", marginBottom: "7px" }} />
      <div style={{
        paddingBottom: "0.3rem",
        fontWeight: "500",
      }}>HTML editor</div>
      <Editor
        height="300px"
        defaultLanguage="html"
        value={htmlValue}
        onChange={newValue => {
          if (createMedia.status.isLoading) return;
          setHtmlValue(newValue!)
        }}
        theme="vs-dark"
      />
      <span style={{ display: "block", marginBottom: "7px" }} />
      <SelectorWithLabel
        label="Language"
        currentValue={langValue}
        values={["ru-RU", "en-EN"]}
        disabled={createMedia.status.isLoading}
        onChange={() => setLangValue("en-EN")}
      />
      <span style={{ display: "block", marginBottom: "10px" }} />
      <InputWithLabel
        type="checkbox"
        label="Is published"
        checked={isPublishedValue}
        disabled={createMedia.status.isLoading}
        onChange={() => setIsPublishedValue(current => !current)}
      />
      <span style={{ display: "block", marginBottom: "20px" }} />
      <Button
        onClick={createHandler}
        error={createMedia.status.error}
        disabled={createMedia.status.isLoading}
      >Create</Button>
    </div>
  );
}
