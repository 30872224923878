import axios from "axios"
import { Loader } from "common/components";
import { SERVER_URL } from "config";
import { useEffect, useState } from "react"
import { NavLink } from "react-router-dom";

export const replaceString = (txt: string) => txt
  .replaceAll("%", "%25")   // Процент
  .replaceAll(" ", "%20")   // Пробел
  .replaceAll("\t", "%20")  // Табуляция (заменяем на пробел)
  .replaceAll("\n", "%20")  // Переход строки (заменяем на пробел)
  .replaceAll("\r", "%20")  // Возврат каретки (заменяем на пробел)
  .replaceAll("!", "%21")   // Восклицательный знак
  .replaceAll("\"", "%22")  // Двойная кавычка
  .replaceAll("#", "%23")   // Октоторп, решетка
  .replaceAll("\\$", "%24") // Знак доллара
  .replaceAll("&", "%26")   // Амперсанд
  .replaceAll("'", "%27")   // Одиночная кавычка
  .replaceAll("\\(", "%28") // Открывающаяся скобка
  .replaceAll("\\)", "%29") // Закрывающаяся скобка
  .replaceAll("\\*", "%2a") // Звездочка
  .replaceAll("\\+", "%2b") // Знак плюс
  .replaceAll(",", "%2c")   // Запятая
  .replaceAll("-", "%2d")   // Дефис
  .replaceAll("\\.", "%2e") // Точка
  .replaceAll("/", "%2f")   // Слеш, косая черта
  .replaceAll(":", "%3a")   // Двоеточие
  .replaceAll(";", "%3b")   // Точка с запятой
  .replaceAll("<", "%3c")   // Открывающаяся угловая скобка
  .replaceAll("=", "%3d")   // Знак равно
  .replaceAll(">", "%3e")   // Закрывающаяся угловая скобка
  .replaceAll("?", "%3f") // Вопросительный знак
  .replaceAll("@", "%40")   // At sign, по цене, собачка
  .replaceAll("\\[", "%5b") // Открывающаяся квадратная скобка
  .replaceAll("\\\\", "%5c") // Одиночный обратный слеш '\'
  .replaceAll("\\]", "%5d") // Закрывающаяся квадратная скобка
  .replaceAll("\\^", "%5e") // Циркумфлекс
  .replaceAll("_", "%5f")   // Нижнее подчеркивание
  .replaceAll("`", "%60")   // Гравис
  .replaceAll("\\{", "%7b") // Открывающаяся фигурная скобка
  .replaceAll("\\|", "%7c") // Вертикальная черта
  .replaceAll("\\}", "%7d") // Закрывающаяся фигурная скобка
  .replaceAll("~", "%7e");  // Тильда

export const CatalogInfo = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [InfoList, setInfoList] = useState<any>([]);
  const [errors, setErrors] = useState<any[]>([]);

  useEffect(() => {
    const getCatalogInfo = async () => {
      setIsLoading(true);

      const statusesConfig = {
        url: SERVER_URL + "/admin/catalog-info" + window.location.search,
        method: "get",
        withCredentials: true,
      };
  
      try {
        const { data } = await axios(statusesConfig);
        setInfoList(data.items);
        console.log(data);
      } catch (err) {
        console.log(err)
      }

      setIsLoading(false);
    }

    getCatalogInfo();
  }, [window.location.search]);

  if (isLoading) {
    return (
      <div className="catalog-info">
        <Loader className="loader" />
      </div>
    );
  }

  return (
    <div>
      <div><b><NavLink to={"/catalog-info/create"}>Create</NavLink></b></div>
      <br />
      <table style={{ width: "100% "}}>
        <thead>
          <tr>
            <th style={{ width: '50%' }}>Alias</th>
            <th style={{ width: 'calc(50% - 80px)' }}>Title</th>
            <th style={{ width: '80px' }}>Lang</th>
          </tr>
        </thead>
        {InfoList.map((createInfo: any) => (
          <tbody key={createInfo.alias}>
            <tr>
              <td><NavLink to={"/catalog-info/" + JSON.stringify({ alias: replaceString(createInfo.alias), lang: createInfo.lang })} className="link" style={{ display: "block" }}>{createInfo.alias}</NavLink></td>
              <td><NavLink to={"/catalog-info/" + JSON.stringify({ alias: replaceString(createInfo.alias), lang: createInfo.lang })}>{createInfo.title}</NavLink></td>
              <td><NavLink to={"/catalog-info/" + JSON.stringify({ alias: replaceString(createInfo.alias), lang: createInfo.lang })}>{createInfo.lang}</NavLink></td>
            </tr>
          </tbody>
        ))}
      </table>
    </div>
  )
}
