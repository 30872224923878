import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios  from "axios";

import { UserPrimary, FetchData } from "models";
import { SERVER_URL } from "config";

type UploadsState = {
  status: FetchData;
};

const initialState: UploadsState = {
  status: {
    isError: false,
    isLoading: false,
    error: undefined,
  },
};

export const addMenuJSONFile = createAsyncThunk("uploads/addFiles", (file: JSON, thunkAPI) => {
  const config = {
    withCredentials: true,
    method: "put",
    url: SERVER_URL + "/admin/menu",
    data: file,
  };

  return axios(config)
    .then(result => {
      console.log(result)
      return result.data;
    })
    .catch((error: any) => {
      return thunkAPI.rejectWithValue({
        status: error.response.status,
        statusText: error.response.statusText,
        message: error.response.data.message,
      } as FetchData["error"]);
    });
});

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {},
  extraReducers: {
    [addMenuJSONFile.fulfilled.type]: (state: UploadsState, action: PayloadAction<UserPrimary>) => {
      state.status.isLoading = false;
      state.status.isError = false;
      state.status.error = undefined;
    },
    [addMenuJSONFile.pending.type]: (state: UploadsState) => {
      state.status.isLoading = true;
      state.status.isError = false;
      state.status.error = undefined;
    },
    [addMenuJSONFile.rejected.type]: (state: UploadsState, action: PayloadAction<FetchData["error"]>) => {
      state.status.isLoading = false;
      state.status.isError = true;
      state.status.error = action.payload;
    },
  }
});
